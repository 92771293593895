import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import reverse from "lodash/reverse";
import slice from "lodash/slice";
import sortBy from "lodash/sortBy";
import {actionNotification} from "app/utils/notifications";
import {handleErrorResponse, handleGoogleAnalyticsTracking} from "app/utils/helpers";
import {resetFilters, setFilters} from "app/utils/filters";

const initialState = {
  items: [],
  itemsPerPage: 20,
  itemsCount: null,
  loaded: false,
  loading: false,
  shortlists: null,
  shortlistPdf: null,
  shortlistPdfLoading: false,
  shortlistParks: null,
  shortlistUnitsLoading: false,
  landlordItems: [],
  landlordItemsCount: null,
  landlordItemsPerPage: 20,
  landlordTotal: null,
  landlordLoading: false,
  landlordShortlistUnits: null,
  landlordShortlistUnitsLoading: false,
  loadingMore: false,
  offerItems: null,
  offerOffice: null,
  offerOffice404: false,
  offerContact: null,
  offerBranding: null,
  offerBranding404: false,
  loadingOfferItems: false,
  loadingOfferOffice: false,
  loadingOfferContact: false,
  loadingOffersBranding: false,
  verifyingAccess: false,
  requestingAccess: false,
  hasAccess: false,
  offersOnlineAnalytics: null,
  offersOnlineCharts: null,
  offersOnlineDetailsAnalytics: null,
  loadingOffersOnlineAnalytics: false,
  loadingOffersOnlineCharts: false,
  loadingOffersOnlineDetailsAnalytics: false,
  offersAll: null,
  loadingOffersAll: false,
  offerPhotos: null,
  loadingPhotos: false,
  shareLoading: false,
  shared: false,
  isLoadingShortlists: false,
  isShortlistParksLoading: false,
  isLoadedShortlist: false,
  offerUpdated: false,
  offerUpdating: false
};
const handleUpdateOfferData = (currentList, updatedItem) => {
  return currentList.map(obj => [updatedItem].find(o => o.id === obj.id) || obj);
};
export const reducer = persistReducer(
  { storage, key: "shortlistsIndustrial" },
  (state = initialState, action) => {
    const result = action.payload;
    switch (action.type) {
      // RESET INDUSTRIAL OFFERS LOADING STATES
      case 'RESET_INDUSTRIAL_OFFERS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false,
          shortlistPdfLoading: false,
          shortlistUnitsLoading: false,
          landlordLoading: false,
          landlordShortlistUnitsLoading: false,
          loadingMore: false,
          loadingOfferItems: false,
          loadingOfferOffice: false,
          loadingOfferContact: false,
          loadingOffersBranding: false,
          loadingOffersOnlineAnalytics: false,
          loadingOffersOnlineCharts: false,
          loadingOffersOnlineDetailsAnalytics: false,
          loadingOffersAll: false,
          loadingPhotos: false,
          shareLoading: false,
          isLoadingShortlists: false,
          isShortlistParksLoading: false
        };
      }
      // // OFFER
      case 'FETCH_OFFERS_ALL_REQUEST': {
        return { ...state, loadingOffersAll: true };
      }
      case 'FETCH_OFFERS_ALL_SUCCESS': {
        return { ...state, offersAll: action.payload, loadingOffersAll: false };
      }
      case 'FETCH_SHORTLISTS_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_SHORTLISTS_SUCCESS': {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return { ...state, items, itemsCount, itemsPerPage, loading: false, loaded: true };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_REQUEST': {
        return { ...state, landlordLoading: true };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_RESET_REQUEST': {
        return { ...state, landlordLoading: true };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_SUCCESS': {
        const { items, itemsCount, itemsPerPage, total } = action.payload;
        return { ...state, landlordItems: items, landlordItemsCount: itemsCount, landlordItemsPerPage: itemsPerPage, landlordTotal: total, landlordLoading: false };
      }
      case 'FETCH_MORE_SHORTLISTS_LANDLORD_REQUEST': {
        return { ...state, loadingMore: true };
      }
      case 'FETCH_MORE_SHORTLISTS_LANDLORD_SUCCESS': {
        return {
          ...state,
          landlordItems: state.landlordItems ? [...state.landlordItems, ...result.items] : result.items,
          loadingMore: false
        };
      }
      case 'FETCH_MORE_SHORTLISTS_LANDLORD_FAILED': {
        return { ...state, landlordItems: null, loadingMore: false };
      }
      case 'FETCH_SHORTLIST_PDF_REQUEST': {
        return { ...state, shortlistPdfLoading: true };
      }
      case 'FETCH_SHORTLIST_PDF_INDUSTRIAL_SUCCESS': {
        return { ...state, shortlistPdf: action.payload, shortlistPdfLoading: false };
      }
      case 'FETCH_SHORTLIST_INDUSTRIAL_UNITS_REQUEST': {
        return { ...state, shortlistUnits: null, shortlistUnitsLoading: true };
      }
      case 'FETCH_SHORTLIST_INDUSTRIAL_UNITS_SUCCESS': {
        return { ...state, shortlistUnits: action.payload, shortlistUnitsLoading: false };
      }
      case 'FETCH_SHORTLIST_INDUSTRIAL_UNITS_RESET_SUCCESS': {
        return { ...state, shortlistUnits: null, shortlistUnitsLoading: false };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_REQUEST': {
        return { ...state, landlordShortlistUnitsLoading: true };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_SUCCESS': {
        return { ...state, landlordShortlistUnits: action.payload ? action.payload : [], landlordShortlistUnitsLoading: false };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_RESET_SUCCESS': {
        return { ...state, landlordShortlistUnits: null, landlordShortlistUnitsLoading: false };
      }
      // // OFFER ONLINE
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_REQUEST': {
        return { ...state, loadingOfferItems: true, offerItems: null };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_SUCCESS': {
        return { ...state, offerItems: action.payload, loadingOfferItems: false, offerPhotos: null };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_FAILED': {
        return { ...state, offerItems: null, loadingOfferItems: false };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_REQUEST': {
        return { ...state, loadingOfferOffice: true, offerOffice: null, offerContact: null, offerOffice404: false };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_SUCCESS': {
        return { ...state, offerOffice: action.payload, loadingOfferOffice: false, offerContact: null, offerOffice404: false };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_FAILED': {
        return {
          ...state,
          offerOffice: null,
          loadingOfferOffice: false,
          offerContact: null,
          offerOffice404: action.payload >= 400 && action.payload <= 500
        };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_REQUEST': {
        return { ...state, loadingOfferContact: true, offerContact: null };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_SUCCESS': {
        return { ...state, offerContact: action.payload, loadingOfferContact: false };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_FAILED': {
        return { ...state, offerContact: null, loadingOfferContact: false };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_REQUEST': {
        return {
          ...state,
          loadingOfferBranding: true,
          offerBranding: null,
          offerBranding404: false,
          offerItems: null
        };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_SUCCESS': {
        return { ...state, offerBranding: action.payload, loadingOfferBranding: false, offerBranding404: false };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_FAILED': {
        return {
          ...state,
          offerBranding: null,
          loadingOfferBranding: false,
          offerBranding404: action.payload === 404
        };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_REQUEST': {
        return { ...state, loadingPhotos: true, offerPhotos: null };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_SUCCESS': {
        return { ...state, loadingPhotos: false, offerPhotos: action.payload };
      }
      case 'FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_FAILED': {
        return { ...state, loadingPhotos: false, offerPhotos: null };
      }
      // // OFFER ONLINE ANALYTICS
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_REQUEST': {
        return { ...state, offersOnlineAnalytics: null, loadingOffersOnlineAnalytics: true };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_SUCCESS': {
        return { ...state, offersOnlineAnalytics: action.payload, loadingOffersOnlineAnalytics: false };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_FAILED': {
        return { ...state, offersOnlineAnalytics: null, loadingOffersOnlineAnalytics: false };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_CHARTS_REQUEST': {
        return { ...state, offersOnlineCharts: null, loadingOffersOnlineCharts: true };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_CHARTS_SUCCESS': {
        return { ...state, offersOnlineCharts: action.payload, loadingOffersOnlineCharts: false };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_CHARTS_FAILED': {
        return { ...state, offersOnlineCharts: null, loadingOffersOnlineCharts: false };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_OFFICE_ANALYTICS_REQUEST': {
        return { ...state, offersOnlineDetailsAnalytics: null, loadingOffersOnlineDetailsAnalytics: true };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_OFFICE_ANALYTICS_SUCCESS': {
        return { ...state, offersOnlineDetailsAnalytics: action.payload, loadingOffersOnlineDetailsAnalytics: false };
      }
      case 'FETCH_OFFERS_INDUSTRIAL_ONLINE_OFFICE_ANALYTICS_FAILED': {
        return { ...state, offersOnlineDetailsAnalytics: null, loadingOffersOnlineDetailsAnalytics: false };
      }
      // // OFFER ACTIONS
      case 'ADD_SHORTLIST_INDUSTRIAL_REQUEST': {
        return { ...state };
      }
      case 'ADD_SHORTLIST_INDUSTRIAL_SUCCESS': {
        let record = action.payload;
        record["emails"] = [];
        return {
          ...state,
          items: slice(reverse(sortBy([...state.items, record], 'id')), 0, state.itemsPerPage),
          itemsCount: state.itemsCount + 1
        };
      }
      case 'ADD_PARK_TO_EXISTING_SHORTLIST_REQUEST': {
        return {
          ...state,
          offerUpdated: false
        };
      }
      case 'ADD_PARK_TO_EXISTING_SHORTLIST_SUCCESS': {
        return {
          ...state,
          offerUpdated: true
        };
      }
      case 'ADD_PARK_TO_NEW_SHORTLIST_REQUEST': {
        return {
          ...state,
          offerUpdated: false
        };
      }
      case 'ADD_PARK_TO_NEW_SHORTLIST_SUCCESS': {
        return {
          ...state,
          offerUpdated: true
        };
      }
      case 'ADD_UNIT_TO_NEW_SHORTLIST_REQUEST': {
        return { ...state };
      }
      case 'UPDATE_OFFER_INDUSTRIAL_CLIENTS_SUCCESS': {
        return { ...state, items: handleUpdateOfferData(state.items, action.payload) };
      }
      case 'UPDATE_OFFER_INDUSTRIAL_REQUEST': {
        return {
          ...state,
          offerUpdating: true
        };
      }
      case 'UPDATE_OFFER_INDUSTRIAL_SUCCESS': {
        return {
          ...state,
          items: handleUpdateOfferData(state.items, action.payload),
          offerUpdating: false
        };
      }
      case 'UPDATE_OFFER_INDUSTRIAL_FAILED': {
        return {
          ...state,
          offerUpdating: false
        };
      }
      // // OFFER ONLINE ACTIONS
      case 'VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_REQUEST': {
        return { ...state, verifyingAccess: true, hasAccess: false };
      }
      case 'VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_SUCCESS': {
        const {hasAccess} = action.payload;
        return { ...state, hasAccess, verifyingAccess: false };
      }
      case 'VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_FAILED': {
        return { ...state, hasAccess: false, verifyingAccess: false };
      }
      case 'REQUEST_OFFER_INDUSTRIAL_ONLINE_ACCESS_SUCCESS': {
        return { ...state, requestingAccess: false };
      }
      case 'REQUEST_OFFER_INDUSTRIAL_ONLINE_ACCESS_FAILED': {
        return { ...state, requestingAccess: false };
      }
      // // SHARE OFFER ONLINE
      case 'SHARE_OFFER_ONLINE_REQUEST': {
        return { ...state, shareLoading: true, shared: false };
      }
      case 'SHARE_OFFER_INDUSTRIAL_ONLINE_SUCCESS': {
        handleGoogleAnalyticsTracking('Button', 'Click', 'Offers Online - Share');
        return { ...state, shareLoading: false, shared: true };
      }
      case 'SHARE_OFFER_INDUSTRIAL_ONLINE_FAILED': {
        return { ...state, shareLoading: false, shared: false };
      }

      case 'FETCH_OFFERS_ALL_INDUSTRIAL_REQUEST': {
        return { ...state, loadingOffersAll: true };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_REQUEST': {
        return { ...state, isLoadingShortlists: true };
      }
      case 'FETCH_OFFERS_ALL_INDUSTRIAL_SUCCESS': {
        return { ...state, offersAll: action.payload, loadingOffersAll: false };
      }
      case 'FETCH_SHORTLISTS_INDUSTRIAL_SUCCESS': {
        return {...state, items: action.payload.items, itemsCount: action.payload.itemsCount, shortlistItems: action.payload.items, isLoadingShortlists: false, isLoadedShortlist: true}
      }
      case 'FETCH_SHORTLISTS_PARKS_REQUEST': {
        return { ...state, isShortlistParksLoading: true };
      }
      case 'FETCH_SHORTLIST_PARKS_SUCCESS': {
        return { ...state, shortlistParks: action.payload, isShortlistParksLoading: false };
      }
      case 'FETCH_SHORTLIST_PARKS_RESET_SUCCESS': {
        return { ...state, shortlistParks: null, isShortlistParksLoading: true };
      }
      case 'DELETE_SHORTLIST_INDUSTRIAL_REQUEST': {
        return { ...state, };
      }
      case 'DELETE_SHORTLIST_INDUSTRIAL_SUCCESS': {
        return { ...state, items: state.items.filter(item => item.id !== action.payload), itemsCount: state.itemsCount > 0 ? state.itemsCount -1 : null };
      }

      case 'FETCH_SHORTLIST_PDF_INDUSTRIAL_REQUEST': {
        return { ...state, shortlistPdfLoading: true };
      }
      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchOffersIndustrialAll() {
  try {
    const offersAll = yield axios.get(`/offers-industrial/all/`);
    yield put({ type: "FETCH_OFFERS_ALL_INDUSTRIAL_SUCCESS", payload: offersAll.data || [{ error: offersAll.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchShortlistsIndustrial(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const shortlists = yield axios.get(`/offers-industrial/${filters}`);
    yield put({ type: "FETCH_SHORTLISTS_INDUSTRIAL_SUCCESS", payload: shortlists.data || [{ error: shortlists.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchShortlistsIndustrialLandlord(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const shortlists = yield axios.get(`/offers-landlord-industrial/${filters}`);
    yield put({ type: "FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_SUCCESS", payload: shortlists.data || [{ error: shortlists.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchMoreShortlistsIndustrialLandlord(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const shortlistsMore = yield axios.get(`/offers-landlord-industrial/${filters}`);
    yield put({ type: "FETCH_MORE_SHORTLIST_INDUSTRIAL_LANDLORD_SUCCESS", payload: shortlistsMore.data || [{ error: shortlistsMore.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "FETCH_MORE_SHORTLISTS_LANDLORD_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchShortlistIndustrialPdf(action) {
  try {
    const shortlistID = action.payload;
    const shortlistPdf = yield axios.get(`/offers-industrial/${shortlistID}/get_presentation_data/`);
    yield put({ type: "FETCH_SHORTLIST_PDF_INDUSTRIAL_SUCCESS", payload: shortlistPdf.data || [{ error: shortlistPdf.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchShortlistParks(action) {
  try {
    const shortlistID = action.payload.params;
    const shortlistUnits = yield axios.get(`/offers-industrial/${shortlistID}/`);
    yield put({ type: "FETCH_SHORTLIST_PARKS_SUCCESS", payload: shortlistUnits.data || [{ error: shortlistUnits.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchShortlistIndustrialUnitsLandlord(action) {
  try {
    const params = action.payload.params;
    const officeID = params.officeId || null;
    const shortlistUnits = yield axios.get(`/offers-landlord-industrial/${officeID}/`);
    yield put({ type: "FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_SUCCESS", payload: shortlistUnits.data || [{ error: shortlistUnits.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchShortlistIndustrialLandlordReset(action) {
  const initOrderBy = action.payload;
  const filtersReset = resetFilters(initOrderBy);
  try {
    const shortlistsLandlord = yield axios.get(`/offers-landlord-industrial/${filtersReset}`);
    yield put({ type: "FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_SUCCESS", payload: shortlistsLandlord.data || [{ error: shortlistsLandlord.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

function* fetchShortlistParksReset() {
  try {
    yield put({ type: "FETCH_SHORTLIST_PARKS_RESET_SUCCESS" });
  }
  catch(err) {
    console.log(err);
  }
}

function* fetchShortlistUnitsIndustrialLandlordReset() {
  try {
    yield put({ type: "FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_RESET_SUCCESS" });
  }
  catch(err) {
    console.log(err);
  }
}

// FETCH OFFERS ONLINE DATA
function* fetchOfferIndustrialOnline(action) {
  const {offerID, email, market} = action.payload;
  const authMail = email ? `?email=${email}` : '';
  const filterMarket = market ? `${email ? '&' : '?'}market=${market}` : '';
  const filter = `${authMail}${filterMarket}`;
  try {
    const offer = yield axios.get(`/offers-industrial/${offerID}/details/${filter}`);
    yield put({ type: "FETCH_OFFER_INDUSTRIAL_ONLINE_SUCCESS", payload: offer.data || [{ error: offer.statusText }] });
  }
  catch(err) {
    if(err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_INDUSTRIAL_ONLINE_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchOfferIndustrialOnlinePark(action) {
  const {offerID, officeID, email} = action.payload;
  const authMail = email ? `?email=${email}` : '';
  try {
    const offerOffice = yield axios.get(`/offers-industrial/${offerID}/details/${officeID}/${authMail}`);
    yield put({ type: "FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_SUCCESS", payload: offerOffice.data || [{ error: offerOffice.statusText }] });
  }
  catch(err) {
    if(err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later..', 'error')
    });
  }
}
function* fetchOfferIndustrialOnlineOfficeContact(action) {
  const {offerID, officeID, email} = action.payload;
  const authMail = email ? `?email=${email}` : '';
  try {
    const offerContact = yield axios.get(`/offers-industrial/${offerID}/details/${officeID}/contact/${authMail}`);
    yield put({ type: "FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_SUCCESS", payload: offerContact.data || [{ error: offerContact.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later..', 'error')
    });
  }
}
function* fetchOfferIndustrialOnlineBranding(action) {
  const {offerID} = action.payload;
  try {
    const offerBranding = yield axios.get(`/offers-industrial/${offerID}/branding/`);
    yield put({ type: "FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_SUCCESS", payload: offerBranding.data || [{ error: offerBranding.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchOfferIndustrialOnlineListPhotos(action) {
  const {offerID, email} = action.payload;
  try {
    const offerPhotos = yield axios.get(`/offers-industrial/${offerID}/get-main-photos/?email=${email}`);
    yield put({ type: "FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_SUCCESS", payload: offerPhotos.data || [{ error: offerPhotos.statusText }] });
  }
  catch(err) {
    if(err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// FETCH OFFERS ONLINE ANALYTICS DATA
function* fetchOffersIndustrialAnalytics() {
  try {
    const offersAnalytics = yield axios.get(`/offers-industrial/analytics/`);
    yield put({
      type: "FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_SUCCESS",
      payload: offersAnalytics.data || [{error: offersAnalytics.statusText}]
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_FAILED"
    });
  }
}
function* fetchOffersIndustrialAnalyticsCharts(action) {
  try {
    const {year} = action.payload;
    const offersAnalyticsCharts = yield axios.get(`/offers-industrial/charts/${year}/`);
    yield put({
      type: "FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_CHARTS_SUCCESS",
      payload: offersAnalyticsCharts.data || [{error: offersAnalyticsCharts.statusText}]
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_CHARTS_FAILED"
    });
  }
}
function* fetchOffersIndustrialOfficeAnalytics(action) {
  try {
    const {offerID} = action.payload;
    const offerDetailsAnalytics = yield axios.get(`/offers-industrial/${offerID}/analytics/`);
    yield put({
      type: "FETCH_OFFERS_INDUSTRIAL_ONLINE_OFFICE_ANALYTICS_SUCCESS",
      payload: offerDetailsAnalytics.data || [{error: offerDetailsAnalytics.statusText}]
    });
  }
  catch(err) {
    console.log(err);
  }
}

// OFFER ACTION
function* addShortlistIndustrial(action) {
  const {data} = action.payload;
  try {
    const newShortlist = yield axios.post(`/offers-industrial/`, data);
    yield put({
      type: "ADD_SHORTLIST_INDUSTRIAL_SUCCESS",
      payload: newShortlist.data,
      meta: actionNotification('Shortlist has been added.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_SHORTLIST_INDUSTRIAL_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.name) || 'You have reached the limit of 30 units on a single shortlist.', 'error')
    });
  }
}
function* addParkToExistingShortlist(action) {
  const {id, parks} = action.payload;
  try {
    const parkToShortlist = yield axios.post(`/offers-industrial/${id}/add_park/`, { parks });
    yield put({
      type: "ADD_PARK_TO_EXISTING_SHORTLIST_SUCCESS",
      payload: parkToShortlist.data,
      meta: actionNotification('Park has been added to selected shortlist.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_PARK_TO_EXISTING_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification('You have reached the limit of 30 units on a single shortlist.', 'error')
    });
  }
}
function* addParksToNewShortlist(action) {
  const {name, parks, forTesting} = action.payload;
  try {
    const parksData = yield axios.post(`/offers-industrial/`, {name, parks, forTesting});
    yield put({
      type: "ADD_PARK_TO_NEW_SHORTLIST_SUCCESS",
      payload: parksData.data,
      meta: actionNotification('Park has been added to new shortlist.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_SHORTLIST_FAILED_INDUSTRIAL",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.name) || err.data?.[0] || 'You have reached the limit of 30 parks on a single shortlist.', 'error')
    });
  }
}

function* deleteShortlistIndustrial(action) {
  const {shortlistId} = action.payload;
  try {
    yield axios.delete(`/offers-industrial/${shortlistId}/`);
    yield put({
      type: "DELETE_SHORTLIST_INDUSTRIAL_SUCCESS",
      payload: shortlistId,
      meta: actionNotification('Shortlist has been deleted.', 'success')
    });
  }
  catch(err) {
    console.log(err);
  }
}

function* updateOfferIndustrialClients(action) {
  const {offerID, data, notificationDisabled} = action.payload;
  try {
    const clients = yield axios.post(`/offers-industrial/${offerID}/give-access/`, data);
    yield put({
      type: "UPDATE_OFFER_INDUSTRIAL_CLIENTS_SUCCESS",
      payload: clients.data,
      meta: notificationDisabled ? null : actionNotification('Offer clients list has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_OFFER_CLIENTS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateOfferIndustrial(action) {
  const { offerID, data } = action.payload;
  try {
    const clients = yield axios.patch(`/offers-industrial/${offerID}/`, data);
    yield put({
      type: "UPDATE_OFFER_INDUSTRIAL_SUCCESS",
      payload: clients.data,
      meta: actionNotification("Offer has been updated.", "success"),
    });
  } catch (err) {
    yield put({
      type: "UPDATE_OFFER_INDUSTRIAL_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* shareOfferIndustrialOnline(action) {
  try {
    const {data, offerID} = action.payload;
    const offerShare = yield axios.post(`/offers-industrial/${offerID}/send_offer_share/`, data);
    yield put({
      type: "SHARE_OFFER_INDUSTRIAL_ONLINE_SUCCESS",
      payload: offerShare.data
    });
  }
  catch(err) {
    yield put({
      type: "SHARE_OFFER_INDUSTRIAL_ONLINE_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// OFFER ONLINE ACTIONS
function* verifyOfferIndustrialOnlineAccess(action) {
  try {
    const {offerID, email} = action.payload;
    const authMail = email ? `?email=${email}` : '';
    const offerVerifyAccess = yield axios.get(`/offers-industrial/${offerID}/verify/${authMail}`);
    const isNotAllowed = offerVerifyAccess && offerVerifyAccess.data && !offerVerifyAccess.data.hasAccess;
    yield put({
      type: "VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_SUCCESS",
      payload: offerVerifyAccess.data,
      meta: isNotAllowed && actionNotification('You are not allowed to view this Offer. You can request access to it by clicking link above.', 'warning')
    });
  }
  catch(err) {
    yield put({
      type: "VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* requestOfferIndustrialOnlineAccess(action) {
  try {
    const {offerID, email} = action.payload;
    const authMail = email ? `?email=${email}` : '';
    const offerRequestAccess = yield axios.get(`/offers-industrial/${offerID}/get-access/${authMail}`);
    yield put({
      type: "REQUEST_OFFER_INDUSTRIAL_ONLINE_ACCESS_SUCCESS",
      payload: offerRequestAccess.data,
      meta: actionNotification('Your request for access to this Offer has been sent.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REQUEST_OFFER_INDUSTRIAL_ONLINE_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// OFFER KUDOS SEND MAIL
function* sendKudosEmailIndustrialConfirmation(action) {
  try {
    const {data} = action.payload;
    const kudosConfirm = yield axios.post(`/offers-industrial/send_kudos/`, data);
    yield put({
      type: "SEND_KUDOS_EMAIL_INDUSTRIAL_SUCCESS",
      payload: kudosConfirm.data
    });
  }
  catch(err) {
    yield put({
      type: "SEND_KUDOS_EMAIL_INDUSTRIAL_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* createKudosEmailIndustrialSignInLog(action) {
  try {
    const {data} = action.payload;
    const kudosLog = yield axios.post(`/offers-industrial/create_mail_log_in_redd_record/`, data);
    yield put({
      type: "CREATE_KUDOS_EMAIL_INDUSTRIAL_SIGN_IN_LOG_SUCCESS",
      payload: kudosLog.data
    });
    window.location.href = "/";
  }
  catch(err) {
    yield put({
      type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* createRealTimeEmailIndustrialSignInLog(action) {
  try {
    const {data} = action.payload;
    const kudosLog = yield axios.post(`/offers-industrial/create_mail_check_module_record/`, data);
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_SUCCESS",
      payload: kudosLog.data
    });
    localStorage.removeItem("redirectSourceUrl");
  }
  catch(err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// OFFER WEEKLY SUMMARY - BUTTON REDIRECT
function* createRealTimeEmailIndustrialWeeklySummaryLog(action) {
  try {
    const {data} = action.payload;
    const weeklySummaryLog = yield axios.post(`/offers-industrial/create_offer_weekly_summary_check_record/`, data);
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_SUCCESS",
      payload: weeklySummaryLog.data
    });
    localStorage.removeItem("redirectSourceUrl");
  }
  catch(err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// OFFER FIRST OPEN - BUTTON REDIRECT
function* createRealTimeEmailIndustrialFirstOpenLog(action) {
  try {
    const {data} = action.payload;
    const firstOpenLog = yield axios.post(`/offers-industrial/create_offer_first_time_opened_record/`, data);
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_SUCCESS",
      payload: firstOpenLog.data
    });
    localStorage.removeItem("redirectSourceUrl");
  }
  catch(err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// OFFER SHARE - OFFER OPEN - BUTTON REDIRECT
function* createRealTimeEmailOfferIndustrialOpenLog(action) {
  try {
    const {data} = action.payload;
    const offerOpenLog = yield axios.post(`/offers-industrial/create_offer_real_time_mail_check_record/`, data);
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_OFFER_INDUSTRIAL_OPEN_LOG_SUCCESS",
      payload: offerOpenLog.data
    });
    localStorage.removeItem("redirectSourceUrl");
  }
  catch(err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}


export function* saga() {
  // OFFER
  yield takeLatest("FETCH_OFFERS_ALL_INDUSTRIAL_REQUEST", fetchOffersIndustrialAll)
  yield takeLatest("FETCH_SHORTLISTS_INDUSTRIAL_REQUEST", fetchShortlistsIndustrial)
  yield takeLatest('FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_REQUEST', fetchShortlistsIndustrialLandlord);
  yield takeLatest('FETCH_MORE_SHORTLISTS_INDUSTRIAL_LANDLORD_REQUEST', fetchMoreShortlistsIndustrialLandlord);
  // yield takeLatest('FETCH_SHORTLIST_PDF_REQUEST', fetchShortlistPdf);
  yield takeLatest("FETCH_SHORTLISTS_PARKS_REQUEST", fetchShortlistParks)
  yield takeLatest('FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_REQUEST', fetchShortlistIndustrialUnitsLandlord);
  yield takeLatest('FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_RESET_REQUEST', fetchShortlistIndustrialLandlordReset);
  yield takeLatest("FETCH_SHORTLISTS_PARKS_RESET_REQUEST", fetchShortlistParksReset)
  yield takeLatest('FETCH_SHORTLIST_PDF_INDUSTRIAL_REQUEST', fetchShortlistIndustrialPdf);
  yield takeLatest('FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_RESET_REQUEST', fetchShortlistUnitsIndustrialLandlordReset);
  // OFFER ONLINE
  yield takeLatest('FETCH_OFFER_INDUSTRIAL_ONLINE_REQUEST', fetchOfferIndustrialOnline);
  yield takeLatest('FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_REQUEST', fetchOfferIndustrialOnlinePark);
  yield takeLatest('FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_REQUEST', fetchOfferIndustrialOnlineOfficeContact);
  yield takeLatest('FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_REQUEST', fetchOfferIndustrialOnlineBranding);
  yield takeLatest('FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_REQUEST', fetchOfferIndustrialOnlineListPhotos);
  // OFFER ONLINE ANALYTICS
  yield takeLatest('FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_REQUEST', fetchOffersIndustrialAnalytics);
  yield takeLatest('FETCH_OFFERS_INDUSTRIAL_ONLINE_ANALYTICS_CHARTS_REQUEST', fetchOffersIndustrialAnalyticsCharts);
  yield takeLatest('FETCH_OFFERS_INDUSTRIAL_ONLINE_OFFICE_ANALYTICS_REQUEST', fetchOffersIndustrialOfficeAnalytics);
  // OFFER ACTIONS
  yield takeLatest('ADD_SHORTLIST_INDUSTRIAL_REQUEST', addShortlistIndustrial);
  yield takeLatest("ADD_PARK_TO_EXISTING_SHORTLIST_REQUEST", addParkToExistingShortlist)
  yield takeLatest("ADD_PARK_TO_NEW_SHORTLIST_REQUEST", addParksToNewShortlist)
  yield takeLatest("DELETE_SHORTLIST_INDUSTRIAL_REQUEST", deleteShortlistIndustrial)
  // yield takeLatest('DELETE_MULTIPLE_SHORTLIST_REQUEST', deleteMultipleShortlist);
  yield takeLatest('UPDATE_OFFER_INDUSTRIAL_CLIENTS_REQUEST', updateOfferIndustrialClients);
  yield takeLatest('UPDATE_OFFER_INDUSTRIAL_REQUEST', updateOfferIndustrial);
  yield takeLatest('SHARE_OFFER_INDUSTRIAL_ONLINE_REQUEST', shareOfferIndustrialOnline);
  // OFFER ONLINE ACTIONS
  yield takeLatest('VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_REQUEST', verifyOfferIndustrialOnlineAccess);
  yield takeLatest('REQUEST_OFFER_INDUSTRIAL_ONLINE_ACCESS_REQUEST', requestOfferIndustrialOnlineAccess);
  // OFFER KUDOS SEND MAIL
  yield takeLatest('SEND_KUDOS_EMAIL_INDUSTRIAL_REQUEST', sendKudosEmailIndustrialConfirmation);
  yield takeLatest('CREATE_KUDOS_EMAIL_INDUSTRIAL_SIGN_IN_LOG_REQUEST', createKudosEmailIndustrialSignInLog);
  yield takeLatest('CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_REQUEST', createRealTimeEmailIndustrialSignInLog);
  // OFFER WEEKLY SUMMARY - BUTTON REDIRECT
  yield takeLatest('CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_REQUEST', createRealTimeEmailIndustrialWeeklySummaryLog);
  // OFFER FIRST OPEN - BUTTON REDIRECT
  yield takeLatest('CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_REQUEST', createRealTimeEmailIndustrialFirstOpenLog);
  // OFFER FIRST OPEN - BUTTON REDIRECT
  yield takeLatest('CREATE_REAL_TIME_EMAIL_OFFER_INDUSTRIAL_OPEN_LOG_REQUEST', createRealTimeEmailOfferIndustrialOpenLog);
}