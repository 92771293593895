import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {downloadFileFromUrl, toDateFormat} from "../../../../utils/helpers";

const initialState = {
  basic: null,
  basicLoading: false,
  buildings: null,
  buildingsLoading: false,
  leaseTerms: null,
  leaseTermsLoading: false,
  marketplaceStats: null,
  marketplaceStatsLoading: false,
  changes: null,
  changesLoading: false,
  files: null,
  filesLoading: false,
  similarProperties: null,
  similarPropertiesLoading: false,
  owner: null,
  ownerLoading: false,
  leasingContacts: null,
  leasingContactsLoading: false,
  certificates: null,
  certificatesLoading: false,
  units: null,
  unitsLoading: false,
  editFormsData: null,
  editFormsDataLoading: false,
  regionStats: null,
  regionStatsLoading: false,
  regionSupplyBoost: null,
  regionSupplyBoostLoading: false,
  regionRent: null,
  regionRentLoading: false,
  regionMap: null,
  regionMapLoading: false,
  xlsLoading: false,
  resetting: false
};

export const reducer = persistReducer(
  { storage, key: "industrialPark" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET INDUSTRIAL PARK LOADING STATES
      case 'RESET_INDUSTRIAL_PARK_LOADING_STATES_REQUEST': {
        return {
          ...state,
          basicLoading: false,
          buildingsLoading: false,
          leaseTermsLoading: false,
          marketplaceStatsLoading: false,
          changesLoading: false,
          filesLoading: false,
          similarPropertiesLoading: false,
          ownerLoading: false,
          leasingContactsLoading: false,
          certificatesLoading: false,
          editFormsDataLoading: false,
          unitsLoading: false,
          regionStatsLoading: false,
          regionSupplyBoostLoading: false,
          regionRentLoading: false,
          xlsLoading: false
        }
      }
      // BASIC
      case 'FETCH_INDUSTRIAL_PARK_BASIC_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          basic: backgroundLoading ? state.basic : null,
          basicLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_BASIC_SUCCESS': {
        return { ...state, basic: action.payload, basicLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_BASIC_FAILED': {
        return { ...state, basic: null, basicLoading: false }
      }
      // BUILDINGS
      case 'FETCH_INDUSTRIAL_PARK_BUILDINGS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          buildingsLoading: !backgroundLoading,
          buildings: backgroundLoading ? state.buildings : null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_BUILDINGS_SUCCESS': {
        return { ...state, buildingsLoading: false, buildings: action.payload.warehouses }
      }
      case 'FETCH_INDUSTRIAL_PARK_BUILDINGS_FAILED': {
        return { ...state, buildingsLoading: false, buildings: null }
      }
      // LEASE TERMS
      case 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          leaseTerms: backgroundLoading ? state.leaseTerms : null,
          leaseTermsLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_SUCCESS': {
        return { ...state, leaseTerms: action.payload, leaseTermsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_FAILED': {
        return { ...state, leaseTerms: null, leaseTermsLoading: false }
      }
      // MARKETPLACE STATS
      case 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_REQUEST': {
        return { ...state, marketplaceStats: null, marketplaceStatsLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_SUCCESS': {
        return { ...state, marketplaceStats: action.payload, marketplaceStatsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_FAILED': {
        return { ...state, marketplaceStats: null, marketplaceStatsLoading: false }
      }
      // CHANGES
      case 'FETCH_INDUSTRIAL_PARK_CHANGES_REQUEST': {
        return { ...state, changes: null, changesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_CHANGES_SUCCESS': {
        return { ...state, changes: action.payload?.changes, changesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_CHANGES_FAILED': {
        return { ...state, changes: null, changesLoading: false }
      }
      // FILES
      case 'FETCH_INDUSTRIAL_PARK_FILES_REQUEST': {
        return { ...state, files: null, filesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_FILES_SUCCESS': {
        return { ...state, files: action.payload, filesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_FILES_FAILED': {
        return { ...state, files: null, filesLoading: false }
      }
      // SIMILAR PROPERTIES
      case 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_REQUEST': {
        return { ...state, similarProperties: null, similarPropertiesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_SUCCESS': {
        return { ...state, similarProperties: action.payload, similarPropertiesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_FAILED': {
        return { ...state, similarProperties: null, similarPropertiesLoading: false }
      }
      // OWNER
      case 'FETCH_INDUSTRIAL_PARK_OWNER_REQUEST': {
        return { ...state, owner: null, ownerLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_OWNER_SUCCESS': {
        return { ...state, owner: action.payload, ownerLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_OWNER_FAILED': {
        return { ...state, owner: null, ownerLoading: false }
      }
      // OWNER
      case 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_REQUEST': {
        return { ...state, leasingContacts: null, leasingContactsLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_SUCCESS': {
        return { ...state, leasingContacts: action.payload, leasingContactsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_FAILED': {
        return { ...state, leasingContacts: null, leasingContactsLoading: false }
      }
      // CERTIFICATES
      case 'FETCH_INDUSTRIAL_PARK_CERTIFICATES_REQUEST': {
        return { ...state, certificates: null, certificatesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_CERTIFICATES_SUCCESS': {
        return { ...state, certificates: action.payload, certificatesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_CERTIFICATES_FAILED': {
        return { ...state, certificates: null, certificatesLoading: false }
      }
      // UNITS
      case 'FETCH_INDUSTRIAL_PARK_UNITS_BASIC_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          unitsLoading: !backgroundLoading,
          units: backgroundLoading ? state.units : null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_UNITS_BASIC_SUCCESS': {
        return { ...state, unitsLoading: false, units: [...action.payload.units] }
      }
      case 'FETCH_INDUSTRIAL_PARK_UNITS_BASIC_FAILED': {
        return { ...state, unitsLoading: false, units: null }
      }
      // REGION BASIC DATA
      case 'FETCH_INDUSTRIAL_PARK_REGION_REQUEST': {
        return {
          ...state,
          regionStatsLoading: true,
          regionStats: null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_SUCCESS': {
        return {
          ...state,
          regionStatsLoading: false,
          regionStats: action.payload
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_FAILED': {
        return {
          ...state,
          regionStatsLoading: false,
          regionStats: null
        }
      }
      // REGION SUPPLY BOOST
      case 'FETCH_INDUSTRIAL_PARK_REGION_SUPPLY_BOOST_REQUEST': {
        return {
          ...state,
          regionSupplyBoostLoading: true,
          regionSupplyBoost: null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_SUPPLY_BOOST_SUCCESS': {
        return {
          ...state,
          regionSupplyBoostLoading: false,
          regionSupplyBoost: action.payload
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_SUPPLY_BOOST_FAILED': {
        return {
          ...state,
          regionSupplyBoostLoading: false,
          regionSupplyBoost: null
        }
      }
      // REGION RENT
      case 'FETCH_INDUSTRIAL_PARK_REGION_RENT_REQUEST': {
        return {
          ...state,
          regionRentLoading: true,
          regionRent: null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_RENT_SUCCESS': {
        return {
          ...state,
          regionRentLoading: false,
          regionRent: action.payload
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_RENT_FAILED': {
        return {
          ...state,
          regionRentLoading: false,
          regionRent: null
        }
      }
      // REGION AMP
      case 'FETCH_INDUSTRIAL_PARK_REGION_MAP_REQUEST': {
        return {
          ...state,
          regionMapLoading: true,
          regionMap: null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_MAP_SUCCESS': {
        return {
          ...state,
          regionMapLoading: false,
          regionMap: action.payload
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_REGION_MAP_FAILED': {
        return {
          ...state,
          regionMapLoading: false,
          regionMap: null
        }
      }
      // Fetch XLS
      case "FETCH_INDUSTRIAL_PARK_REGION_XLS_REQUEST": {
        return {
          ...state,
          xlsLoading: true
        };
      }
      case "FETCH_INDUSTRIAL_PARK_REGION_XLS_SUCCESS": {
        return {
          ...state,
          xlsLoading: false
        };
      }
      case "FETCH_INDUSTRIAL_PARK_REGION_XLS_FAILED": {
        return {
          ...state,
          xlsLoading: false
        };
      }

      // EDIT FORMS DATA
      case 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_REQUEST': {
        return { ...state, editFormsData: null, editFormsDataLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_SUCCESS': {
        return { ...state, editFormsData: action.payload, editFormsDataLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_FAILED': {
        return { ...state, editFormsData: null, editFormsDataLoading: false }
      }

      // AUTO ACTION
      case 'RESET_INDUSTRIAL_PARK_STATE_REQUEST': {
        return {
          ...state,
          resetting: true
        }
      }
      case 'RESET_INDUSTRIAL_PARK_STATE_SUCCESS': {
        return {
          ...initialState,
          resetting: false
        }
      }
      case 'RESET_INDUSTRIAL_PARK_STATE_FAILED': {
        return {
          ...initialState,
          resetting: false
        }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchIndustrialParkEditFormsData(action) {
  const {parkID} = action.payload;
  try {
    const editFormsData = yield axios.get(`/industrial-parks/${parkID}/basic-form/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_SUCCESS", payload: editFormsData.data || [{ error: editFormsData.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkBasic(action) {
  const {parkID} = action.payload;
  try {
    const officeBasic = yield axios.get(`/industrial-parks/${parkID}/basic/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BASIC_SUCCESS", payload: officeBasic.data || [{ error: officeBasic.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BASIC_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkBuildings(action) {
  const {parkID, ordering} = action.payload;
  try {
    const setOrdering = [
      ...ordering ? [`?ordering=${ordering}`] : []
    ].join("");
    const buildings = yield axios.get(`/industrial-parks/${parkID}/basic-buildings/${setOrdering}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BUILDINGS_SUCCESS", payload: buildings.data || [{ error: buildings.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BUILDINGS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkLeaseTerms(action) {
  const {parkID} = action.payload;
  try {
    const leaseTerms = yield axios.get(`/industrial-parks/${parkID}/basic-rent/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASE_TERMS_SUCCESS", payload: leaseTerms.data || [{ error: leaseTerms.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASE_TERMS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkMarketplaceStats(action) {
  const {parkID} = action.payload;
  try {
    const stats = yield axios.get(`/industrial-parks/${parkID}/basic-marketplace/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_SUCCESS", payload: stats.data || [{ error: stats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkChanges(action) {
  const {parkID} = action.payload;
  try {
    const changes = yield axios.get(`/industrial-parks/${parkID}/basic-changes/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CHANGES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkFiles(action) {
  const {parkID} = action.payload;
  try {
    const files = yield axios.get(`/industrial-parks/${parkID}/basic-files/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_FILES_SUCCESS", payload: files.data || [{ error: files.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkSimilarProperties(action) {
  const {parkID} = action.payload;
  try {
    const changes = yield axios.get(`/industrial-parks/${parkID}/basic-similar/?size=10`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkOwner(action) {
  const {parkID} = action.payload;
  try {
    const owner = yield axios.get(`/industrial-parks/${parkID}/basic-owner/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_OWNER_SUCCESS", payload: owner?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_OWNER_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkLeasingContacts(action) {
  const {parkID} = action.payload;
  try {
    const leasingContact = yield axios.get(`/industrial-parks/${parkID}/basic-landlord/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_SUCCESS", payload: leasingContact?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkCertificates(action) {
  const {parkID} = action.payload;
  try {
    const certificates = yield axios.get(`/industrial-parks/${parkID}/basic-certificates/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CERTIFICATES_SUCCESS", payload: certificates.data || [{ error: certificates.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CERTIFICATES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkUnitsBasic(action) {
  const {parkID} = action.payload;
  try {
    const units = yield axios.get(`/industrial-parks/${parkID}/basic-units/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_UNITS_BASIC_SUCCESS", payload: units.data || [{ error: units.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_UNITS_BASIC_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkRegion(action) {
  const {parkID} = action.payload;
  try {
    const regionStats = yield axios.get(`/industrial-parks/${parkID}/basic-region/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_SUCCESS", payload: regionStats.data || [{ error: regionStats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkRegionSupplyBoost(action) {
  const {parkID} = action.payload;
  try {
    const units = yield axios.get(`/industrial-parks/${parkID}/basic-region-supply-boost/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_SUPPLY_BOOST_SUCCESS", payload: units.data || [{ error: units.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_SUPPLY_BOOST_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkRegionRent(action) {
  const {parkID} = action.payload;
  try {
    const units = yield axios.get(`/industrial-parks/${parkID}/basic-region-rent/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_RENT_SUCCESS", payload: units.data || [{ error: units.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_RENT_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkRegionMap(action) {
  const {parkID} = action.payload;
  try {
    const markers = yield axios.get(`/industrial-parks/${parkID}/basic-region-map/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_MAP_SUCCESS", payload: markers.data || [{ error: markers.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_REGION_MAP_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkRegionXls(action) {
  const {parkID, propertyName} = action.payload;
  try {
    const currentDate = new Date();
    const xls = yield axios.get(`/industrial-parks/${parkID}/basic-region-xls/`, { responseType: 'blob'});
    const csvBlobToUrl = xls?.data && URL.createObjectURL(xls?.data);
    const officeNameFormatted = propertyName && propertyName?.length > 0 ? propertyName.toLowerCase().replaceAll(" ", "_") : null;
    const fileName = [
      'redd-industrial-park-region',
      ...propertyName ? [`${officeNameFormatted.replaceAll(".", "_")}`] : [],
      ...currentDate ? [`${toDateFormat(currentDate).replaceAll(".", "-")}`] : []
    ].join("-");

    downloadFileFromUrl(csvBlobToUrl, fileName);
    yield put({
      type: "FETCH_INDUSTRIAL_PARK_REGION_XLS_SUCCESS",
      payload: xls.data || [
        {error: xls.statusText},
      ],
    });
  }
  catch (err) {
    yield put({
      type: "FETCH_INDUSTRIAL_PARK_REGION_XLS_FAILED",
      payload: err.status,
    });
  }
}
function* resetIndustrialParkState() {
  try {
    yield put({ type: "RESET_INDUSTRIAL_PARK_STATE_SUCCESS" });
  }
  catch(err) {
    yield put({ type: "RESET_INDUSTRIAL_PARK_STATE_FAILED" });
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_REQUEST', fetchIndustrialParkEditFormsData);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_BASIC_REQUEST', fetchIndustrialParkBasic);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_BUILDINGS_REQUEST', fetchIndustrialParkBuildings);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_LEASE_TERMS_REQUEST', fetchIndustrialParkLeaseTerms);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_REQUEST', fetchIndustrialParkMarketplaceStats);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_CHANGES_REQUEST', fetchIndustrialParkChanges);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_FILES_REQUEST', fetchIndustrialParkFiles);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_REQUEST', fetchIndustrialParkSimilarProperties);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_OWNER_REQUEST', fetchIndustrialParkOwner);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_REQUEST', fetchIndustrialParkLeasingContacts);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_CERTIFICATES_REQUEST', fetchIndustrialParkCertificates);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_UNITS_BASIC_REQUEST', fetchIndustrialParkUnitsBasic);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_REGION_REQUEST', fetchIndustrialParkRegion);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_REGION_SUPPLY_BOOST_REQUEST', fetchIndustrialParkRegionSupplyBoost);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_REGION_RENT_REQUEST', fetchIndustrialParkRegionRent);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_REGION_MAP_REQUEST', fetchIndustrialParkRegionMap);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_REGION_XLS_REQUEST', fetchIndustrialParkRegionXls);
  yield takeLatest('RESET_INDUSTRIAL_PARK_STATE_REQUEST', resetIndustrialParkState);
}
