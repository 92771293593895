import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography} from "@material-ui/core";
import {ThemeProvider} from "_metronic";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import ConfirmationDialog from "../ConfirmationDialog";
import LoadingScreen from "../LoadingScreen";
import {ReactComponent as IconClose} from "app/media/icons/icon-close.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class ModalCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.externalModalState || false,
      confirmationOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onToggle } = this.props;
    if (onToggle && !prevState.open && prevState.open !== this.state.open) {
      onToggle(this.state.open);
    }
    // Disable BI Insights auto modal open after initial action
    if (this.state.open && localStorage.getItem("redirectOfficeID")) {
      localStorage.removeItem("redirectOfficeID");
      localStorage.removeItem("redirectViewMode");
    }
  }

  handleClickOpen = (inactive) => {
    const { fetch, isOpen, gtagAllowed, onOpen } = this.props;
    fetch && fetch();
    onOpen && onOpen();
    if (!inactive) {
      this.setState(
        {
          open: true,
        },
        () => {
          if (isOpen) {
            isOpen(this.state.open);
          }
          if (gtagAllowed && typeof window.gtag === "function") {
            window.gtag("event", "Click", {
              event_category: "Button",
              event_label: "Become Premium",
            });
          }
        }
      );
    }
  };
  handleClose = () => {
    const {
      closeConfirmationRequired,
      handleClose,
      isOpen,
      externalPremiumPopupClose,
    } = this.props;

    if (closeConfirmationRequired) {
      this.setState({
        confirmationOpen: true,
      });
    } else {
      this.setState(
        {
          open: false,
        },
        () => {
          isOpen && isOpen(this.state.open);
          externalPremiumPopupClose && externalPremiumPopupClose();
          handleClose && handleClose();
        }
      );
    }
  };
  handleConfirmationRejected = () => {
    this.setState({
      confirmationOpen: false,
    });
  };
  handleConfirmationGranted = () => {
    const { handleClose, isOpen, externalPremiumPopupClose } = this.props;
    this.setState(
      {
        confirmationOpen: false,
        open: false,
      },
      () => {
        isOpen && isOpen(this.state.open);
        externalPremiumPopupClose && externalPremiumPopupClose();
        handleClose && handleClose();
      }
    );
  };

  render() {
    const {
      toolbar,
      btn,
      title,
      children,
      handleSave,
      btnFlex,
      fullWidth,
      btnReject,
      btnConfirm,
      btnColorReverse,
      maxWidth,
      fullScreen,
      inactive,
      btnStyle,
      responseDisabled,
      externalModalState,
      defaultThemeProvider,
      modalContentFlexCenter,
      bgColor = "#f2f3f8",
      btnInline,
      btnRejectDisabled,
      btnConfirmDisabled,
      justify,
      btnClassNames,
      disabled,
      saving,
      btnCloseAlt
    } = this.props;
    const { open } = this.state;
    const isOpen = externalModalState || open;
    return (
      <>
        <div
          className={clsx(
            btnFlex ? styles.btnFlex : undefined,
            btnInline ? styles.btnInline : undefined,
            inactive && !responseDisabled ? styles.inactive : undefined,
            disabled ? styles.disabled : undefined,
            btnClassNames
          )}
          onClick={() => this.handleClickOpen(inactive || disabled)}
          style={btnStyle}
        >
          {btn}
        </div>
        <ThemeProvider dark={!defaultThemeProvider}>
          {isOpen && (
            <Dialog
              fullScreen={fullScreen}
              maxWidth={maxWidth || "lg"}
              fullWidth={fullWidth}
              open={isOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              container={document.getElementById("root")}
              PaperProps={
                defaultThemeProvider && {
                  style: {
                    backgroundColor: bgColor,
                  },
                }
              }
            >
              {btnCloseAlt &&
              <button className={styles.btnCloseAlt} onClick={this.handleClose}>
                <IconClose width="2.4rem" height="2.4rem"/>
              </button>
              }
              {toolbar ? (
                <div className={styles.toolbarContainer}>
                  <DialogTitle id="alert-dialog-slide-title" disableTypography>
                    <Typography variant="h4">{title}</Typography>
                  </DialogTitle>
                  {toolbar}
                </div>
              ) : (
                title && (
                  <DialogTitle id="alert-dialog-slide-title" disableTypography>
                    <Typography variant="h4">{title}</Typography>
                  </DialogTitle>
                )
              )}
              <DialogContent
                className={
                  modalContentFlexCenter && styles.dialogContentFlexCenter
                }
                style={{ overflowX: "hidden" }}
              >
                {children}
              </DialogContent>

              {((!btnRejectDisabled && !btnCloseAlt) || handleSave) && (
                <DialogActions
                  style={{
                    justifyContent: justify || null,
                  }}
                >
                  {(!btnRejectDisabled || !btnCloseAlt) && (
                    <Button
                      variant="contained"
                      color={btnColorReverse ? "primary" : "secondary"}
                      className={styles.btnModal}
                      onClick={this.handleClose}
                    >
                      {btnReject || (
                        <FormattedMessage id="GENERAL.PHRASE.CLOSE" />
                      )}
                    </Button>
                  )}
                  {handleSave && (
                    saving ? <LoadingScreen loaderStyle={{width: "2rem", height: "2rem"}}/> :
                    <Button
                      variant="contained"
                      color={btnColorReverse ? "secondary" : "primary"}
                      disabled={btnConfirmDisabled}
                      className={styles.btnModal}
                      onClick={handleSave}
                    >
                      {btnConfirm || "Ok"}
                    </Button>
                  )}
                </DialogActions>
              )}
            </Dialog>
          )}
        </ThemeProvider>
        <ConfirmationDialog
          open={this.state.confirmationOpen}
          onClose={this.handleConfirmationRejected}
          onConfirm={this.handleConfirmationGranted}
          message={
            <>
              <Typography style={{ marginBottom: "1rem" }}>
                <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_CLOSE_CONFIRMATION_MESSAGE" />
              </Typography>
              <Typography>
                <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE" />
              </Typography>
            </>
          }
        />
      </>
    );
  }
}
