import React from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {FormattedMessage} from "react-intl";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import InputAdornments from "../../../partials/components/InputAdornments";
import ReactVisibilitySensor from "react-visibility-sensor";
import SelectSearch from "../../../partials/components/SelectSearch";
import {createVisibleArray, getParameterByName, sortArrayAlph} from "../../../utils/helpers";
import InputSwitcher from "../../../partials/components/InputSwitcher";
import cn from "classnames";
import ButtonCustom from "../../../partials/components/ButtonCustom";
import IsAdmin from "../../../utils/IsAdmin";
import LanguageSelector from "../../../partials/layout/LanguageSelector";

export default class AdminAddUser extends React.Component {
  constructor(props) {
    super(props);
    const isFromRequestTrial = getParameterByName("request")?.length > 0;
    const initFullName = getParameterByName("name");
    const initMail = getParameterByName("mail");
    const initPhone = getParameterByName("phone");
    const initLanguage = getParameterByName("lang")?.toLowerCase();
    const firstName = initFullName?.length > 0 ? initFullName?.split(" ")?.[0] : '';
    const lastName = initFullName?.length > 0 ? initFullName?.replace(firstName, "")?.trim() : '';

    this.state = {
      initLoaded: false,
      user: {
        firstName,
        lastName,
        email: initMail || '',
        phoneNumber: initPhone || '',
        companyName: '',
        langCode: initLanguage || 'pl',
        mailLangCode: initLanguage || 'pl',
        fromRequestTrial: isFromRequestTrial || false
      },
      team: {
        teamId: [],
        name: '',
        teamType: [],
        proOfficeUntil: '',
        proOfficeIndefinite: false,
        proOfficeMaxUsers: '',
        proIndustrialUntil: '',
        proIndustrialIndefinite: false,
        proIndustrialMaxUsers: '',
        officeViewEnable: false,
        industrialViewEnable: false
      },
      newTeam: false,
      createUserConfirm: false
    };
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchVisibleTeams, teamsLoading} = this.props;
    const {initLoaded} = this.state;

    if (isVisible && !teamsLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchVisibleTeams());
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }
  // Handlers
  handleCheckboxChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };
  handleChangeSwitcher = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      team: {
        ...this.state.team,
        teamId: []
      }
    });
  };
  handleUserDataChange = (event) => {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: event.target.value
      }
    });
  }
  handleTeamSelect = (target) => {
    this.setState({
      team: {
        ...this.state.team,
        [target.name]: target.value
      }
    });
  }
  handleTeamChange = (event) => {
    this.setState({
      team: {
        ...this.state.team,
        [event.target.name]: event.target.value
      }
    });
  }
  handleTeamCheckboxChange = event => {
    const isViewEnableOption = event?.target?.id === "officeViewEnable" || event?.target?.id === "industrialViewEnable";
    const isChecked = event.target.checked;
    const resetProSettings = isViewEnableOption && !isChecked;

    this.setState({
      team: {
        ...this.state.team,
        [event.target.id]: isChecked,
        ...resetProSettings ? {
          proOfficeUntil: '',
          proOfficeIndefinite: false,
          proOfficeMaxUsers: '',
          proIndustrialUntil: '',
          proIndustrialIndefinite: false,
          proIndustrialMaxUsers: '',
        } : {}
      }
    });
  };
  // Handle Create User
  handleCreateUser = (e) => {
    e.preventDefault();
    const {adminCreateUser} = this.props;
    const {user, team} = this.state;
    const mandatoryUserFieldsFilled = user?.email && user?.phoneNumber;
    const mandatoryTeamFieldsFilled = team?.teamId ? team?.teamId : (team?.name && team?.teamType?.length > 0);

    if(mandatoryUserFieldsFilled && mandatoryTeamFieldsFilled) {
      const data = {
        user: {
          ...user?.email ? {"email": user?.email} : {},
          ...user?.phoneNumber ? {"phone_number": user?.phoneNumber} : {},
          ...user?.firstName ? {"first_name": user?.firstName} : {},
          ...user?.lastName ? {"last_name": user?.lastName} : {},
          ...user?.companyName ? {"company_name": user?.companyName} : {},
          ...user?.langCode ? {"lang_code": user?.langCode} : {},
          ...user?.mailLangCode ? {"mail_lang_code": user?.mailLangCode} : {},
          ...user?.fromRequestTrial ? {"from_request_trial": user?.fromRequestTrial} : {}
        },
        team: team?.teamId?.length > 0 ?
        {
          "id": team?.teamId?.[0]
        } :
        {
          ...team?.name ? {"name": team?.name} : {},
          ...team?.teamType?.length > 0 ? {"team_type": team?.teamType?.[0]} : {},
          ...team?.proOfficeIndefinite ? {"pro_office_indefinite": team?.proOfficeIndefinite} : {},
          ...team?.proOfficeUntil ? {"pro_office_until": team?.proOfficeUntil} : {},
          ...team?.proOfficeMaxUsers ? {"pro_office_max_users": team?.proOfficeMaxUsers} : {},
          ...team?.proIndustrialIndefinite ? {"pro_industrial_indefinite": team?.proIndustrialIndefinite} : {},
          ...team?.proIndustrialUntil ? {"pro_industrial_until": team?.proIndustrialUntil} : {},
          ...team?.proIndustrialMaxUsers ? {"pro_industrial_max_users": team?.proIndustrialMaxUsers} : {},
          ...team?.officeViewEnable ? {"office_view_enable": team?.officeViewEnable} : {},
          ...team?.industrialViewEnable ? {"industrial_view_enable": team?.industrialViewEnable} : {}
        }
      };
      this.setState({
        createUserConfirm: false
      }, () => adminCreateUser && adminCreateUser(data));
    }
  }

  render() {
    const {teams, teamsLoading} = this.props;
    const {user, team, newTeam, createUserConfirm} = this.state;
    const sortedTeams = teams?.length > 0 ? sortArrayAlph(createVisibleArray(teams, "fullName")) : [];
    const teamTypes = [
      {
        id: 1,
        name: "Landlord"
      },
      {
        id: 3,
        name: "Agent"
      },
      {
        id: 4,
        name: "Owner"
      }
    ];
    const sortedTeamTypes = sortArrayAlph(createVisibleArray(teamTypes, "name"));

    return (
      <IsAdmin markup="section">
        <Portlet fluidHeight>
          <PortletHeader
            title={
              <ReactVisibilitySensor
                onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
                scrollCheck
                partialVisibility
              >
                <Typography variant="h3">
                  <FormattedMessage id="VOCABULARY.Add new user"/>
                </Typography>
              </ReactVisibilitySensor>
            }
          />
          <PortletBody>
            <form onSubmit={(e) => this.handleCreateUser(e)}>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <div className={styles.formFieldsWrapper}>
                    <Typography variant="h3" className={styles.columnTitle}>1. <FormattedMessage id="User data"/></Typography>
                    <InputAdornments
                      label={<FormattedMessage id="VOCABULARY.E-mail address"/>}
                      name="email"
                      value={user?.email}
                      onChange={this.handleUserDataChange}
                      required
                      disabled={user?.email && user?.fromRequestTrial}
                    />
                    <InputAdornments
                      label={<FormattedMessage id="VOCABULARY.Phone number"/>}
                      name="phoneNumber"
                      value={user?.phoneNumber}
                      onChange={this.handleUserDataChange}
                      required
                    />
                    <InputAdornments
                      label={<FormattedMessage id="VOCABULARY.First name"/>}
                      name="firstName"
                      value={user?.firstName}
                      onChange={this.handleUserDataChange}
                    />
                    <InputAdornments
                      label={<FormattedMessage id="VOCABULARY.Last name"/>}
                      name="lastName"
                      value={user?.lastName}
                      onChange={this.handleUserDataChange}
                    />
                    <InputAdornments
                      label={<FormattedMessage id="VOCABULARY.Company name"/>}
                      name="companyName"
                      value={user?.companyName}
                      onChange={this.handleUserDataChange}
                    />
                    <Typography variant="body1">
                      <FormattedMessage id="USER.PHRASE.APP_LANGUAGE"/>
                    </Typography>
                    <div className={styles.langSelectWrapper}>
                      <LanguageSelector
                        preventReload={true}
                        alignToLeft={true}
                        cutomHandler={(langCode) => {
                          this.setState({
                            user: {
                              ...this.state.user,
                             langCode: langCode
                            }
                          });
                          return true
                        }}
                        currentLanguage={this.state.user?.langCode}
                      />
                    </div>
                    <Typography variant="body1">
                      <FormattedMessage id="USER.PHRASE.EMAIL_LANGUAGE"/>
                    </Typography>
                    <div className={styles.langSelectWrapper}>
                      <LanguageSelector
                        preventReload={true}
                        alignToLeft={true}
                        cutomHandler={(langCode) => {
                          this.setState({
                            user: {
                              ...this.state.user,
                              mailLangCode: langCode
                            }
                          });
                          return true
                        }}
                        currentLanguage={this.state.user?.mailLangCode}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <div className={styles.formFieldsWrapper}>
                    <Typography variant="h3" className={styles.columnTitle}>2. <FormattedMessage id="Team"/></Typography>
                    <SelectSearch
                      name="teamId"
                      label={<FormattedMessage id="VOCABULARY.Select existing team"/>}
                      options={sortedTeams}
                      optionsFull={teams}
                      optionFullLabel="fullName"
                      optionFullValue="id"
                      loading={teamsLoading}
                      value={team?.teamId}
                      isDisabled={teamsLoading || newTeam}
                      marginOff
                      onChange={this.handleTeamSelect}
                    />
                    <InputSwitcher
                      checked={newTeam}
                      value="newTeam"
                      label={<FormattedMessage id="VOCABULARY.Create new Team"/>}
                      onChange={this.handleChangeSwitcher("newTeam")}
                    />
                    {newTeam &&
                      <div className={styles.newTeamFieldsWrapper}>
                        <InputAdornments
                          label={<FormattedMessage id="VOCABULARY.Team name"/>}
                          name="name"
                          value={team?.name}
                          onChange={this.handleTeamChange}
                          style={{marginBottom: "1.6rem"}}
                          required
                        />
                        <SelectSearch
                          name="teamType"
                          label={<FormattedMessage id="VOCABULARY.Select team type"/>}
                          options={sortedTeamTypes}
                          optionsFull={teamTypes}
                          optionFullLabel="name"
                          optionFullValue="id"
                          marginOff
                          onChange={this.handleTeamSelect}
                        />
                        <Grid container spacing={4} style={{marginTop: "1.6rem"}}>
                          <Grid item xs={6}>
                            <Typography variant="h4"><FormattedMessage id="VOCABULARY.Office Permissions"/></Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="officeViewEnable"
                                  color="primary"
                                  checked={team?.officeViewEnable}
                                  onChange={this.handleTeamCheckboxChange}
                                />
                              }
                              label={<FormattedMessage id="VOCABULARY.Office view enabled"/>}
                            />
                            <Typography
                              variant="body1"
                              className={styles.subtitle}
                            >
                              <FormattedMessage id="VOCABULARY.PRO Settings"/>
                            </Typography>
                            <div className={styles.formFieldsWrapper}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id="proOfficeIndefinite"
                                    color="primary"
                                    checked={team?.proOfficeIndefinite}
                                    onChange={this.handleTeamCheckboxChange}
                                  />
                                }
                                label={<FormattedMessage id="VOCABULARY.PRO indefinite"/>}
                                disabled={!team?.officeViewEnable}
                              />
                              <InputAdornments
                                type="date"
                                label={<FormattedMessage id="VOCABULARY.PRO active until"/>}
                                name="proOfficeUntil"
                                value={team?.proOfficeUntil}
                                onChange={this.handleTeamChange}
                                disabled={team?.proOfficeIndefinite || !team?.officeViewEnable}
                              />
                              <InputAdornments
                                label={<FormattedMessage id="VOCABULARY.PRO max users"/>}
                                name="proOfficeMaxUsers"
                                value={team?.proOfficeMaxUsers}
                                onChange={this.handleTeamChange}
                                disabled={!team?.officeViewEnable}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h4"><FormattedMessage id="VOCABULARY.Industrial Permissions"/></Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="industrialViewEnable"
                                  color="primary"
                                  checked={team?.industrialViewEnable}
                                  onChange={this.handleTeamCheckboxChange}
                                />
                              }
                              label={<FormattedMessage id="VOCABULARY.Industrial view enabled"/>}
                            />
                            <Typography
                              variant="body1"
                              className={styles.subtitle}
                            >
                              <FormattedMessage id="VOCABULARY.PRO Settings"/>
                            </Typography>
                            <div className={cn(styles.formFieldsWrapper, styles.noBorder)}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id="proIndustrialIndefinite"
                                    color="primary"
                                    checked={team?.proIndustrialIndefinite}
                                    onChange={this.handleTeamCheckboxChange}
                                  />
                                }
                                label={<FormattedMessage id="VOCABULARY.PRO indefinite"/>}
                                disabled={!team?.industrialViewEnable}
                              />
                              <InputAdornments
                                type="date"
                                label={<FormattedMessage id="VOCABULARY.PRO active until"/>}
                                name="proIndustrialUntil"
                                value={team?.proIndustrialUntil}
                                onChange={this.handleTeamChange}
                                disabled={team?.proIndustrialIndefinite || !team?.industrialViewEnable}
                              />
                              <InputAdornments
                                label={<FormattedMessage id="VOCABULARY.PRO max users"/>}
                                name="proIndustrialMaxUsers"
                                value={team?.proIndustrialMaxUsers}
                                onChange={this.handleTeamChange}
                                disabled={!team?.industrialViewEnable}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h3" className={styles.columnTitle}>3. <FormattedMessage id="Confirm"/></Typography>
                  <p className={styles.confirmMessageWarning}>
                    <strong><FormattedMessage id="VOCABULARY.Attention"/>! </strong>
                    <span className={styles.confirmMessage}><FormattedMessage id="VOCABULARY.Create account confirm message"/></span>
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="createUserConfirm"
                        color="primary"
                        checked={createUserConfirm}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label={<FormattedMessage id="VOCABULARY.Create user confirm"/>}
                    style={{display: "block"}}
                  />
                  <ButtonCustom
                    label={<FormattedMessage id="VOCABULARY.Create user"/>}
                    color="danger"
                    solid
                    inactive={!createUserConfirm}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </form>
          </PortletBody>
        </Portlet>
      </IsAdmin>
    );
  }
}