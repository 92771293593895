import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Grid} from "@material-ui/core";
import PropertyHeader from "./components/PropertyHeader";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import PropertyBuildings from "./components/content/PropertyBuildings";
import PrintModeDetect from "../../../partials/components/PrintModeDetect";
import PropertySimilarProperties from "../../common/components/PropertyPage/PropertySimilarProperties";
import PropertyContact from "../../common/components/PropertyPage/PropertyContact";
import PropertyLeaseTerms from "./components/content/PropertyLeaseTerms";
import PropertyRegionArea from "./components/PropertyRegionArea";
import PropertyCertificates from "../../common/components/PropertyPage/PropertyCertificates";
import PropertyUnits from "app/pages/industrial/PropertyWarehousePage/components/content/PropertyUnits";
import PropertyRegionSupplyBoost from "./components/content/PropertyRegionSupplyBoost";
import PropertyRegionRent from "./components/content/PropertyRegionRent";

const breakPointValue = 1054; // Content Container width, NOT window width
const breakPointValueLogotypes = 1300; // Content Container width, NOT window width
const breakPointValueAreaTable = 1215; // Content Container width, NOT window width
export default class PropertyParkPage extends React.Component {
  constructor(props) {
    super(props);
    const contentRoot = document.getElementById("kt_content").offsetWidth;
    const breakPointState = contentRoot < breakPointValue;
    const breakPointLogotypesState = contentRoot < breakPointValueLogotypes;
    const breakPointAreaTableState = contentRoot < breakPointValueAreaTable;

    this.state = {
      breakPoint: breakPointState || false,
      breakPointLogotypes: breakPointLogotypesState || false,
      breakPointAreaTable: breakPointAreaTableState || false
    }

    this.props.resetIndustrialParkState && this.props.resetIndustrialParkState();
  }
  componentDidMount() {
    const body = document.getElementById("root");
    setTimeout(() => {
      body.scrollTo({
        top: 0
      });
    }, 100);
    //
    const contentRoot = document.getElementById("kt_content");
    const observer = new ResizeObserver(entries => {
      const e = entries[0]; // should be only one
      const width = e.contentRect.width;
      const breakPointState = width < breakPointValue;
      const breakPointLogotypesState = width < breakPointValueLogotypes;
      const breakPointAreaTableState = width < breakPointValueAreaTable;
      //
      if(this.state.breakPoint !== breakPointState) {
        this.setState({
          breakPoint: breakPointState
        });
      }
      //
      if(this.state.breakPointLogotypes !== breakPointLogotypesState) {
        this.setState({
          breakPointLogotypes: breakPointLogotypesState
        });
      }
      //
      if(this.state.breakPointAreaTable !== breakPointAreaTableState) {
        this.setState({
          breakPointAreaTable: breakPointAreaTableState
        });
      }
    })
    // start listening for size changes
    observer.observe(contentRoot);
  }

  render() {
    if(this.props.resetting) {
      return <LoadingScreen/>;
    }
    const {breakPoint, breakPointLogotypes, breakPointAreaTable} = this.state;
    const leftColumnOrder = ["header", "regionArea", "regionSupplyBoost", "regionRent", "warehouses", "units", "similar"];
    const rightColumnOrder = ["contact", "leaseTerms", "certificates"];
    const mergedColumnOrder = ["header", "contact", "regionArea", "warehouses", "units", "leaseTerms", "certificates", "similar"];
    const leftColumnContent = [
      {
        key: "header",
        section: (
          <Grid item xs={12}>
            <PropertyHeader
              breakPoint={breakPoint}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "regionArea",
        section: (
          <Grid item xs={12}>
            <PropertyRegionArea
              breakPoint={breakPoint}
              breakPointAreaTable={breakPointAreaTable}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "regionSupplyBoost",
        section: (
          <Grid item xs={6}>
            <PropertyRegionSupplyBoost
              breakPoint={breakPoint}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "regionRent",
        section: (
          <Grid item xs={6}>
            <PropertyRegionRent
              breakPoint={breakPoint}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "warehouses",
        section: (
          <Grid item xs={12}>
            <PropertyBuildings
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "units",
        section: (
          <Grid item xs={12}>
            <PropertyUnits
              source="park"
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "similar",
        section: (
          <PrintModeDetect hide>
            <Grid item xs={12}>
              <PropertySimilarProperties {...this.props}/>
            </Grid>
          </PrintModeDetect>
        )
      },
    ];
    const rightColumnContent = [
      {
        key: "contact",
        section: (
          <Grid item xs={12}>
            <PropertyContact
              oneLine={breakPoint}
              logoBreak={breakPointLogotypes}
            />
          </Grid>
        )
      },
      {
        key: "leaseTerms",
        section: (
          <Grid item xs={breakPoint ? 6 : 12}>
            <PropertyLeaseTerms {...this.props}/>
          </Grid>
        )
      },
      {
        key: "certificates",
        section: (
          <PropertyCertificates breakPoint={breakPoint} {...this.props}/>
        )
      }
    ];
    const mergedColumnContent = leftColumnContent.concat(rightColumnContent);
    const leftColumnFinalOrder = leftColumnOrder.map(orderItem=> leftColumnContent.find(contentItem => contentItem?.key === orderItem));
    const rightColumnFinalOrder = rightColumnOrder.map(orderItem=> rightColumnContent.find(contentItem => contentItem?.key === orderItem));
    const mergedColumnFinalOrder = mergedColumnOrder.map(orderItem=> mergedColumnContent.find(contentItem => contentItem?.key === orderItem));

    if(breakPoint) {
      return(
        <Grid container>
          <Grid item xs={12}>
            <Portlet
              shadowDisabled
            >
              <PortletBody>
                <Grid container spacing={5}>
                  {mergedColumnFinalOrder?.map((item,index) => {
                    return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                  })}
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      );
    }
    return(
      <Grid container>
        <Grid item xs={9}>
          <Portlet
            shadowDisabled
          >
            <PortletBody>
              <Grid container spacing={4}>
                {leftColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
        <Grid item xs={3}>
          <Portlet
            fluidHeight
            shadowDisabled
            style={{backgroundColor: "rgba(250, 250, 250, 0.8)"}}
          >
            <PortletBody>
              <Grid container spacing={5}>
                {rightColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}