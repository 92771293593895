import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Grid} from "@material-ui/core";
import PropertyLeaseTerms from "./components/content/PropertyLeaseTerms";
import PropertyHeader from "./components/PropertyHeader";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import PropertyUnits from "./components/content/PropertyUnits";
import PropertyContact from "../../common/components/PropertyPage/PropertyContact";
import PropertyAmenities from "../../common/components/PropertyPage/PropertyAmenities";
import PropertyHeaderBackToPark from "./components/PropertyHeaderBackToPark";

const breakPointValue = 1054; // Content Container width, NOT window width
const breakPointValueLogotypes = 1300; // Content Container width, NOT window width
export default class PropertyWarehousePage extends React.Component {
  constructor(props) {
    super(props);
    const contentRoot = document.getElementById("kt_content").offsetWidth;
    const breakPointState = contentRoot < breakPointValue;
    const breakPointLogotypesState = contentRoot < breakPointValueLogotypes;

    this.state = {
      breakPoint: breakPointState || false,
      breakPointLogotypes: breakPointLogotypesState || false
    }

    this.props.resetIndustrialWarehouseState && this.props.resetIndustrialWarehouseState();
  }

  componentDidMount() {
    const body = document.getElementById("root");
    setTimeout(() => {
      body.scrollTo({
        top: 0
      });
    }, 100);
    //
    const contentRoot = document.getElementById("kt_content");
    const observer = new ResizeObserver(entries => {
      const e = entries[0]; // should be only one
      const width = e.contentRect.width;
      const breakPointState = width < breakPointValue;
      const breakPointLogotypesState = width < breakPointValueLogotypes;
      //
      if(this.state.breakPoint !== breakPointState) {
        this.setState({
          breakPoint: breakPointState
        });
      }
      //
      if(this.state.breakPointLogotypes !== breakPointLogotypesState) {
        this.setState({
          breakPointLogotypes: breakPointLogotypesState
        });
      }
    })
    // start listening for size changes
    observer.observe(contentRoot);
  }

  render() {
    if(this.props.resetting) {
      return <LoadingScreen/>;
    }
    const {breakPoint, breakPointLogotypes} = this.state;
    const leftColumnOrder = ["header", "units"];
    const rightColumnOrder = ["contact", "leaseTerms"];
    const mergedColumnOrder = ["header", "contact", "units", "leaseTerms"];
    const leftColumnContent = [
      {
        key: "header",
        section: (
          <Grid item xs={12}>
            <PropertyHeader
              breakPoint={breakPoint}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "units",
        section: (
          <Grid item xs={12}>
            <PropertyUnits
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "amenitiesOffice",
        section: (
          <Grid item xs={6}>
            <PropertyAmenities
              sectionTitle="Udogodnienia magazynowe"
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "amenitiesIndustrial",
        section: (
          <Grid item xs={6}>
            <PropertyAmenities
              sectionTitle="Udogodnienia biurowe"
              {...this.props}
            />
          </Grid>
        )
      }
    ];
    const rightColumnContent = [
      {
        key: "contact",
        section: (
          <Grid item xs={12}>
            <PropertyContact
              oneLine={breakPoint}
              logoBreak={breakPointLogotypes}
            />
          </Grid>
        )
      },
      {
        key: "leaseTerms",
        section: (
          <Grid item xs={breakPoint ? 6 : 12}>
            <PropertyLeaseTerms {...this.props}/>
          </Grid>
        )
      },
      {
        key: "buildingFeatures",
        section: (
          <Grid item xs={12}>
            <PropertyAmenities
              sectionTitle="Cechy budynku"
              {...this.props}
            />
          </Grid>
        )
      }
    ];
    const mergedColumnContent = leftColumnContent.concat(rightColumnContent);
    const leftColumnFinalOrder = leftColumnOrder.map(orderItem=> leftColumnContent.find(contentItem => contentItem?.key === orderItem));
    const rightColumnFinalOrder = rightColumnOrder.map(orderItem=> rightColumnContent.find(contentItem => contentItem?.key === orderItem));
    const mergedColumnFinalOrder = mergedColumnOrder.map(orderItem=> mergedColumnContent.find(contentItem => contentItem?.key === orderItem));

    if(breakPoint) {
      return(
        <Grid container>
          <Grid item xs={12}>
            <Portlet
              shadowDisabled
            >
              <PortletBody>
                <Grid container spacing={5}>
                  {mergedColumnFinalOrder?.map((item,index) => {
                    return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                  })}
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      );
    }
    return(
      <Grid container>
        <Grid item xs={12}>
          <PropertyHeaderBackToPark data={this.props.basic}/>
        </Grid>
        <Grid item xs={9}>
          <Portlet
            shadowDisabled
          >
            <PortletBody>
              <Grid container spacing={4}>
                {leftColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
        <Grid item xs={3}>
          <Portlet
            fluidHeight
            shadowDisabled
            style={{backgroundColor: "rgba(250, 250, 250, 0.8)"}}
          >
            <PortletBody>
              <Grid container spacing={5}>
                {rightColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}