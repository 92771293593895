import React from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import {ROUTES} from "app/constants";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import {toNumberFormat} from "app/utils/helpers";
import {fetchOfficeCompetitors} from "app/crud/estates/offices/officeBuilding.crud";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import TooltipCustom from "../../../../../partials/components/TooltipCustom";
import {Typography} from "@material-ui/core";
import PropertyComparisonXlsDownload from "./PropertyComparisonXlsDownload";
import BlurText from "../../../../../partials/components/BlurText";
import PaywallBannerRowTable from "../../../../../partials/components/PayWall/banners/PaywallBannerRowTable";

class PropertyCompetitionCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tBody: null,
      initLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {competitors} = this.props;
    // Re-render TableBody
    if(
      // if offices was empty
      (_.isEmpty(prevProps?.competitors) && competitors?.length > 0) ||
      // if offices was updated
      (!_.isEqual(prevProps?.competitors, competitors))
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }
  // Handlers
  handlePrepTableBody = () => {
    const {competitors, match} = this.props;
    const officeID = match?.params?.id;

    if(competitors?.length > 0) {
      return competitors?.map((item, index) => {
        const isPropertyPrimary = Number(officeID) === item?.id;
        const address = [
          ...item?.addressStreet ? [item?.addressStreet] : [],
          ...item?.addressCity ? [item?.addressCity] : []
        ].join(", ");
        const isBlurred = false;

        return {
          data: [
            <PropertyCardPreview
              propertyID={index > 0 ? item?.id : undefined}
              title={item?.name}
              semiTitle={address}
              thumb={item?.imageThumbnail}
              route={ROUTES.BUILDING_PAGE}
              routeNewTab
              noOverflow
              titleStyles={
              isPropertyPrimary ? {
                color: "#2d2d2d"
              } : undefined}
            />,
            <BlurText
              type="area"
              isBlurred={isBlurred}
            >
              {toNumberFormat(item?.areaAvailable, 'area')}
            </BlurText>,
            <BlurText
              type="area"
              isBlurred={isBlurred}
            >
              {toNumberFormat(item?.transactions6m || 0, 'area')}
            </BlurText>,
            <BlurText
              type="area"
              isBlurred={isBlurred}
            >
              {toNumberFormat(item?.supplyBoost6m || 0, 'area')}
            </BlurText>,
            <BlurText
              type="currency"
              isBlurred={isBlurred}
            >
              {toNumberFormat(item?.rent, 'currency')}
            </BlurText>,
            <BlurText
              isBlurred={isBlurred}
              customBlurValue={
              <FormattedMessage
                id="BUILDING.PHRASE.REDD_INDEX_DAYS"
                values={{days: 123 }}
              />
              }
            >
              {item?.timeToLease ?
              <FormattedMessage
                id="BUILDING.PHRASE.REDD_INDEX_DAYS"
                values={{days: Number(item?.timeToLease?.toFixed(0)) }}
              /> : "-"
              }
            </BlurText>
          ]
        }
      });
    }
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficeCompetitors,
      competitorsLoading
    } = this.props;
    const {initLoaded} = this.state;
    const officeID = this.props.match?.params?.id;

    if(isVisible && !competitorsLoading && !initLoaded && officeID) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficeCompetitors(officeID));
    }
  };
  handleLabelTooltip = (label, tooltip) => {
    return(
      <TooltipCustom
        title={<FormattedMessage id={`${tooltip}`}/>}
        placement="bottom"
      >
        <FormattedMessage id={`${label}`}/>
      </TooltipCustom>
    );
  }

  render() {
    const {competitorsLoading, basic, intl} = this.props;
    const {tBody} = this.state;
    const tHead = [
      {
        "label": "",
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Availability"/>,
        "number": true
      },
      {
        "label": this.handleLabelTooltip("VOCABULARY.6M Take-up", "VOCABULARY.transactions_tooltip"),
        "number": true
      },
      {
        "label": this.handleLabelTooltip("VOCABULARY.6M New Vacancy", "VOCABULARY.supply_boost_tooltip"),
        "number": true
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Asking rent"/>,
        "number": true
      },
      {
        "label": this.handleLabelTooltip("VOCABULARY.Time to lease", "VOCABULARY.time_to_lease_tooltip"),
        "number": true
      }
    ];
    const toolbarLabel = [
      ...basic?.buildingClass ? [`${intl.formatMessage({id: "VOCABULARY.Class"})} ${basic?.buildingClass}`] : [],
      ...basic?.addressCity ? [`${basic?.addressCity}`] : []
    ].join(" • ");

    return(
      <div className={styles.propertyCompetitorsCompare}>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            title={<FormattedMessage id="VOCABULARY.Comparable properties"/>}
            toolbar={!competitorsLoading &&
            <div className={styles.titleToolbarWrapper}>
              <Typography style={{fontWeight: 400}}>{toolbarLabel}</Typography>
              <PropertyComparisonXlsDownload
                reddClick="office-building-competition-compare-xls-download"
              />
            </div>
            }
          />
        </ReactVisibilitySensor>
        {competitorsLoading ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData withIcon/> :
            <TableCustom
              tHead={tHead}
              tBody={tBody}
              propertyCellIndex={0}
              propertyPrimaryRowIndex={0}
              propertyPrimaryRowStyle={{
                backgroundColor: "#F9F9F9"
              }}
              propertyPrimaryCellStyle={{
                fontWeight: "700",
                borderBottomColor: "#A2A5B8"
              }}
              tHeadCellStyle={{
                color: "#2d2d2d"
              }}
              responsive
              initialLimit={5}
              limitBtnLabel={<FormattedMessage id="VOCABULARY.Show all"/>}
              paywallBanner={<PaywallBannerRowTable bannerVariant="row_table-property-competition-compare"/>}
              paywallActive={false}
            />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basic: state.officeBuilding.basic,
  competitors: state.officeBuilding.competitors,
  competitorsLoading: state.officeBuilding.competitorsLoading,
});
const mapDispatchToProps = {
  fetchOfficeCompetitors: (officeID) => fetchOfficeCompetitors(officeID)
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertyCompetitionCompare)