import React from "react";
import {FormattedMessage} from "react-intl";
import IconColorMUI from "../../../../../partials/components/IconColorMUI";
import ButtonSimple from "../../../../../partials/components/ButtonSimple";
import {callReddClickEvent} from "../../../../../crud/user.crud";
import {fetchOfficeComparisonXls} from "../../../../../crud/estates/offices/officeBuilding.crud";
import connect from "react-redux/es/connect/connect";
import PaywallModal from "../../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../../utils/IsPro";

function PropertyComparisonXlsDownload({basic, xlsLoading, fetchOfficeComparisonXls, reddClick}) {
  const handleDownloadXls = () => {
    const officeID = basic?.id;
    const officeName = basic?.name;

    if(!xlsLoading && fetchOfficeComparisonXls && officeID) {
      callReddClickEvent(
        reddClick,
        'office',
        null,
        officeID
      );
      fetchOfficeComparisonXls(officeID, officeName);
    }
  }

  return(
    <IsPro
      paywall
      paywallComponent={
      <PaywallModal
        triggerBtn={
        <ButtonSimple
          label={<FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>}
          icon={
          <IconColorMUI
            icon="download"
            size="18px"
            style={{paddingRight: "5px", lineHeight: "0"}}
          />
          }
        />
        }
      />
      }
    >
      <ButtonSimple
        label={<FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>}
        icon={
        <IconColorMUI
          icon="download"
          size="18px"
          style={{paddingRight: "5px", lineHeight: "0"}}
        />
        }
        loading={xlsLoading}
        onClick={() => handleDownloadXls()}
      />
    </IsPro>
  );
}

const mapStateToProps = store => ({
  basic: store.officeBuilding.basic,
  xlsLoading: store.officeBuilding.xlsLoading
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchOfficeComparisonXls: (officeID, officeName) => fetchOfficeComparisonXls(officeID, officeName)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyComparisonXlsDownload);