import {toAbsoluteUrl} from "_metronic";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {ROUTES} from "app/constants";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Avatar} from "app/partials/components/Avatar";
import {FormattedMessage, injectIntl} from "react-intl";
import ConfirmationDialog from "../ConfirmationDialog";
import {updateUserMarket} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import IsAdmin from "../../../utils/IsAdmin";

class BuildingCardPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmRedirectOpen: false
    }
  }

  handleConfirmOpen = () => {
    this.setState({
      confirmRedirectOpen: true
    });
  };
  handleConfirmClose = () => {
    this.setState({
      confirmRedirectOpen: false
    });
  };
  handleProceed = () => {
    const {updateUserMarket, marketID, officeID} = this.props;
    updateUserMarket(marketID, `${ROUTES.BUILDING_PAGE}/${officeID}`);
    this.handleConfirmClose();
  };

  renderPreview = () => {
    const {name, street, city, thumb, margin, small, themeDark, vertical, onTrackOpen} = this.props;
    const avatar = thumb !== null ? thumb : toAbsoluteUrl("media/branding/redd-logo-neon-200px.png");
    const semiTitleFinal = [
      ...city ? [`${city}`] : [],
      ...street ? [`${street}`] : []
    ].join(", ");

    return (
      <Grid
        container
        direction={vertical ? "column" : "row"}
        justifyContent="flex-start"
        alignItems={vertical ? "stretch" : "center"}
        wrap="nowrap"
        onClick={onTrackOpen}
        spacing={1}
      >
        {thumb !== "disabled" &&
          <Grid item xs={vertical && 12}>
            <Avatar avatar={avatar} margin={margin} size={vertical && "100%"}/>
          </Grid>
        }
        <Grid item xs={vertical && 12}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Typography variant={small ? "h5" : "h4"} color="primary"
                        style={vertical && {marginTop: 10, minHeight: 40}}>{name}</Typography>
            {street && (
              <Typography variant="body2" style={themeDark && {color: "#ffffff"}}>{semiTitleFinal}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {officeID, marketID} = this.props;
    const diffMarket = marketID !== this.props.userMarket;
    const isAdmin = IsAdmin();
    if (diffMarket && officeID && marketID && !isAdmin) {
      return (
        <>
          <span onClick={() => this.handleConfirmOpen()} style={{cursor: "pointer"}}>{this.renderPreview()}</span>
          <ConfirmationDialog
            open={this.state.confirmRedirectOpen}
            onClose={() => this.handleConfirmClose()}
            onConfirm={() => this.handleProceed()}
            message={<FormattedMessage id="ACQUISITIONS.PHRASE.DIFF_MARKET_MESSAGE"/>}
          />
        </>
      );
    }
    else if (officeID) {
      return (
        <RouterLink to={officeID ? `${ROUTES.BUILDING_PAGE}/${officeID}` : false}>
          {this.renderPreview()}
        </RouterLink>
      );
    }
    return this.renderPreview();
  }
}

const mapStateToProps = (state) => ({
  userMarket: state.user.data.market
});
const mapDispatchToProps = dispatch => ({
  updateUserMarket: (market, redirect) => dispatch(updateUserMarket(market, redirect)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(BuildingCardPreview))
);