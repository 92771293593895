import React from "react";
import ModalIPI from "../../../partials/components/IPI/ModalIPI";
import IPI_PLAN_SOURCE from "../../../constants/IPI_PLAN_SOURCE";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "../../../../_metronic";
import cn from "classnames";
import {FormattedMessage} from "react-intl";
import ButtonCustom from "../../../partials/components/ButtonCustom";

export default class IndustrialPreviewIPI extends React.Component {
    render() {
        const {title, planSource, planPark, planBuilding, parkID, buildingID, isEditable, units, triggerStyle, triggerBtnStyle, modalTrigger} = this.props;
        const ipiPlan = planSource === IPI_PLAN_SOURCE.WAREHOUSE ? (planBuilding ? planBuilding : planPark) : planSource === IPI_PLAN_SOURCE.PARK && planPark;
        const bgImage = ipiPlan ? ipiPlan : toAbsoluteUrl("media/industrial-siteplan-placeholder.jpg");

        return(
            <ModalIPI
                title={title}
                planSource={planSource}
                planPark={planPark}
                planBuilding={planBuilding}
                parkID={parkID}
                buildingID={buildingID}
                isEditable={isEditable}
                units={units}
                modalTrigger={
                  modalTrigger ? modalTrigger :
                    <div className={cn(styles.ipiPreviewTriggerWrapper, !ipiPlan && styles.planPlaceholder)} style={{"--plan":`url(${bgImage})`, ...triggerStyle}}>
                        {!ipiPlan && <ButtonCustom btnStyle={triggerBtnStyle} btnStyleForced={triggerBtnStyle} color="danger" icon="add" solid disabled label={<FormattedMessage id="INDUSTRIAL.PHRASE.IPI_UPLOAD_PLAN"/>}/>}
                        {ipiPlan && <ButtonCustom btnStyle={triggerBtnStyle} btnStyleForced={triggerBtnStyle} color="danger" icon="texture" solid disabled label={<FormattedMessage id="INDUSTRIAL.PHRASE.IPI_VIEW_PLAN"/>}/>}
                    </div>
                }
                btnStyle={{
                  width: "100%",
                  ...triggerBtnStyle
                }}
            />
        );
    }
}