import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import InputSwitcher from "app/partials/components/InputSwitcher";
import ButtonCustom from "app/partials/components/ButtonCustom";
import TableMUI from "app/partials/components/TableMUI";
import {Checkbox, TableCell, TableRow} from "@material-ui/core";
import {handleTableCellAlign} from "../../../../../utils/helpers";
import UnitFloorPreview from "app/partials/components/_DataParts/UnitFloorPreview";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import {ROUTES, UNIT_STATUS} from "app/constants";
import UnitAvailabilityPreview from "app/partials/components/_DataParts/UnitAvailabilityPreview";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";
import {FormattedMessage} from "react-intl";
import UnitAreaPreview from "app/partials/components/_DataParts/UnitAreaPreview";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import EditModal from "app/partials/components/EditModal";
import NoData from "app/partials/components/NoData/NoData";
import IndustrialUnitRefreshAll from "../../../components/ActionsUnits/IndustrialUnitRefreshAll";
import IndustrialUnitRefreshSelected from "../../../components/ActionsUnits/IndustrialUnitRefreshSelected";
import IndustrialWarehouseUnitAdd
  from "../../../../../partials/components/TableProperties/TableActions/TableActionsIndustrial/components/IndustrialWarehouseUnitAdd";
import LackOfAvailableArea from "../../../../../partials/components/LackOfAvailableArea/LackOfAvailableArea";
import styles from "./index.module.scss";
import IsPro from "../../../../../utils/IsPro";
import PaywallBlurContent from "../../../../../partials/components/PayWall/components/PaywallBlurContent";
import PaywallBannerSimple from "../../../../../partials/components/PayWall/banners/PaywallBannerSimple";
import {ModalTrigger} from "../../../../../partials/components/ModalCustom/ModalTrigger";
import PropertyCardPreview from "../../../../../partials/components/_DataParts/PropertyCardPreview";

export default class PropertyUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnavailable: "",
      initLoaded: false,
      ordering: '',
      selected: []
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialWarehouseUnits, fetchIndustrialParkUnits, units, unitsLoading, match, source} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;
    const isSourcePark = source === "park";

    setTimeout(() => {
      if(isVisible && !initLoaded && !unitsLoading && _.isEmpty(units) && id) {
        this.setState({
          initLoaded: true,
        }, () => {
          if(isSourcePark) {
            fetchIndustrialParkUnits(id);
          }
          else {
            fetchIndustrialWarehouseUnits(id);
          }
        });
      }
    }, 200);
  }
  handleToggleUnavailable = (e) => {
    this.setState({
      showUnavailable: e?.target?.checked ? e.target.value : "",
    });
  };
  handleSortTable = (orderBy, order) => {
    const {fetchIndustrialWarehouseUnits, fetchIndustrialParkUnits, unitsLoading, match, source} = this.props;
    const {id} = match?.params;
    const isSourcePark = source === "park";

    if(orderBy && !unitsLoading) {
      const ordering = [
        ...order ? [`${order}`] : [],
        ...orderBy ? [`${orderBy}`] : []
      ].join("");

      this.setState({
        ordering
      }, () => {
        if(isSourcePark) {
          fetchIndustrialParkUnits(id);
        }
        else {
          fetchIndustrialWarehouseUnits(id, ordering);
        }
      });
    }
  }
  handleSelectRow = (event) => {
    const {selected} = this.state;
    const isChecked = event?.target?.checked;
    const value = Number(event?.target?.value);
    let selectedUpdated;

    if(isChecked) {
      selectedUpdated = [
        ...selected,
        value
      ];
    }
    else {
      selectedUpdated = selected.filter(item => item !== value);
    }
    this.setState({
      selected: selectedUpdated
    });
  }
  handleSelectAll = (tBody, checked) => {
    const selectedUpdated = checked ? tBody?.map(item => item?.id) : [];
    this.setState({
      selected: selectedUpdated
    });
  }
  handleOnRefreshComplete = () => {
    this.setState({
      selected: []
    });
  }
  // Helpers
  isSelected = (item) => this.state.selected?.indexOf(item) !== -1;

  render() {
    const {parentRef, basic, units, unitsLoading, source} = this.props;
    const {showUnavailable, ordering, selected} = this.state;
    const isSourcePark = source === "park";
    const tHead = [
        {id: 'type', numeric: false, disablePadding: false, label: 'VOCABULARY.Type', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'VOCABULARY.Size', sort: false},
        {id: 'docks', numeric: true, disablePadding: false, label: 'VOCABULARY.Loading docks', sort: false},
        {id: 'available_from', numeric: false, disablePadding: false, label: 'VOCABULARY.Availability', sort: false},
        ...isSourcePark ? [{id: 'building', numeric: false, disablePadding: false, label: 'VOCABULARY.Building', sort: false}] : [],
        {id: 'updated_at', numeric: false, disablePadding: false, label: 'VOCABULARY.Update', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];
    const tBody = units?.length > 0 ? (
      showUnavailable ? units?.filter(unit => unit?.status === UNIT_STATUS.UNAVAILABLE) :
        units?.filter(unit => unit?.status !== UNIT_STATUS.UNAVAILABLE)
    ) : null;
    const totalAvailableItems = units?.length > 0 && units?.filter(unit => unit?.status !== UNIT_STATUS.UNAVAILABLE)?.length;
    const isPropertyEditable = basic?.isEditable;

    return(
      <>
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.Available units"/>}
          titleActions={
            isPropertyEditable &&
            <>
            {!isSourcePark && selected?.length === totalAvailableItems &&
              <IndustrialUnitRefreshAll
                propertyID={basic?.id}
                ordering={ordering}
                source={source}
                color="#ff4626"
                onRefreshComplete={this.handleOnRefreshComplete}
              />
            }
            {!isSourcePark && selected?.length > 0 && selected?.length < totalAvailableItems &&
              <IndustrialUnitRefreshSelected
                warehouseID={basic?.id}
                ordering={ordering}
                units={selected}
                color="#ff4626"
                onRefreshComplete={this.handleOnRefreshComplete}
              />
            }
            </>
          }
          toolbar={
          <>
            {!isSourcePark && isPropertyEditable &&
            <InputSwitcher
              checked={showUnavailable === "true"}
              value="true"
              label={<FormattedMessage id="VOCABULARY.Show unavailable units"/>}
              onChange={this.handleToggleUnavailable}
            />
            }
            {!isSourcePark && isPropertyEditable && (basic?.numberOfUnitsAvailable <= 0 || basic?.lackOfAvailableArea) &&
            <LackOfAvailableArea
              propertyID={basic?.id}
              lackOfAvailableArea={basic?.lackOfAvailableArea}
              buildingAvailableFrom={basic?.buildingAvailableFrom}
              target="industrial"
              directIcon
              modeSwitcher
            />
            }
            {!isSourcePark && isPropertyEditable &&
              <IndustrialWarehouseUnitAdd
                parentID={basic?.id}
                lackOfAvailableArea={basic?.lackOfAvailableArea}
                ordering={ordering}
                btnTrigger={
                <ButtonCustom
                  label={<FormattedMessage id="VOCABULARY.Add unit"/>}
                  color="danger"
                  inactive={basic?.lackOfAvailableArea}
                  solid
                  slim
                />
                }
              />
            }
          </>
          }
          style={{
            marginBottom: "1.6rem"
          }}
        />
        <IsPro
          paywall
          paywallComponent={
            <PaywallBlurContent
              blurImage="media/placeholders/table-office-units-blur-placeholder.png"
              style={{height: "40rem"}}
            >
              <PaywallBannerSimple/>
            </PaywallBlurContent>
          }
          overwriteRule={isPropertyEditable}
        >
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
            intervalCheck={true}
            intervalDelay={1000}
          >
            <TableMUI
              tHead={tHead}
              checkable={!isSourcePark && isPropertyEditable}
              loading={unitsLoading}
              initialOrderBy="updated_at"
              containerStyle={{
                minHeight: "32rem",
                maxHeight: "unset"
              }}
              selected={selected}
              totalItems={tBody?.length}
              initialLimit={5}
              limitBtnLabel={<FormattedMessage id="VOCABULARY.Show all"/>}
              onSort={this.handleSortTable}
              onSelectAll={(checked) => this.handleSelectAll(tBody, checked)}
              noData={!tBody || tBody?.length <= 0}
              noDataMessage={
                !isSourcePark && isPropertyEditable ?
                  (
                    showUnavailable ?
                      <NoData
                        absolute
                        withIcon
                        style={{paddingTop: "6.4rem"}}
                      /> :
                      <NoData
                        absolute
                        withIcon
                        style={{paddingTop: "6.4rem"}}
                        message={<FormattedMessage id="There are currently no modules available in the building."/>}
                        extraMessage={
                        basic?.lackOfAvailableArea &&
                          <>
                            <FormattedMessage id="To add a new unit, you must first uncheck - "/>
                            <strong><FormattedMessage id="Lack of available area."/></strong>
                          </>
                        }
                        action={
                          isPropertyEditable &&
                          <IndustrialWarehouseUnitAdd
                            parentID={basic?.id}
                            lackOfAvailableArea={basic?.lackOfAvailableArea}
                            ordering={ordering}
                            btnTrigger={
                            <ButtonCustom
                              label={<FormattedMessage id="VOCABULARY.Add unit"/>}
                              color="danger"
                              inactive={basic?.lackOfAvailableArea}
                              solid
                              slim
                            />
                            }
                          />
                        }
                      />
                  ) :
                  <NoData
                    message={<FormattedMessage id="VOCABULARY.Lack of available units"/>}
                    absolute
                    withIcon
                    style={{paddingTop: "6.4rem"}}
                  />
              }
            >
              {tBody?.length > 0 && tBody?.map((unit, index) => {
                return(
                  <TableRow key={index} className={unit?.isDeleted ? styles.rowIsDeleted : undefined}>
                    {!isSourcePark && isPropertyEditable &&
                    <TableCell style={{width: "3.5rem", paddingRight: "0"}}>
                      <Checkbox
                        value={unit?.id}
                        checked={this.isSelected(unit?.id)}
                        inputProps={{"aria-labelledby": unit?.id}}
                        onChange={this.handleSelectRow}
                      />
                    </TableCell>
                    }
                    <TableCell align={handleTableCellAlign(tHead, 0)}>
                      <UnitTypePreview
                        industrialType={unit?.isOffice ? "office" : "warehouse"}
                        simple
                      />
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 1)}>
                      <UnitAreaPreview
                        data={unit}
                        parentData={basic}
                        isOfficeEditable={isPropertyEditable}
                        style={{fontWeight: 600}}
                        flexibleUnitForm={
                        <EditModal
                          target="warehouseUnit"
                          parentData={unit}
                          triggerDots
                          isPropertyEditable={isPropertyEditable}
                          initialTab="flexibleUnits"
                          extraData={{
                            ordering,
                            warehouseID: basic?.id
                          }}
                          triggerCustom={
                            <ModalTrigger
                              icon="tab_unselected"
                              label={<FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />}
                              directIcon
                              color={unit?.canBeDivided ? "danger" : "gray"}
                            />
                          }
                        />
                        }
                        blurEnable
                      />
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 2)}>
                      <UnitFloorPreview floor={unit?.docks} />
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 3)}>
                      <UnitAvailabilityPreview
                        date={unit?.availableFrom}
                        isReserved={unit?.status === UNIT_STATUS.RESERVED}
                        isUnavailable={unit?.status === UNIT_STATUS.UNAVAILABLE}
                        availableInMonths={unit?.availableFromAdditionalTime}
                        parentStatus={basic?.status}
                        style={{justifyContent: "flex-start"}}
                      />
                    </TableCell>
                    {isSourcePark &&
                    <TableCell align={handleTableCellAlign(tHead, 4)}>
                      <PropertyCardPreview
                        propertyID={unit?.warehouseId}
                        title={unit?.warehouseName}
                        thumb="disabled"
                        route={ROUTES.INDUSTRIAL_BUILDING}
                      />
                    </TableCell>
                    }
                    <TableCell align={handleTableCellAlign(tHead, isSourcePark ? 5 : 4)}>
                      <UpdatedAtPreview level={unit?.updateLevel} date={unit?.updatedAt} alternative />
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, isSourcePark ? 6: 5)}>
                      <EditModal
                        target="warehouseUnit"
                        parentData={unit}
                        triggerDots
                        isPropertyEditable={isPropertyEditable}
                        initialTab={isPropertyEditable ? "basic" : "note"}
                        extraData={{
                          ordering,
                          ...isSourcePark ? {parkID: basic?.id} : {warehouseID: basic?.id},
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableMUI>
          </ReactVisibilitySensor>
        </IsPro>
      </>
    );
  }
}