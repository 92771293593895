import React from "react";
import ModalCustom from "../../../../partials/components/ModalCustom";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {addLeadProperties, fetchLeadsPreview} from "../../../../crud/leadsManagement.crud";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import _ from "lodash";

class LeadsManagementAddProperty extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      leadID: null,
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleChange = (event) => {
    this.setState({
      leadID: event.target.value,
    });
  };
  handleSave = () => {
    const {addLeadProperties, propertyID, propertyData, isUnitView, isOfficeUnitView, isIndustrialBuildingsView, currentApplicationView} = this.props;
    const {leadID} = this.state;

    if (leadID && propertyID && addLeadProperties) {
      const properties = Array.isArray(propertyID) ? propertyID : [propertyID];
      let unitParentsIDS = [];
      let industrialBuildingsParentsIDS = [];
      let finalProperties = properties;
      // Handle unit select
      if(isUnitView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => properties?.includes(item?.id));
        selectedUnitsData.map(unitData => unitParentsIDS.push(unitData?.office?.id));

        if(!_.isEmpty(unitParentsIDS)) {
          finalProperties = _.uniq(unitParentsIDS);
        }
      }
      if(isOfficeUnitView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => properties?.includes(item?.id));
        selectedUnitsData.map(unitData => unitParentsIDS.push(unitData?.office));

        if(!_.isEmpty(unitParentsIDS)) {
          finalProperties = _.uniq(unitParentsIDS);
        }
      }
      else if(isIndustrialBuildingsView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => properties?.includes(item?.id));
        selectedUnitsData.map(unitData => industrialBuildingsParentsIDS.push(unitData?.park));

        if(!_.isEmpty(industrialBuildingsParentsIDS)) {
          finalProperties = _.uniq(industrialBuildingsParentsIDS);
        }
      }
      // Create data to send
      const data = {
        ...currentApplicationView === APPLICATION_VIEWS.OFFICES ? {"offices": finalProperties} : {},
        ...currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL ? {"parks": finalProperties} : {}
      };
      addLeadProperties(leadID, data);
    }
    this.handleCloseModal();
  };

  render() {
    const {loadingLeads, leads, fetchLeadsPreview, btnListMode, btnMode, tooltip} = this.props;
    const {open, leadID} = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        btnMode ?
        <ModalTrigger
            color="admin"
            label={<FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/>}
            icon="forward_to_inbox"
            directBtnTooltip={tooltip}
            directBtn
            solid
          /> :
        <ModalTrigger
            label={<FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/>}
            color="admin"
            icon="forward_to_inbox"
            directIcon={!btnListMode}
            tooltip={tooltip}
            tooltipPosition="right"
          />
        }
        btnConfirmDisabled={!leadID}
        title={<FormattedMessage id="LM.PHRASE.ADD_PROPERTY_TO_LEAD"/>}
        maxWidth="sm"
        fetch={fetchLeadsPreview}
        handleSave={this.handleSave}
      >
        {loadingLeads ? <LoadingScreen/> :
          <FormControl>
            <InputLabel htmlFor="new_user_market">
              <FormattedMessage id="LM.PHRASE.SELECT_LEAD"/>
            </InputLabel>
            <Select
              open={open}
              onClose={this.handleToggleSelect}
              onOpen={this.handleToggleSelect}
              value={leadID}
              onChange={this.handleChange}
              inputProps={{
                name: "lead",
                id: "lead_assign",
              }}
            >
              {leads?.map((lead, index) => {
                const areaRange = [
                  ...lead?.crmAreaFrom ? [lead?.crmAreaFrom] : [],
                  ...lead?.crmAreaTo ? [lead?.crmAreaTo] : []
                ].join("-");
                const location = [
                  ...lead?.market ? [lead?.market] : [],
                  ...lead?.region ? [lead?.region] : []
                ].join(", ");
                const combinedLabel = [
                  ...lead?.crmEmail ? [lead?.crmEmail] : [],
                  areaRange,
                  location
                ].join(" | ");
                const label = lead?.leadName ? lead?.leadName : combinedLabel;

                return (
                  <MenuItem key={index} value={lead?.id}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({leadsManagement, user}) => ({
  leads: leadsManagement?.leadsPreview,
  loadingLeads: leadsManagement?.loadingLeadsPreview,
  adding: leadsManagement?.adding,
  currentApplicationView: user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchLeadsPreview: () => fetchLeadsPreview(),
  addLeadProperties: (leadID, data) => addLeadProperties(leadID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementAddProperty);