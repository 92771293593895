import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import tableCustomStyles from "app/partials/components/TableCustom/index.module.scss";
import {toNumberFormat, toTimeFromDate} from "../../../../../utils/helpers";
import {ReactComponent as IconArrows} from "app/media/icons/arrows_change.svg";
import cn from "classnames";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import NoData from "../../../../../partials/components/NoData/NoData";
import {UNIT_TYPES} from "../../../../../constants";
import {FormattedMessage} from "react-intl";
import BetaLabel from "../../../../../partials/components/BetaLabel";
import HighlightLabel from "../../../../../partials/components/HighlightLabel";
import IsPro from "../../../../../utils/IsPro";
import PaywallBlurContent from "../../../../../partials/components/PayWall/components/PaywallBlurContent";
import PaywallBannerSimple from "../../../../../partials/components/PayWall/banners/PaywallBannerSimple";
import ButtonSimple from "../../../../../partials/components/ButtonSimple";

export default class PropertyChangesTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      limitActive: props?.initialLimit || false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchOfficeChanges, changes, changesLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !changesLoading && _.isEmpty(changes) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchOfficeChanges(id));
    }
  }
  handleLimitToggle = () => {
    this.setState({
      limitActive: !this.state.limitActive
    });
  }

  render() {
    const {
      changes,
      changesLoading,
      basic,
      initialLimit,
      limitBtnLabel
    } = this.props;
    const {limitActive} = this.state;

    return(
      <div className={styles.changesContainer}>
        <PropertySectionTitle
          title={<FormattedMessage id="VOCABULARY.Leasing activity"/>}
        />
        <IsPro
          paywall
          paywallComponent={
            <PaywallBlurContent
              blurImage="media/placeholders/table-office-transactions-blur-placeholder.png"
              style={{height: "100%"}}
            >
              <PaywallBannerSimple/>
            </PaywallBlurContent>
          }
          overwriteRule={basic?.isEditable}
        >
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
          >
            <div className={styles.changesTrackerWrapper}>
              {
                changesLoading ? <LoadingScreen/> :
                  changes && changes?.length > 0 ?
                  <table>
                    <thead>
                      <tr>
                        <th><FormattedMessage id="VOCABULARY.Area"/></th>
                        <th><FormattedMessage id="VOCABULARY.Floor"/></th>
                        <th><FormattedMessage id="VOCABULARY.Status"/></th>
                        <th><FormattedMessage id="VOCABULARY.Date"/></th>
                      </tr>
                    </thead>
                    <tbody>
                      {changes?.map((item, index) => {
                        return(
                        <tr
                          key={index}
                          style={{
                            ...limitActive && index > initialLimit ? {height: 0, overflow: "hidden", display: "table-column"} : {}
                          }}
                        >
                          <td>
                            <span className={styles.cellWrapper}>
                              <IconArrows/>
                              <span className={styles.typeValue}>
                                {
                                  item?.unitType === UNIT_TYPES.OFFICE ? <FormattedMessage id="GENERAL.PHRASE.OFFICE"/> :
                                    item?.unitType === UNIT_TYPES.RETAIL ? <FormattedMessage id="GENERAL.PHRASE.RETAIL"/> :
                                      item?.unitType === UNIT_TYPES.OFFICE_RETAIL ? <FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/> : ""
                                }
                              </span>
                              <span className={styles.value}>{toNumberFormat(item?.area, 'area')}</span>
                              {item?.isSublease &&
                              <BetaLabel posRelative style={{marginLeft: "5px"}}>
                                <FormattedMessage id="UNITS.PHRASE.SUBLEASE"/>
                              </BetaLabel>
                              }
                            </span>
                          </td>
                          <td>
                            <span className={styles.cellWrapper}>
                              {item?.floor}
                            </span>
                          </td>
                          <td>
                            <HighlightLabel
                              color={item?.status === "ADDED" ? "success" : "warning"}
                              style={{fontSize: "1.2rem"}}
                            ><FormattedMessage id={`${item?.status}`}/></HighlightLabel>
                          </td>
                          <td>
                            <span className={styles.cellWrapper}>
                              <span className={cn(styles.value, styles.timeValue)}>{toTimeFromDate(item?.date)}</span>
                            </span>
                          </td>
                        </tr>
                        );
                      })}
                    </tbody>
                  </table>
                   :
                  <NoData withIcon/>
              }
              {initialLimit && changes?.length > initialLimit && limitActive &&
                <div className={tableCustomStyles.tableFooter}>
                  <ButtonSimple
                    label={limitBtnLabel}
                    slim
                    onClick={() => this.handleLimitToggle()}
                  />
                </div>
              }
            </div>
          </ReactVisibilitySensor>
        </IsPro>
      </div>
    );
  }
}