import React, {useEffect, useState} from "react";
import IsPro from "../../../../utils/IsPro";
import PaywallBannerRowSimple from "../../../../partials/components/PayWall/banners/PaywallBannerRowSimple";
import _ from "lodash";
import PropertyHeaderContacts from "./PropertyHeaderContacts";
import {
  fetchOfficeLeasingContacts,
  fetchOfficeOwner,
} from "../../../../crud/estates/offices/officeBuilding.crud";
import {callReddClickEvent} from "../../../../crud/user.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import styles from "../../../offices/PropertyPage/components/content/index.module.scss";
import cn from "classnames";
import {fetchIndustrialParkOwner, fetchIndustrialParkLeasingContacts} from "../../../../crud/estates/industrial/industrialPark.crud";
import IsApplicationView from "../../../../utils/IsApplicationView";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";

function PropertyContact({...props}) {
  const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
  const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
  const [ownerLoaded, setOwnerLoaded] = useState(false);
  const [contactLoaded, setContactLoaded] = useState(false);
  const basic = isOfficeView ? props?.officeBasic : isIndustrialView ? props?.parkBasic : null;
  const owner = isOfficeView ? props?.officeOwner : isIndustrialView ? props?.parkOwner : null;
  const contacts = isOfficeView ? props?.officeLeasingContacts : isIndustrialView ? props?.parkLeasingContacts : null;
  const basicLoading = isOfficeView ? props?.officeBasicLoading : isIndustrialView ? props?.parkBasicLoading : null;
  const ownerLoading = isOfficeView ? props?.officeOwnerLoading : isIndustrialView ? props?.parkOwnerLoading : false;
  const contactLoading = isOfficeView ? props?.officeLeasingContactsLoading : isIndustrialView ? props?.parkLeasingContactsLoading : false;

  // Fetch Data
  useEffect(() => {
    const propertyID = basic?.id;
    const fetchOwnerData = isOfficeView ? props?.fetchOfficeOwner : isIndustrialView ? props?.fetchIndustrialParkOwner : null;
    const fetchContactData = isOfficeView ? props?.fetchOfficeLeasingContacts : isIndustrialView ? props?.fetchIndustrialParkLeasingContacts : null;
    // Fetch Owner Data
    if(propertyID && !ownerLoaded && !ownerLoading && fetchOwnerData) {
      setOwnerLoaded(true);
      fetchOwnerData(propertyID);
    }
    // Fetch Leasing Contact Data
    if(propertyID && !contactLoaded && !contactLoading && fetchContactData) {
      setContactLoaded(true);
      fetchContactData(propertyID);
    }
  }, [props, basic, ownerLoaded, contactLoaded, ownerLoading, contactLoading, isOfficeView, isIndustrialView]);
  // Variables
  const isEditable = basic?.isEditable;
  const loading = basicLoading || ownerLoading || contactLoading;

  return(
    <div className={cn(
      styles.propertyContactContainer,
      props?.oneLine ? styles.oneLineHeight : undefined,
      props?.semiBreak ? styles.semiBreak : undefined
    )}>
      {loading ? <LoadingScreen/> :
      <>
      <IsPro reverseCheck overwriteRule={isEditable}>
        <PaywallBannerRowSimple style={{marginBottom: "1.6rem"}}/>
      </IsPro>
      {(!_.isEmpty(owner) || !_.isEmpty(contacts)) &&
      <PropertyHeaderContacts
        propertyID={basic?.id}
        owner={owner}
        contacts={contacts}
        isEditable={isEditable}
        oneLine={props?.oneLine}
        logoBreak={props?.logoBreak}
      />
      }
      </>
      }
    </div>
  );
}

const mapStateToProps = (store) => ({
  officeBasic: store.officeBuilding.basic,
  officeBasicLoading: store.officeBuilding.basicLoading,
  officeOwner: store.officeBuilding.owner,
  officeOwnerLoading: store.officeBuilding.ownerLoading,
  officeLeasingContacts: store.officeBuilding.leasingContacts,
  officeLeasingContactsLoading: store.officeBuilding.leasingContactsLoading,
  parkBasic: store.industrialPark.basic,
  parkBasicLoading: store.industrialPark.basicLoading,
  parkOwner: store.industrialPark.owner,
  parkOwnerLoading: store.industrialPark.ownerLoading,
  parkLeasingContacts: store.industrialPark.leasingContacts,
  parkLeasingContactsLoading: store.industrialPark.leasingContactsLoading,
});
const mapDispatchToProps = {
  fetchOfficeOwner: (officeID) => fetchOfficeOwner(officeID),
  fetchOfficeLeasingContacts: (officeID) => fetchOfficeLeasingContacts(officeID),
  fetchIndustrialParkOwner: (parkID) => fetchIndustrialParkOwner(parkID),
  fetchIndustrialParkLeasingContacts: (parkID) => fetchIndustrialParkLeasingContacts(parkID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyContact)
);
