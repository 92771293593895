import React from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import ReactVisibilitySensor from "react-visibility-sensor";
import {fetchOfficeMarketComparison} from "app/crud/estates/offices/officeBuilding.crud";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import {Typography} from "@material-ui/core";
import {areaFormat, toNumberFormat} from "../../../../../utils/helpers";
import TooltipCustom from "../../../../../partials/components/TooltipCustom";
import PropertyComparisonXlsDownload from "./PropertyComparisonXlsDownload";
import BlurText from "../../../../../partials/components/BlurText";
import PaywallBannerRowTable from "../../../../../partials/components/PayWall/banners/PaywallBannerRowTable";

class PropertyMarketComparison extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      activeMonthsRange: 6
    }
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficeMarketComparison,
      marketComparisonLoading
    } = this.props;
    const {initLoaded} = this.state;
    const officeID = this.props.match?.params?.id;

    if(isVisible && !marketComparisonLoading && !initLoaded && officeID) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficeMarketComparison(officeID));
    }
  };
  handleMonthsRangeChange = (value) => {
    this.setState({
      activeMonthsRange: value
    });
  }
  handleCellValue = (value, index, key, blurDisabled) => {
    const mandatoryValues = [
      "transactions_6m",
      "transactions_12m",
      "transactions_24m",
      "supply_boost_6m",
      "supply_boost_12m",
      "supply_boost_24m",
      "marketplace_leads_6m",
      "marketplace_leads_12m",
      "marketplace_leads_24m",
      "marketplace_views_6m",
      "marketplace_views_12m",
      "marketplace_views_24m",
      "marketplace_agent_interest_6m",
      "marketplace_agent_interest_12m",
      "marketplace_agent_interest_24m"
    ];
    const isMandatoryValue = mandatoryValues.includes(key);
    const finalValue = value ? value : (isMandatoryValue ? 0 : value);
    const cellToFixed = [1, 0, 1];
    const toFixedValue = isNaN(cellToFixed[index]) ? 0 : cellToFixed[index];
    const fixedValue = finalValue ? Number(finalValue).toFixed(toFixedValue) : finalValue;
    const isBlurred = false;

    return(
      <BlurText
        isBlurred={
          !blurDisabled &&
          isBlurred
        }
        customBlurValue={123}
      >
        {toNumberFormat(fixedValue)}
      </BlurText>
    );
  }
  handleCellSuffixValue = (key, index) => {
    const cellNumberType = ["%", "dni", "€", areaFormat(), areaFormat(), areaFormat(), areaFormat(), areaFormat(), areaFormat(), areaFormat(), areaFormat()];

    if(key === "time_to_lease") {
      return <FormattedMessage id="days"/>;
    }
    return cellNumberType?.[index];
  }
  handleCellKeyTooltip = (key) => {
    if(key === "time_to_lease" || key === "area_available_future" || key.startsWith("marketplace_agent_interest")) {
      return(
        <TooltipCustom
          title={<FormattedMessage id={`VOCABULARY.${key}_tooltip`}/>}
          placement="right"
        >
          <FormattedMessage id={`${key}`}/>
        </TooltipCustom>
      );
    }
    return <FormattedMessage id={`${key}`} values={{finne: <span style={{fontWeight: 700, color: "#00A6ED"}}>finne</span>}}/>;
  }

  render() {
    const {marketComparisonLoading, marketComparison, basic} = this.props;
    const {activeMonthsRange} = this.state;
    const isBasicUser = false;

    return(
      <div className={styles.propertyMarketComparisonContainer}>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            title={<FormattedMessage id="VOCABULARY.Market comparison"/>}
            toolbar={!marketComparisonLoading &&
              <PropertyComparisonXlsDownload
                reddClick="office-building-market-comparison-xls-download"
              />
            }
          />
        </ReactVisibilitySensor>
        {marketComparisonLoading ? <LoadingScreen/> :
          _.isEmpty(marketComparison) ? <NoData withIcon/> :
            <table className={styles.propertyMarketComparisonTable}>
              <thead>
                <tr>
                  <th/>
                  <th colSpan={2}><Typography variant="body1"><FormattedMessage id="VOCABULARY.Active building"/></Typography></th>
                  <th colSpan={2}><Typography variant="body1"><FormattedMessage id="VOCABULARY.TOP10 competitors"/></Typography></th>
                  <th colSpan={2}><Typography variant="body1">{basic?.addressCity} - <FormattedMessage id="VOCABULARY.Class"/> {basic?.buildingClass}</Typography></th>
                  <th colSpan={2}><Typography variant="body1">{basic?.addressCity}</Typography></th>
                </tr>
              </thead>
              <tbody>
              {marketComparison?.map((item, index) => {
                const keyMonthsRange = item?.key?.split("_")?.at(-1);
                const keyMonthsContainsNumber = /\d/.test(keyMonthsRange);
                const targetTransaction = item?.key === "transactions_6m" || item?.key === "transactions_12m" || item?.key === "transactions_24m";
                const activeKeyMonths = keyMonthsContainsNumber ? keyMonthsRange.includes(`${activeMonthsRange}`) : true;

                if(!basic?.isEditable && item?.key?.startsWith("marketplace_")) {
                  return null;
                }
                return(
                  <React.Fragment key={index}>
                    <tr
                      className={activeKeyMonths ? undefined : styles.hideRow}
                    >
                      <td>
                        {this.handleCellKeyTooltip(item?.key)}
                        {targetTransaction &&
                        <span className={styles.monthsChangeWrapper}>
                          <label><FormattedMessage id="VOCABULARY.Demand and supply"/>:</label>
                          <button
                            className={activeMonthsRange === 6 ? styles.active : undefined}
                            onClick={() => this.handleMonthsRangeChange(6)}
                          >6M</button>
                          <span>&#8226;</span>
                          <button
                            className={activeMonthsRange === 12 ? styles.active : undefined}
                            onClick={() => this.handleMonthsRangeChange(12)}
                          >12M</button>
                          <span>&#8226;</span>
                          <button
                            className={activeMonthsRange === 24 ? styles.active : undefined}
                            onClick={() => this.handleMonthsRangeChange(24)}
                          >24M</button>
                        </span>
                        }
                      </td>
                      <td>{this.handleCellValue(item?.obj, index, item?.key)}</td>
                      <td className={styles.cellPlaceholder}>{this.handleCellSuffixValue(item?.key, index)}</td>
                      <td>{this.handleCellValue(item?.competitors, index, item?.key)}</td>
                      <td className={styles.cellPlaceholder}>{this.handleCellSuffixValue(item?.key, index)}</td>
                      <td>{this.handleCellValue(item?.marketWithClass, index, item?.key)}</td>
                      <td className={styles.cellPlaceholder}>{this.handleCellSuffixValue(item?.key, index)}</td>
                      <td>{this.handleCellValue(item?.market, index, item?.key)}</td>
                      <td className={styles.cellPlaceholder}>{this.handleCellSuffixValue(item?.key, index)}</td>
                    </tr>
                    {isBasicUser && index === 4 &&
                    <tr className={styles.bannerRow}>
                      <td colSpan={100}>
                        <PaywallBannerRowTable bannerVariant="row_table-property-competition-compare"/>
                      </td>
                    </tr>
                    }
                  </React.Fragment>
                );
              })}
              </tbody>
            </table>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basic: state.officeBuilding.basic,
  marketComparison: state.officeBuilding.marketComparison,
  marketComparisonLoading: state.officeBuilding.marketComparisonLoading,
});
const mapDispatchToProps = {
  fetchOfficeMarketComparison: (officeID) => fetchOfficeMarketComparison(officeID)
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertyMarketComparison)