import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import SingleSelect from "../../../../partials/components/SingleSelect";
import {FILTERS_SELECTS} from "../../../../partials/components/FiltersDrawer/constants";
import {callReddClickEvent} from "app/crud/user.crud";
import {changeLeadStatus} from "../../../../crud/finneLeads.crud";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import cn from "classnames";
import styles from "./index.module.scss";

class ManageLeadStatus extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      status: props?.status,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.status !== this.props.status && this.props.status !== this.state.status) {
      this.setState({
        status: this.props.status
      })
    }
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleRenderLabel = () => {
    const activeIndex = FILTERS_SELECTS.BIURA_INFO_LEADS_STATUSES.findIndex(
      (item) => item?.id === this.state.status
    );
    return FILTERS_SELECTS.BIURA_INFO_LEADS_STATUSES?.[activeIndex]?.name;
  };
  handleLabelStatus = () => {
    const activeIndex = FILTERS_SELECTS.BIURA_INFO_LEADS_STATUSES.findIndex(
      (item) => item?.id === this.state.status
    );
    return FILTERS_SELECTS.BIURA_INFO_LEADS_STATUSES?.[activeIndex]?.color;
  };
  handleChangeSelect = (selected, target) => {
    this.setState(
      {
        [target]: selected,
      },
      () => this.handleSave()
    );
  };
  handleSave = () => {
    const {
      changeLeadStatus,
      leadID,
      currentApplicationView,
      callReddClickEvent
    } = this.props;
    const { status } = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    if (leadID) {
      changeLeadStatus(leadID, status);
      isOfficeView && callReddClickEvent(
        "marketplace-leads-status-update",
        "lead",
        "marketplace",
        leadID,
        {status}
      );
      isIndustrialView && callReddClickEvent(
        "marketplace-leads-industrial-status-update",
        "lead",
        "marketplace",
        leadID,
        {status}
      );
    }
  };

  render() {
    const {contentBody} = this.props;
    const { status } = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <button
          className={cn(
            styles.btnChangeStatus,
            styles[`variant_${this.handleLabelStatus()}`]
          )}
        >
          {this.handleRenderLabel()}
        </button>
        }
        title={<FormattedMessage id="BI.PHRASE.MANAGE_LEAD_STATUS" />}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE" />}
        defaultThemeProvider={contentBody}
        maxWidth={contentBody && "xs"}
      >
        {contentBody ? contentBody :
        <SingleSelect
          label={<FormattedMessage id="BI.PHRASE.LEAD_STATUS" />}
          inputName="status"
          options={FILTERS_SELECTS.BIURA_INFO_LEADS_STATUSES}
          optionsValue="id"
          optionsLabel="name"
          defaultSelect={status}
          onSelectedValues={this.handleChangeSelect}
          required
        />
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  currentApplicationView: state.user.data.currentApplicationView,
});
const mapDispatchToProps = {
  changeLeadStatus: (leadID, status) => changeLeadStatus(leadID, status),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManageLeadStatus)
);
