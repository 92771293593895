import React from "react";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import TableMUI from "../../../../../partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import {handleEmptyValue, handleTableCellAlign, toNumberFormat} from "../../../../../utils/helpers";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import NoData from "../../../../../partials/components/NoData/NoData";
import IndustrialParkWarehouseAdd
  from "../../../../../partials/components/TableProperties/TableActions/TableActionsIndustrial/components/IndustrialParkWarehouseAdd";
import {ROUTES} from "../../../../../constants";
import PropertyCardPreview from "../../../../../partials/components/_DataParts/PropertyCardPreview";
import PropertyStatusPreview from "../../../../../partials/components/_DataParts/PropertyStatusPreview";
import PropertyAvailabilityPreview from "../../../../../partials/components/_DataParts/PropertyAvailabilityPreview";
import ButtonCustom from "../../../../../partials/components/ButtonCustom";
import IsPro from "../../../../../utils/IsPro";
import BlurText from "../../../../../partials/components/BlurText";
import HighlightLabel from "../../../../../partials/components/HighlightLabel";

export default class PropertyBuildings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      ordering: ''
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkBuildings, buildings, buildingsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    setTimeout(() => {
      if(isVisible && !initLoaded && !buildingsLoading && _.isEmpty(buildings) && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialParkBuildings(id));
      }
    }, 200);
  }
  handleSortTable = (orderBy, order) => {
    const {fetchIndustrialParkBuildings, buildingsLoading, match} = this.props;
    const {id} = match?.params;

    if(orderBy && !buildingsLoading) {
      const ordering = [
        ...order ? [`${order}`] : [],
        ...orderBy ? [`${orderBy}`] : []
      ].join("");

      this.setState({
        ordering
      }, () => fetchIndustrialParkBuildings(id, ordering));
    }
  }

  render() {
    const {parentRef, basic, buildings, buildingsLoading} = this.props;
    const {ordering} = this.state;
    const tHead = [
        {id: 'building_name', numeric: false, disablePadding: false, label: 'VOCABULARY.Building', sort: false},
        {id: 'status', numeric: false, disablePadding: false, label: 'VOCABULARY.Building status', sort: false},
        {id: 'availability', numeric: false, disablePadding: false, label: 'VOCABULARY.Availability', sort: false},
        {id: 'warehouse_area', numeric: true, disablePadding: false, label: 'VOCABULARY.Warehouse area', sort: false},
        {id: 'available_area', numeric: true, disablePadding: false, label: 'VOCABULARY.Available area', sort: false},
        {id: 'available_units', numeric: true, disablePadding: false, label: 'VOCABULARY.Available units', sort: false}
    ];
    const tBody = buildings && buildings?.length > 0 ? buildings : null;
    const isPropertyEditable = basic?.isEditable;

    return(
      <>
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
        intervalCheck={true}
        intervalDelay={1000}
      >
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.Buildings"/>}
          toolbar={
          <>
            {basic?.isEditable &&
            <IndustrialParkWarehouseAdd
              parkID={basic?.id}
              parentData={basic}
              ordering={ordering}
              btnTrigger={
              <ButtonCustom
                label={<FormattedMessage id="VOCABULARY.Add building"/>}
                color="danger"
                solid
                slim
              />
              }
            />
            }
          </>
          }
          style={{
            marginBottom: "1.6rem"
          }}
        />
      </ReactVisibilitySensor>
      <TableMUI
        tHead={tHead}
        loading={buildingsLoading}
        initialOrderBy="available_units"
        containerStyle={{
          minHeight: "20rem",
          maxHeight: "unset"
        }}
        totalItems={tBody?.length}
        initialLimit={5}
        limitBtnLabel={<FormattedMessage id="VOCABULARY.Show all"/>}
        onSort={this.handleSortTable}
        noData={!tBody || tBody?.length <= 0}
        noDataMessage={
          isPropertyEditable ?
            <NoData
              absolute
              withIcon
              style={{paddingTop: "6.4rem"}}
              message={<FormattedMessage id="VOCABULARY.No buildings"/>}
              action={
                basic?.isEditable &&
                <IndustrialParkWarehouseAdd
                  parkID={basic?.id}
                  parentData={basic}
                  ordering={ordering}
                  btnTrigger={
                  <ButtonCustom
                    label={<FormattedMessage id="VOCABULARY.Add building"/>}
                    color="danger"
                    solid
                  />
                  }
                />
              }
            /> :
            <NoData
              message={<FormattedMessage id="VOCABULARY.No buildings"/>}
              absolute
              withIcon
              style={{paddingTop: "6.4rem"}}
            />
        }
      >
        {tBody?.length > 0 && tBody?.map((building, index) => {
          return(
            <TableRow key={index}>
              <TableCell align={handleTableCellAlign(tHead, 0)}>
                <PropertyCardPreview
                  propertyID={building?.id}
                  title={building?.name}
                  thumb="disabled"
                  route={ROUTES.INDUSTRIAL_BUILDING}
                />
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 1)}>
                <PropertyStatusPreview status={building?.status} textWithIcon/>
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 2)}>
                <PropertyAvailabilityPreview
                  date={building?.buildingAvailableFrom}
                  availableInMonths={building?.availableFromAdditionalTime}
                  isUnavailable={!building?.numberOfUnitsAvailable || building?.numberOfUnitsAvailable <= 0}
                  parentStatus={building?.status}
                />
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 3)}>
                <HighlightLabel color="neutral2">
                  <BlurText
                    type="area"
                    isBlurred={
                      IsPro({
                        reverseCheck: true,
                        overwriteRule: basic?.isEditable
                      })
                    }
                  >
                    {toNumberFormat(building?.totalWarehouseArea, "area")}
                  </BlurText>
                </HighlightLabel>
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 4)}>
                <HighlightLabel color="neutral2">
                  <BlurText
                    type="area"
                    isBlurred={
                      IsPro({
                        reverseCheck: true,
                        overwriteRule: basic?.isEditable
                      })
                    }
                  >
                    {toNumberFormat(building?.totalAreaAvailable, "area")}
                  </BlurText>
                </HighlightLabel>
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 5)}>
                {handleEmptyValue(building?.numberOfUnitsAvailable)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableMUI>
      </>
    );
  }
}