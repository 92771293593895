import React from "react";
import PropertyHeaderTitle from "app/pages/common/components/PropertyPage/PropertyHeaderTitle";
import {FormattedMessage, injectIntl} from "react-intl";
import commonStyles from "app/pages/common/components/PropertyPage/index.module.scss";
import styles from "./index.module.scss";
import LoadingScreen from "app/partials/components/LoadingScreen";
import EditModal from "app/partials/components/EditModal";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import LeadsManagementAddProperty from "../../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import IsAdmin from "../../../../../utils/IsAdmin";
import {handleEmptyValue, toNumberFormat, toTimeFromDate} from "../../../../../utils/helpers";
import INDUSTRIAL_BUILDING_TYPE from "../../../../../constants/INDUSTRIAL_BUILDING_TYPE";
import PropertyStatusPreview from "../../../../../partials/components/_DataParts/PropertyStatusPreview";

function BuildingBasicData({basic, basicLoading, ...props}) {
  // Handlers
  const handleValueLoading = (loading, value) => {
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }
  // Variables
  const storageHeightRange = [
    ...basic?.storageHeightMin ? [toNumberFormat(basic?.storageHeightMin)] : [],
    ...basic?.storageHeightMax ? [toNumberFormat(basic?.storageHeightMax)] : []
  ];
  const columnGridRange = [
    ...basic?.columnGridMin ? [basic?.columnGridMin] : [],
    ...basic?.columnGridMax ? [basic?.columnGridMax] : []
  ];
  const flooringCarrierRange = [
    ...basic?.flooringCarrierMin ? [toNumberFormat(basic?.flooringCarrierMin)] : [],
    ...basic?.flooringCarrierMax ? [toNumberFormat(basic?.flooringCarrierMax)] : []
  ];
  const floorCapacityLoading = props?.intl.formatMessage({id: 'kg/sqm'});
  
  return(
    <div className={styles.basicDataWrapper}>
      <div>
        <table>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Type"/>:</td>
              <td>
                {handleValueLoading(
                    basicLoading,
                    basic?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_SBU.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_SBU.name}/> :
                    basic?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BTS.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BTS.name}/> :
                    basic?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BIG_BOX.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BIG_BOX.name}/> :
                    basic?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_CITY_FLEX.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_CITY_FLEX.name}/> :
                    basic?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_OTHER.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_OTHER.name}/> : "-"
                )}
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Status"/>:</td>
              <td>{handleValueLoading(basicLoading, <PropertyStatusPreview status={basic?.status} textOnly/>)}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Completion year"/>:</td>
              <td>{handleValueLoading(basicLoading, handleEmptyValue(basic?.commissioningYear))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Area"/>:</td>
              <td>{handleValueLoading(basicLoading, toNumberFormat(basic?.totalArea, 'area'))}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.featuredTable}>
        <table>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Clear height"/>:</td>
              <td>{handleValueLoading(basicLoading, toNumberFormat(storageHeightRange.join(" - "), 'range', '', ' m'))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Column grid"/>:</td>
              <td>{handleValueLoading(basicLoading, columnGridRange.join(" - "))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="INDUSTRIAL.PHRASE.FLOOR_LOADING_CAPACITY"/>:</td>
              <td>{handleValueLoading(basicLoading, toNumberFormat(flooringCarrierRange.join(" - "), 'range', '', floorCapacityLoading))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="INDUSTRIAL.PHRASE.PARKING_TRUCK_SLOTS"/>:</td>
              <td>{handleValueLoading(basicLoading, handleEmptyValue(basic?.parkingSlotsTruck))}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.featuredTable}>
        <table>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Standard parking spaces"/>:</td>
              <td>{handleValueLoading(basicLoading, handleEmptyValue(basic?.parkingSlotsCar))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Loading docks"/>:</td>
              <td>{handleValueLoading(basicLoading, handleEmptyValue(basic?.docks))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
class PropertyHeaderPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  render() {
    const {
      basic,
      basicLoading
    } = this.props;
    const isParkEditable = basic?.isEditable;
    const buildingName = basic?.name ? basic?.name : "";

    return(
      <div
        className={styles.propertyHeaderContainer}
      >
        {
          !basicLoading && basic ?
            <>
              <PropertyHeaderTitle
                title={buildingName}
                titleActions={
                <>
                  {(basic?.isEditable || basic?.ipiEditable) &&
                  <EditModal
                    target="warehouse"
                    parentData={basic}
                    isPropertyEditable={isParkEditable}
                    {...this.props}
                  />}
                  <IsAdmin>
                    <LeadsManagementAddProperty propertyID={basic?.parkId}/>
                  </IsAdmin>
                </>
                }
              />
              <BuildingBasicData {...this.props}/>
              <p className={commonStyles.propertyLastUpdate}>
                <FormattedMessage id="VOCABULARY.Data update"/>{" "}
                <span className={commonStyles.time}>{toTimeFromDate(basic?.updatedAtByUser)}</span>
              </p>
            </> :
            <LoadingScreen/>
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PropertyHeaderPreview)
);