// PARKS
export const fetchIndustrialParks = (params) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_REQUEST',
  payload: {
    params
  },
});
export const fetchIndustrialPark = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkWarehouses = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_WAREHOUSES_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkUnits = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_UNITS_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkPhotos = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_PHOTOS_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkManageOwners = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParksSimpleList = (params, owned) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_REQUEST',
  payload: {
    params,
    owned
  }
});
export const fetchIndustrialParksResetList = (initOrderBy, isEditable) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_RESET_REQUEST',
  payload: {
    initOrderBy,
    isEditable
  }
});
export const fetchIndustrialParkFiles = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_PROPERTY_FILES_REQUEST',
  payload: {
    parkID
  },
});
export const getIndustrialParksViewType = () => ({
  type: 'GET_INDUSTRIAL_PARKS_VIEW_TYPE_REQUEST',
});
export const fetchIndustrialParksClusters = (params) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_CLUSTERS_REQUEST',
  payload: {
    params
  },
});
export const fetchIndustrialParksClustersReset = () => ({
  type: 'FETCH_INDUSTRIAL_PARKS_CLUSTERS_RESET_REQUEST',
});
export const fetchIndustrialParksLatestLeads = (params) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_REQUEST',
  payload: {
    params
  },
});
export const fetchIndustrialParksVisitingCompanies = (params) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_REQUEST',
  payload: {
    params
  },
});
export const fetchIndustrialParksMinified = (params) => ({
  type: 'FETCH_INDUSTRIAL_PARKS_MINIFIED_REQUEST',
  payload: {
    params
  },
});

// PARKS TRANSACTIONS/SUPPLY
export const fetchIndustrialDashboardTransactions = () => ({
  type: 'FETCH_INDUSTRIAL_DASHBOARD_TRANSACTIONS_REQUEST',
});
export const fetchIndustrialDashboardSupplyBoost = () => ({
  type: 'FETCH_INDUSTRIAL_DASHBOARD_SUPPLY_BOOST_REQUEST',
});
export const fetchIndustrialTransactions = (params) => ({
  type: 'FETCH_INDUSTRIAL_TRANSACTIONS_REQUEST',
  payload: {
    params
  }
});
export const fetchIndustrialSupplyBoost = (params) => ({
  type: 'FETCH_INDUSTRIAL_SUPPLY_BOOST_REQUEST',
  payload: {
    params
  }
});

// PARKS ACTIONS
export const updateIndustrialPark = (parkID, data, backgroundRefresh) => ({
  type: 'UPDATE_INDUSTRIAL_PARK_REQUEST',
  payload: {
    parkID,
    data,
    backgroundRefresh
  },
});
export const addParkPhoto = (parkID, file) => ({
  type: 'ADD_INDUSTRIAL_PARK_PHOTO_REQUEST',
  payload: {
    parkID,
    file
  },
});
export const deleteParkPhoto = (photoId) => ({
  type: 'DELETE_INDUSTRIAL_PARK_PHOTO_REQUEST',
  payload: photoId,
});
export const addIndustrialPark = (data) => ({
  type: 'ADD_INDUSTRIAL_PARK_REQUEST',
  payload: {
    data
  },
});
export const addIndustrialParkOwner = (parkID, data) => ({
  type: 'ADD_INDUSTRIAL_PARK_OWNER_REQUEST',
  payload: {
    parkID,
    data
  },
});
export const removeIndustrialParkOwner = (parkID, data) => ({
  type: 'REMOVE_INDUSTRIAL_PARK_OWNER_REQUEST',
  payload: {
    parkID,
    data
  },
});
export const uploadIndustrialParkFile = (parkID, file, fileDetails) => ({
  type: 'UPLOAD_INDUSTRIAL_PARK_FILE_REQUEST',
  payload: {
    parkID,
    file,
    fileDetails
  },
});
export const downloadIndustrialParkFiles = (parkID, data, fileName) => ({
  type: 'DOWNLOAD_INDUSTRIAL_PARK_FILES_REQUEST',
  payload: {
    parkID,
    data,
    fileName
  },
});
export const deleteIndustrialPark = parkID => ({
  type: 'DELETE_INDUSTRIAL_PARK_REQUEST',
  payload: {
    parkID
  },
});
export const compareSelectedParks = (data, inBackground) => ({
  type: 'COMPARE_SELECTED_PARKS_REQUEST',
  payload: {data, inBackground},
});
export const compareSelectedParksReset = () => ({
  type: 'COMPARE_SELECTED_PARKS_RESET_REQUEST'
});

// WAREHOUSES
export const fetchIndustrialWarehouses = (params) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSES_REQUEST',
  payload: {
    params
  },
});
export const fetchIndustrialWarehouseUnits = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehousePhotos = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseManageOwners = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseAmenities = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehousesResetList = (initOrderBy, isEditable) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSES_RESET_REQUEST',
  payload: {
    initOrderBy,
    isEditable
  }
});
export const fetchIndustrialWarehouseFiles = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_OLD_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehousePortfolio = (ownerID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_OWNERS_PORTFOLIO_REQUEST',
  payload: {
    ownerID
  },
});
export const fetchIndustrialWarehouseFinances = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_FINANCES_REQUEST',
  payload: {
    warehouseID
  },
});
export const getIndustrialWarehousesViewType = () => ({
  type: 'GET_INDUSTRIAL_WAREHOUSES_VIEW_TYPE_REQUEST',
});
export const fetchIndustrialWarehousesClusters = (params) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_REQUEST',
  payload: {
    params
  },
});
export const fetchIndustrialWarehousesClustersReset = () => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_RESET_REQUEST',
});
//
export const fetchIndustrialWarehouseUnitEditFormsData = (unitID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_REQUEST',
  payload: {
    unitID
  }
});
// WAREHOUSES ACTIONS
export const updateIndustrialWarehouse = (warehouseID, data, backgroundRefresh) => ({
  type: 'UPDATE_INDUSTRIAL_WAREHOUSE_REQUEST',
  payload: {
    warehouseID,
    data,
    backgroundRefresh
  },
});
export const addWarehousePhoto = (warehouseID, file) => ({
  type: 'ADD_INDUSTRIAL_WAREHOUSE_PHOTO_REQUEST',
  payload: {
    warehouseID,
    file
  },
});
export const deleteWarehousePhoto = (photoId) => ({
  type: 'DELETE_INDUSTRIAL_WAREHOUSE_PHOTO_REQUEST',
  payload: photoId,
});
export const addIndustrialWarehouse = (data, ordering) => ({
  type: 'ADD_INDUSTRIAL_WAREHOUSE_REQUEST',
  payload: {
    data,
    ordering
  },
});
export const updateIndustrialWarehouseAmenities = (warehouseID, data, backgroundRefresh) => ({
  type: 'UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST',
  payload: {
    warehouseID,
    data,
    backgroundRefresh
  },
});
export const addIndustrialWarehouseOwner = (warehouseID, data) => ({
  type: 'ADD_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST',
  payload: {
    warehouseID,
    data
  },
});
export const removeIndustrialWarehouseOwner = (warehouseID, data) => ({
  type: 'REMOVE_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST',
  payload: {
    warehouseID,
    data
  },
});
export const uploadIndustrialWarehouseFile = (warehouseID, file, fileDetails) => ({
  type: 'UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_REQUEST',
  payload: {
    warehouseID,
    file,
    fileDetails
  },
});
// WAREHOUSE UNITS ACTIONS
export const addIndustrialWarehouseUnit = (data, ordering) => ({
  type: 'ADD_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST',
  payload: {
    data,
    ordering
  },
});
export const removeIndustrialWarehouseUnit = (unitID) => ({
  type: 'REMOVE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST',
  payload: {
    unitID
  },
});
export const updateIndustrialWarehouseUnit = (unitID, data, backgroundRefreshObject) => ({
  type: 'UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST',
  payload: {
    unitID,
    data,
    backgroundRefreshObject
  },
});
export const uploadIndustrialWarehouseUnitSiteplan = (unitID, data, backgroundRefreshObject) => ({
  type: 'UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_REQUEST',
  payload: {
    unitID,
    data,
    backgroundRefreshObject
  },
});
export const refreshAllIndustrialWarehouseUnits = (warehouseID, backgroundRefreshObject) => ({
  type: 'REFRESH_ALL_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
  payload: {
    warehouseID,
    backgroundRefreshObject
  },
});
export const refreshSelectedIndustrialWarehouseUnits = (warehouseID, data, backgroundRefreshObject) => ({
  type: 'REFRESH_SELECTED_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
  payload: {
    warehouseID,
    data,
    backgroundRefreshObject
  },
});
export const refreshSingleIndustrialWarehouseUnit = (unitID) => ({
  type: 'REFRESH_SINGLE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST',
  payload: {
    unitID
  },
});
// IPI
export const fetchIndustrialParkUnitsIPIEditMode = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialWarehouseUnitsIPIEditMode = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_REQUEST',
  payload: {
    warehouseID
  },
});
export const updateIndustrialUnitsIPI = (data) => ({
  type: 'UPDATE_INDUSTRIAL_UNITS_IPI_REQUEST',
  payload: {
    data
  },
});
export const uploadIndustrialParkIPIPlan = (parkID, file, source, sourceID) => ({
  type: 'UPLOAD_INDUSTRIAL_PARK_IPI_PLAN_REQUEST',
  payload: {
    parkID,
    file,
    source,
    sourceID
  },
});
export const uploadIndustrialWarehouseIPIPlan = (warehouseID, file) => ({
  type: 'UPLOAD_INDUSTRIAL_WAREHOUSE_IPI_PLAN_REQUEST',
  payload: {
    warehouseID,
    file
  },
});
// OTHERS
export const fetchIndustrialColumnGridDimensions = () => ({
  type: 'FETCH_INDUSTRIAL_COLUMN_GRID_DIMENSIONS_REQUEST'
});
export const fetchIndustrialAmenities = () => ({
  type: 'FETCH_INDUSTRIAL_AMENITIES_REQUEST'
});
export const fetchIndustrialMarketsCities = (markets) => ({
  type: 'FETCH_INDUSTRIAL_MARKETS_CITIES_REQUEST',
  payload: {
    markets
  }
});
// OTHERS ACTION - UPDATE FILE
export const updateIndustrialFile = (fileID, data, dataSource) => ({
  type: 'UPDATE_INDUSTRIAL_FILE_REQUEST',
  payload: {
    fileID,
    data,
    dataSource
  }
});
// OTHERS ACTION - DELETE FILE
export const deleteIndustrialFile = (fileID, parkID, warehouseID) => ({
  type: 'DELETE_INDUSTRIAL_FILE_REQUEST',
  payload: {
    fileID,
    parkID,
    warehouseID
  }
});

// RESET INDUSTRIAL LOADING STATES
export const resetIndustrialLoadingStates = () => ({
  type: 'RESET_INDUSTRIAL_LOADING_STATES_REQUEST'
});