import React from "react";
import {Portlet, PortletBody, PortletHeader,} from "app/partials/content/Portlet";
import styles from "app/pages/common/UserProfile/index.module.scss";
import {Formik} from "formik";
import {Icon, TextField, Tooltip, Typography} from "@material-ui/core";
import clsx from "clsx";
import {InputUpload} from "app/partials/components/InputUpload";
import {FormattedMessage} from "react-intl";
import {toAbsoluteUrl} from "../../../../../_metronic";
import ConfirmationDialog from "../../../../partials/components/ConfirmationDialog";
import LanguageSelector from "app/partials/layout/LanguageSelector";
import IsAdmin from "../../../../utils/IsAdmin";
import InputSwitcher from "../../../../partials/components/InputSwitcher";

export default class UserEditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: props.planImage,
      file: null,
      companyLogo: props.planImage,
      fileCompanyLogo: null,
      imageRemove: null,
      confirmationOpen: false,
      mailLangCode: props.user.mailLangCode,
      adminMarkup: props?.user?.adminMarkup || false,
      adminScripts: props?.user?.adminEnableScripts || false
    };
  }
  handleSave = (values) => {
    const { onSave, user } = this.props;
    const { mailLangCode, adminMarkup, adminScripts } = this.state;

    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      position: values.position,
      phoneNumber: values.phoneNumber,
      companyName: values.companyName,
      market: user.market,
      mailLangCode,
      adminMarkup,
      adminEnableScripts: adminScripts
    };
    onSave(data, this.state.file, this.state.fileCompanyLogo);
  };
  handleAvatarUpload = (file) => {
    this.setState({
      file,
    });
  };
  handleCompanyLogoUpload = (fileCompanyLogo) => {
    this.setState({
      fileCompanyLogo,
    });
  };
  handleConfirmImageRemove = () => {
    const { onRemoveImage } = this.props;
    this.setState(
      {
        confirmationOpen: false,
      },
      () => onRemoveImage(this.state.imageRemove)
    );
  };
  handleRemoveImage = (target) => {
    this.setState({
      imageRemove: target,
      confirmationOpen: true,
    });
  };
  handleAdminMarkup = event => {
    const isChecked = event?.target?.checked;
    this.setState({
      adminMarkup: isChecked
    })
  }
  handleAdminScripts = event => {
    const isChecked = event?.target?.checked;
    this.setState({
      adminScripts: isChecked
    })
  }

  render() {
    const {
      user: {
        firstName,
        lastName,
        email,
        phoneNumber,
        avatar,
        position,
        companyLogo,
        companyName
      },
      updateLoading,
    } = this.props;
    const {adminMarkup, adminScripts} = this.state;

    return (
      <Portlet fluidHeight>
        <PortletHeader
          title={
            <Typography variant="h4">
              <FormattedMessage id="USER.PHRASE.EDIT_PERSONAL_INFORMATION_TOUPPER" />
            </Typography>
          }
        />
        <PortletBody>
          <div className={styles.filesContainer}>
            <div className={styles.avatarBox}>
              <label className={styles.avatarTitleBox}>
                <span>Avatar</span>
                <img
                  src={toAbsoluteUrl("media/icons/unchecked.png")}
                  alt="Remove"
                  onClick={() => this.handleRemoveImage("avatar")}
                />
              </label>
              <div className={styles.editAvatar}>
                <InputUpload
                  currentFile={avatar}
                  onFileUpload={this.handleAvatarUpload}
                  width="100%"
                  height="100%"
                  noFrame
                  placeholder={<Icon>add_a_photo</Icon>}
                  fileTarget="userAvatar"
                />
              </div>
            </div>
            <div className={styles.avatarBox}>
              <label className={styles.avatarTitleBox}>
                <FormattedMessage id="USER.PHRASE.COMPANY_LOGO" />
                <img
                  src={toAbsoluteUrl("media/icons/unchecked.png")}
                  alt="Remove"
                  onClick={() => this.handleRemoveImage("companyLogo")}
                />
              </label>
              <div className={styles.editAvatar}>
                <InputUpload
                  currentFile={companyLogo}
                  onFileUpload={this.handleCompanyLogoUpload}
                  width="100%"
                  height="100%"
                  noFrame
                  placeholder={<Icon>add_a_photo</Icon>}
                  fileTarget="companyLogo"
                />
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: firstName || "",
              lastName: lastName || "",
              email: email || "",
              phoneNumber: phoneNumber || "",
              avatar: avatar || "",
              position: position || "",
              companyLogo: companyLogo || "",
              companyName: companyName || "",
              adminMarkup,
              adminScripts
            }}
            onSubmit={(values) => this.handleSave(values)}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div className="form-group">
                  <Tooltip
                    placement="top-start"
                    title={
                      <Typography variant="body2">
                        <FormattedMessage
                          id="USER.PHRASE.PERSONAL_DATA_CHANGE_REQUEST_TOOLTIP"
                          values={{ breakingLine: <br /> }}
                        />
                      </Typography>
                    }
                  >
                    <TextField
                      type="text"
                      margin="normal"
                      label={<FormattedMessage id="FORM.FIELD.FIRST_NAME" />}
                      className="kt-width-full"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      disabled
                    />
                  </Tooltip>
                  <Tooltip
                    placement="top-start"
                    title={
                      <Typography variant="body2">
                        <FormattedMessage
                          id="USER.PHRASE.PERSONAL_DATA_CHANGE_REQUEST_TOOLTIP"
                          values={{ breakingLine: <br /> }}
                        />
                      </Typography>
                    }
                  >
                    <TextField
                      type="text"
                      margin="normal"
                      label={<FormattedMessage id="FORM.FIELD.LAST_NAME" />}
                      className="kt-width-full"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      disabled
                    />
                  </Tooltip>
                  <TextField
                    type="text"
                    margin="normal"
                    label={<FormattedMessage id="FORM.FIELD.POSITION" />}
                    className="kt-width-full"
                    name="position"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.position}
                  />
                  <Tooltip
                    placement="top-start"
                    title={
                      <Typography variant="body2">
                        <FormattedMessage
                          id="USER.PHRASE.PERSONAL_DATA_CHANGE_REQUEST_TOOLTIP"
                          values={{ breakingLine: <br /> }}
                        />
                      </Typography>
                    }
                  >
                    <TextField
                      type="text"
                      margin="normal"
                      label={<FormattedMessage id="FORM.FIELD.COMPANY_NAME" />}
                      className="kt-width-full"
                      name="companyName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.companyName}
                      disabled
                    />
                  </Tooltip>
                  <Tooltip
                    placement="top-start"
                    title={
                      <Typography variant="body2">
                        <FormattedMessage
                          id="USER.PHRASE.PERSONAL_DATA_CHANGE_REQUEST_TOOLTIP"
                          values={{ breakingLine: <br /> }}
                        />
                      </Typography>
                    }
                  >
                    <TextField
                      type="tel"
                      margin="normal"
                      label={<FormattedMessage id="FORM.FIELD.PHONE_NUMBER" />}
                      className="kt-width-full"
                      name="phoneNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      disabled
                    />
                  </Tooltip>
                  <Tooltip
                    placement="top-start"
                    title={
                      <Typography variant="body2">
                        <FormattedMessage
                          id="USER.PHRASE.PERSONAL_DATA_CHANGE_REQUEST_TOOLTIP"
                          values={{ breakingLine: <br /> }}
                        />
                      </Typography>
                    }
                  >
                    <TextField
                      type="email"
                      label={<FormattedMessage id="FORM.FIELD.EMAIL_ADDRESS" />}
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      disabled
                    />
                  </Tooltip>
                </div>
                <IsAdmin markup="font">
                  <InputSwitcher
                    checked={adminMarkup}
                    value="adminMarkup"
                    label={<FormattedMessage id="Admin Only styles active" />}
                    onChange={(e) => this.handleAdminMarkup(e)}
                    style={{marginBottom: "2.4rem"}}
                  />
                </IsAdmin>
                <br/>
                <IsAdmin markup="font">
                  <InputSwitcher
                    checked={adminScripts}
                    value="adminScripts"
                    label={<FormattedMessage id="Activate tracking scripts for Admin Account" />}
                    onChange={(e) => this.handleAdminScripts(e)}
                    style={{marginBottom: "2.4rem"}}
                  />
                </IsAdmin>
                <Typography variant="body1">
                  <FormattedMessage id="USER.PHRASE.EMAIL_LANGUAGE" />
                </Typography>
                <div className={styles.lang}>
                  <LanguageSelector
                    preventReload={true}
                    alignToLeft={true}
                    cutomHandler={(langCode) => {this.setState({mailLangCode: langCode}); return true}}
                    currentLanguage={this.state.mailLangCode}
                  />
                </div>
                <br/>

                <div className="kt-login__actions">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={updateLoading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": updateLoading,
                      }
                    )}`}
                  >
                    <FormattedMessage id="GENERAL.PHRASE.SAVE_CHANGES" />
                  </button>
                </div>
              </form>
            )}
          </Formik>
          <ConfirmationDialog
            open={this.state.confirmationOpen}
            onConfirm={this.handleConfirmImageRemove}
            message={<FormattedMessage id="USER.PHRASE.REMOVE_IMAGE_MESSAGE" />}
          />
        </PortletBody>
      </Portlet>
    );
  }
}
