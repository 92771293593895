import React from "react";
import styles from "./index.module.scss";
import {toNumberFormat} from "../../../../../utils/helpers";
import cn from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import {callReddClickEvent} from "../../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";
import BlurText from "../../../../../partials/components/BlurText";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";

function PropertyDataSummary({basic, basicLoading, breakPoint, ...props}) {
  const occupancyRate = basic?.occupancyRateV2 ? Number(basic?.occupancyRateV2).toFixed(0) : null;
  const occupancyDiff =
    Number(basic?.occupancyRate6m) < Number(basic?.occupancyRateV2) ? "up" :
    Number(basic?.occupancyRate6m) > Number(basic?.occupancyRateV2) ? "down" : null;
  const availabilityDiff =
    Number(basic?.totalAreaAvailable6m) > Number(basic?.totalAreaAvailable) ? "up" :
    Number(basic?.totalAreaAvailable6m) < Number(basic?.totalAreaAvailable) ? "down" : null;
  const ttlDiff =
    Number(basic?.timeToLease6m) < Number(basic?.timeToLease) ? "down" :
    Number(basic?.timeToLease6m) > Number(basic?.timeToLease) ? "up" : null;
  const isBlurred = false;

  // Handlers
  const handleValueLoading = (value) => {
    if(basicLoading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }

  return(
    <div className={cn(
      styles.propertyDataSummaryWrapper,
      breakPoint ? styles.singleColumn : undefined
    )}>
      <div className={styles.dataSummaryItemWrapper}>
        <div className={styles.itemWrapper}>
          <label><FormattedMessage id="VOCABULARY.Occupancy"/></label>
          <span
            className={cn(
              styles.primaryValue,
              styles?.[occupancyDiff]
            )}
          >
            <BlurText
              type="percentage"
              isBlurred={isBlurred}
            >
              {handleValueLoading(toNumberFormat(Number(occupancyRate).toFixed(1), '%'))}
            </BlurText>
          </span>
        </div>
        <div className={styles.itemWrapper}>
          <label>6M</label>
          <span>
            <BlurText
              customBlurValue={123}
              isBlurred={isBlurred}
            >
              {handleValueLoading(toNumberFormat(Number(basic?.occupancyRate6m).toFixed(1)))}
            </BlurText>
          </span>
        </div>
        <div className={styles.itemWrapper}>
          <label>12M</label>
          <span>
            <BlurText
              customBlurValue={123}
              isBlurred={isBlurred}
            >
              {handleValueLoading(toNumberFormat(Number(basic?.occupancyRate12m).toFixed(1)))}
            </BlurText>
          </span>
        </div>
      </div>
      <div className={styles.dataSummaryItemWrapper}>
        <div className={styles.itemWrapper}>
          <label><FormattedMessage id="VOCABULARY.Availability"/></label>
          <span
            className={cn(
              styles.primaryValue,
              styles?.[availabilityDiff]
            )}
          >
            <BlurText
              type="area"
              isBlurred={isBlurred}
            >
              {handleValueLoading(toNumberFormat(Number(basic?.totalAreaAvailable).toFixed(0), 'area'))}
            </BlurText>
          </span>
        </div>
        <div className={styles.itemWrapper}>
          <label>6M</label>
          <span>
            <BlurText
              customBlurValue={1234}
              isBlurred={isBlurred}
            >
              {handleValueLoading(toNumberFormat(Number(basic?.totalAreaAvailable6m).toFixed(0)))}
            </BlurText>
          </span>
        </div>
        <div className={styles.itemWrapper}>
          <label>12M</label>
          <span>
            <BlurText
              customBlurValue={1234}
              isBlurred={isBlurred}
            >
              {handleValueLoading(toNumberFormat(Number(basic?.totalAreaAvailable12m).toFixed(0)))}
            </BlurText>
          </span>
        </div>
      </div>
      <div className={styles.dataSummaryItemWrapper}>
        <div className={styles.itemWrapper}>
          <label>Time to lease</label>
          <span
            className={cn(
              styles.primaryValue,
              styles?.[ttlDiff]
            )}
          >
            <BlurText
              customBlurValue={
              <FormattedMessage id="BUILDING.PHRASE.REDD_INDEX_DAYS" values={{days: 123}}/>
              }
              isBlurred={isBlurred}
            >
              {handleValueLoading(basic?.timeToLease > 0 ?
              <FormattedMessage
                id="BUILDING.PHRASE.REDD_INDEX_DAYS"
                values={{days: toNumberFormat(basic?.timeToLease?.toFixed(0))}}
              /> : "-")}
            </BlurText>
          </span>
        </div>
        <div className={styles.itemWrapper}>
          <label>6M</label>
          <span>
            <BlurText
              customBlurValue={123}
              isBlurred={isBlurred}
            >
              {handleValueLoading(basic?.timeToLease6m > 0 ?
                toNumberFormat(Number(basic?.timeToLease6m).toFixed(0)) :
                "-")
              }
            </BlurText>
          </span>
        </div>
        <div className={styles.itemWrapper}>
          <label>12M</label>
          <span>
            <BlurText
              customBlurValue={123}
              isBlurred={isBlurred}
            >
              {handleValueLoading(basic?.timeToLease12m > 0 ?
                toNumberFormat(Number(basic?.timeToLease12m).toFixed(0)) :
                "-")
              }
            </BlurText>
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  basic: store.officeBuilding.basic,
  basicLoading: store.officeBuilding.basicLoading,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyDataSummary)
);