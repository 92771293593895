import React from "react";
import {FormattedMessage} from "react-intl";
import IconColorMUI from "app/partials/components/IconColorMUI";
import ButtonSimple from "app/partials/components/ButtonSimple";
import {callReddClickEvent} from "app/crud/user.crud";
import {fetchIndustrialParkRegionXls} from "app/crud/estates/industrial/industrialPark.crud";
import connect from "react-redux/es/connect/connect";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../utils/IsPro";

function PropertyRegionXlsDownload({basic, xlsLoading, fetchIndustrialParkRegionXls, reddClick}) {
  const handleDownloadXls = () => {
    const officeID = basic?.id;
    const officeName = basic?.name;

    if(!xlsLoading && fetchIndustrialParkRegionXls && officeID) {
      callReddClickEvent(
        reddClick,
        'office',
        null,
        officeID
      );
      fetchIndustrialParkRegionXls(officeID, officeName);
    }
  }

  return(
    <IsPro
      paywall
      paywallComponent={
      <PaywallModal
        triggerBtn={
        <ButtonSimple
          label={<FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>}
          icon={
          <IconColorMUI
            icon="download"
            size="18px"
            style={{paddingRight: "5px", lineHeight: "0"}}
          />
          }
        />
        }
      />
      }
    >
      <ButtonSimple
        label={<FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>}
        icon={
        <IconColorMUI
          icon="download"
          size="18px"
          style={{paddingRight: "5px", lineHeight: "0"}}
        />
        }
        loading={xlsLoading}
        onClick={() => handleDownloadXls()}
      />
    </IsPro>
  );
}

const mapStateToProps = store => ({
  basic: store.industrialPark.basic,
  xlsLoading: store.industrialPark.xlsLoading
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchIndustrialParkRegionXls: (parkID, propertyName) => fetchIndustrialParkRegionXls(parkID, propertyName)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyRegionXlsDownload);