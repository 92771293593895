import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import {ROUTES} from "app/constants";
import TableCustom from "app/partials/components/TableCustom";
import {fetchOfficesLatestLeads} from "app/crud/estates/offices/offices.crud";
import {fetchIndustrialParksLatestLeads} from "../../../../crud/estates/industrial/industrial.crud";
import _ from "lodash";
import {toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import APPLICATION_VIEWS from "app/constants/APPLICATION_VIEWS";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import HighlightLabel from "app/partials/components/HighlightLabel";
import ReactVisibilitySensor from "react-visibility-sensor";
import styles from "../../components/LeadsPages/index.module.scss";
import PropertySubscription from "../../components/LeadsPages/components/PropertySubscription";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";

class DashboardLatestLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tBody: null,
      initLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {currentApplicationView, latestLeads, latestLeadsIndustrial} = this.props;
    const isOfficesView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const data = isOfficesView ? latestLeads : latestLeadsIndustrial;
    const dataPrev = isOfficesView ? prevProps?.latestLeads : prevProps?.latestLeadsIndustrial;
    // Re-render TableBody
    if(
      // if offices was empty
      (_.isEmpty(dataPrev) && data?.length > 0) ||
      // if offices was updated
      (!_.isEqual(dataPrev, data))
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }
  // Handlers
  handlePrepTableBody = () => {
    const {currentApplicationView, latestLeads, latestLeadsIndustrial, intl} = this.props;
    const isOfficesView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const data = isOfficesView ? latestLeads : latestLeadsIndustrial;

    if(data?.length > 0) {
      return data?.map(lead => {
        const areaRange = [
          ...lead?.areaFrom ? [lead?.areaFrom] : [],
          ...lead?.areaTo ? [lead?.areaTo] : []
        ];

        return {
          isHidden: lead?.isHidden,
          data: [
            <span className={styles.propertyNameWrapper}>
              <PropertySubscription subscription={lead?.propertySubscription}/>
              <PropertyCardPreview
                propertyID={lead?.[isOfficesView ? "officeId" : "parkId"]}
                route={isOfficesView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                routeNewTab
              >
                <span className={styles.propertyName}>{lead?.[isOfficesView ? "officeName" : "parkName"]}</span>
              </PropertyCardPreview>
            </span>,
            <HighlightLabel color="neutral2">
              {toNumberFormat(areaRange.join(" - "), 'range', '', 'area')}
            </HighlightLabel>,
            lead?.name,
            toDateFormat(lead?.createdAt),
            lead?.isHidden ?
            <PaywallModal
              triggerLabel={
                lead?.isHidden ? <FormattedMessage id="VOCABULARY.Unlock"/> :
                  <FormattedMessage id="VOCABULARY.Tenant details"/>
              }
              bannerVariant="default-unlock_lead"
              triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Unlock"})}
              withIcon
            /> :
            <ButtonCustom
              label={<FormattedMessage id="VOCABULARY.Tenant details"/>}
              color="danger"
              redirect={ROUTES.LEADS_TENANT_INTRODUCTIONS}
              onClick={() => {
                localStorage.setItem("redirectOfficeID", lead?.[isOfficesView ? "officeId" : "parkId"]);
                localStorage.setItem("redirectOfficeLeadID", lead?.id);
              }}
              trackingData={{
                clickType: isOfficesView ?
                  "dashboard-latest-leads-view-details-click" :
                  "dashboard-industrial-latest-leads-view-details-click",
                contentType: "user"
              }}
              solid
              normalCase
            />
          ]
        }
      });
    }
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficesLatestLeads,
      fetchIndustrialParksLatestLeads,
      loading,
      loadingIndustrial,
      currentApplicationView
    } = this.props;
    const {initLoaded} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const params = {
      isEditable: 'true',
      size: 3
    };

    if(isOfficeView && isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficesLatestLeads({params}));
    }
    else if(isIndustrialView && isVisible && !loadingIndustrial && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchIndustrialParksLatestLeads({params}));
    }
  };

  render() {
    const {loading, loadingIndustrial, currentApplicationView} = this.props;
    const {tBody} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const tHead = [
      {
        "label": isOfficeView ? <FormattedMessage id="GENERAL.PHRASE.BUILDING"/> : <FormattedMessage id="GENERAL.PHRASE.INDUSTRIAL_PARK"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.AREA"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.COMPANY"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.DATE"/>,
        "number": false
      },
      {
        "label": "",
        "number": true
      }
    ];

    return(
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3"><FormattedMessage id="DASHBOARD.PHRASE.LATEST_ENQUIRIES"/> <span style={{color: "#00A6ED"}}>finne</span></Typography>
        </ReactVisibilitySensor>
        }
        footer={
        <ButtonCustom
          label={<FormattedMessage id="VOCABULARY.Check all"/>}
          color="brandPlainText"
          normalCase
          redirect={ROUTES.LEADS_TENANT_INTRODUCTIONS}
          trackingData={{
            clickType: isOfficeView ?
              "dashboard-latest-leads-see-all-click" :
              "dashboard-industrial-latest-leads-see-all-click",
            contentType: "user"
          }}
          btnStyle={{fontSize: "1.6rem", fontWeight: "400"}}
        />
        }
        disableAccordion
      >
        {(isOfficeView ? loading : loadingIndustrial) ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData message={<FormattedMessage id="GENERAL.PHRASE.NO_LEADS"/>} withIcon/> :
            <TableCustom
              tHead={tHead}
              tBody={tBody}
              blurrIndexes={[2]}
              blurrValues={[<span style={{lineHeight: "1.8rem"}}>Lorem ipsum<br/>+48 000-000-000</span>]}
              responsive
            />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (state) => ({
  latestLeads: state.offices.latestLeads,
  loading: state.offices.latestLeadsLoading,
  latestLeadsIndustrial: state.industrial.latestLeads,
  loadingIndustrial: state.industrial.latestLeadsLoading,
  currentApplicationView: state.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchOfficesLatestLeads: ({params}) => fetchOfficesLatestLeads(params),
  fetchIndustrialParksLatestLeads: ({params}) => fetchIndustrialParksLatestLeads(params)
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardLatestLeads))