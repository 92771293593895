import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {resetFilters, setFilters} from "app/utils/filters";
import {actionNotification} from "../../../../utils/notifications";
import {handleErrorResponse} from "../../../../utils/helpers";
import IPI_PLAN_SOURCE from "../../../../constants/IPI_PLAN_SOURCE";
import _ from "lodash";
import {VIEW_TYPES} from "../../../../constants";

const initialState = {
  // PARKS
  parksItems: [],
  parksItemsCount: null,
  parksItemsPerPage: 20,
  parksTotal: null,
  parksLoading: false,
  parksLoaded: false,
  viewType: VIEW_TYPES.LIST_VIEW,
  parkClusters: null,
  parkClustersLoaded: false,
  parkClustersLoading: false,
  // PARKS SIMPLE LIST
  parksSimpleList:null,
  parksOwnedSimpleList: null,
  parksSimpleListLoading: false,
  // PARKS COMPARING
  parksCompareResults: null,
  parksComparing: false,
  // PARKS MINIFIED
  parksMinified: null,
  parksMinifiedLoading: false,
  // PARK
  park: null,
  parkLoading: false,
  parkLoaded: false,
  // PARK WAREHOUSES
  parkWarehouses: [],
  parkWarehousesLoading: false,
  parkWarehousesLoaded: false,
  // PARK UNITS
  parkUnits: [],
  parkUnitsLoading: false,
  parkUnitsLoaded: false,
  // PARK UNITS TRACKING
  parkUnitsTracking: [],
  parkUnitsTrackingLoading: false,
  // PARK PHOTOS
  parkPhotos: null,
  parkPhotosLoading: false,
  // PARK PHOTO UPLOAD
  parkPhotoUploading: false,
  // PARK OWNERS
  parkOwners: null,
  parkOwnersLoading: false,
  // PARK MANAGE OWNERS
  parkManageOwnersCurrent: null,
  parkManageOwnersPrevious: null,
  parkManageOwnersCompanies: null,
  parkManageOwnersTotalShare: null,
  parkManageOwnersLoading: false,
  // PARK MANAGE CONTACT PERSONS
  parkManageContactPersons: null,
  parkManageContactPersonsLoading: false,
  // PARK ADD
  addParkLoading: false,
  // PARK UPDATE
  parkUpdateLoading: false,
  // PARK FILES
  parkFiles: [],
  parkFilesLoading: false,
  // PARK UPLOAD FILE
  uploadParkFileLoading: false,
  // PARK FILES DOWNLOAD
  parkFilesDownloading: false,
  parkDownloadFilesData: null,
  // PARK POIS
  parkPOIS: null,
  parkPOISLoading: false,
  // PARK BUILDING ADDING
  addingBuilding: false,
  buildingAdded: false,
  // WAREHOUSES
  warehousesItems: [],
  warehousesItemsCount: null,
  warehousesItemsPerPage: 20,
  warehousesTotal: null,
  warehousesLoading: false,
  warehousesLoaded: false,
  viewTypeWarehouses: VIEW_TYPES.LIST_VIEW,
  warehousesClusters: null,
  warehousesClustersLoaded: false,
  warehousesClustersLoading: false,
  // WAREHOUSE
  warehouse: null,
  warehouseLoading: false,
  warehouseLoaded: false,
  // WAREHOUSE UNITS
  warehouseUnits: [],
  warehouseUnitsLoading: false,
  warehouseUnitsLoaded: false,
  // WAREHOUSE UNITS TRACKING
  warehouseUnitsTracking: [],
  warehouseUnitsTrackingLoading: false,
  // WAREHOUSE PHOTOS
  warehousePhotos: null,
  warehousePhotosLoading: false,
  // WAREHOUSE PHOTO UPLOAD
  warehousePhotoUploading: false,
  // WAREHOUSE OWNERS
  warehouseOwners: null,
  warehouseOwnersLoading: false,
  // WAREHOUSE MANAGE OWNERS
  warehouseManageOwnersCurrent: null,
  warehouseManageOwnersPrevious: null,
  warehouseManageOwnersCompanies: null,
  warehouseManageOwnersTotalShare: null,
  warehouseManageOwnersLoading: false,
  // WAREHOUSE MANAGE CONTACT PERSONS
  warehouseManageContactPersons: null,
  warehouseManageContactPersonsLoading: false,
  // WAREHOUSE TECHNICAL DETAILS
  warehouseTechnicalDetails: null,
  warehouseTechnicalDetailsLoading: false,
  // WAREHOUSE AMENITIES
  warehouseAmenities: null,
  warehouseAmenitiesLoading: false,
  warehouseAmenitiesUpdating: false,
  // WAREHOUSE ADD
  addWarehouseLoading: false,
  // WAREHOUSE UPDATE
  warehouseUpdateLoading: false,
  // WAREHOUSE FILES
  warehouseFiles: [],
  warehouseFilesLoading: false,
  // WAREHOUSE UPLOAD FILE
  uploadWarehouseFileLoading: false,
  // WAREHOUSE UNITS ACTIONS
  addWarehouseUnitLoading: false,
  updateWarehouseUnitLoading: false,
  uploadWarehouseUnitPlanLoading: false,
  refreshWarehouseUnitsLoading: false,
  // WAREHOUSE UNITS ADDING
  addingUnit: false,
  unitAdded: false,
  // WAREHOUSE OWNERS LOANS
  warehouseOwnersLoans: null,
  warehouseOwnersLoansLoading: false,
  // WAREHOUSE OWNERS PORTFOLIO
  warehouseOwnersPortfolio: null,
  warehouseOwnersPortfolioLoading: false,
  // WAREHOUSE FINANCES
  warehouseFinances: null,
  warehouseFinancesLoading: false,
  // WAREHOUSE ACQUISITIONS
  warehouseAcquisitions: null,
  warehouseAcquisitionsLoading: false,
  // WAREHOUSE COLUMN GRID DIMENSIONS
  columnGridDimensions: [],
  columnGridDimensionsLoading: false,
  // IPI - UNITS EDIT MODE
  ipiParkUnitsEditMode: null,
  ipiWarehouseUnitsEditMode: null,
  ipiParkUnitsEditModeLoading: false,
  ipiWarehouseUnitsEditModeLoading: false,
  // IPI - PLAN UPLOADING
  ipiPlanUploading: false,
  // IPI - SAVE CHANGES
  ipiUnitsUpdating: false,
  // OTHERS ACTION - ADD POI
  addIndustrialPOILoading: false,
  // OTHERS ACTIONS - REMOVE POI
  removeIndustrialPOILoading: false,
  // OTHERS ACTIONS - UPDATE FILE
  updateIndustrialFileLoading: false,
  // OTHERS ACTIONS - DELETE FILE
  deleteIndustrialFileLoading: false,
  // INDUSTRIAL AMENITIES
  industrialAmenities: null,
  industrialAmenitiesLoading:false,
  // INDUSTRIAL MARKETS CITIES
  industrialMarketsCities: null,
  industrialMarketsCitiesLoading: false,
  // INDUSTRIAL TRANSACTIONS/SUPPLY
  industrialDashboardTransactions: null,
  industrialDashboardTransactionsLoading: false,
  industrialDashboardSupplyBoost: null,
  industrialDashboardSupplyBoostLoading: false,
  industrialTransactions: null,
  industrialTransactionsLoading: false,
  industrialSupplyBoost: null,
  industrialSupplyBoostLoading: false,
  //
  latestLeads: null,
  latestLeadsLoading: false,
  visitingCompanies: null,
  visitingCompaniesLoading: false,
  // Warehouse Unit - edit form
  warehouseUnitEditFormsData: null,
  warehouseUnitEditFormsDataLoading: false
};

const handleUpdatedUnitData = (oldUnits, updatedUnit) => {
  if(oldUnits) {
    const foundChanged = oldUnits.findIndex(unit => unit.id === updatedUnit.id);
    oldUnits[foundChanged] = updatedUnit;
    return [...oldUnits];
  }
  return oldUnits;
};
const handleUpdatedUnitPlan = (oldUnits, updatedUnit) => {
  if(oldUnits) {
    const foundChanged = oldUnits.findIndex(unit => unit.id === updatedUnit.id);
    oldUnits[foundChanged]["planImage"] = updatedUnit.planImage;
    return [...oldUnits];
  }
  return oldUnits;
};
const handleUpdateFiles = (oldFiles, updatedFile) => {
  if(oldFiles) {
    const foundChanged = oldFiles.findIndex(file => file.id === updatedFile.id);
    oldFiles[foundChanged] = updatedFile;
    return [...oldFiles];
  }
  return oldFiles;
};
const handleUpdatedIPIPlan = (source, planField, newPlan, propertyID) => {
  if(source && newPlan) {
    if(propertyID) {
      const foundChangedProperty = source.findIndex(property => property?.id === propertyID);
      source[foundChangedProperty][planField] = newPlan;
      return [...source];
    }
    else {
      source[planField] = newPlan;
      return source;
    }
  }
  return source;
};
const handleUpdateWarehousesListItem = (warehouseID, oldList, newData) => {
  if(oldList) {
    const foundChanged = oldList.findIndex(warehouse => warehouse.id === warehouseID);
    oldList[foundChanged]["lackOfAvailableArea"] = newData;
    return [...oldList];
  }
  return oldList;
}

export const reducer = persistReducer(
  { storage, key: "industrial" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET INDUSTRIAL LOADING STATES
      case 'RESET_INDUSTRIAL_LOADING_STATES_REQUEST': {
        return {
          ...state,
          parksLoading: false,
          parkClustersLoading: false,
          parksSimpleListLoading: false,
          parksMinifiedLoading: false,
          parkLoading: false,
          parkWarehousesLoading: false,
          parkUnitsLoading: false,
          parkUnitsTrackingLoading: false,
          parkPhotosLoading: false,
          parkPhotoUploading: false,
          parkOwnersLoading: false,
          parkManageOwnersLoading: false,
          addParkLoading: false,
          parkUpdateLoading: false,
          parkFilesLoading: false,
          parkPOISLoading: false,
          warehousesLoading: false,
          warehousesClustersLoading: false,
          warehouseLoading: false,
          warehouseUnitsLoading: false,
          warehouseUnitsTrackingLoading: false,
          warehousePhotosLoading: false,
          warehousePhotoUploading: false,
          warehouseOwnersLoading: false,
          warehouseManageOwnersLoading: false,
          warehouseManageContactPersonsLoading: false,
          warehouseTechnicalDetailsLoading: false,
          warehouseAmenitiesLoading: false,
          addWarehouseLoading: false,
          warehouseUpdateLoading: false,
          warehouseFilesLoading: false,
          uploadWarehouseFileLoading: false,
          addWarehouseUnitLoading: false,
          updateWarehouseUnitLoading: false,
          uploadWarehouseUnitPlanLoading: false,
          refreshWarehouseUnitsLoading: false,
          warehouseOwnersLoansLoading: false,
          warehouseOwnersPortfolioLoading: false,
          warehouseFinancesLoading: false,
          warehouseAcquisitionsLoading: false,
          columnGridDimensionsLoading: false,
          ipiParkUnitsEditModeLoading: false,
          ipiWarehouseUnitsEditModeLoading: false,
          ipiPlanUploading: false,
          addIndustrialPOILoading: false,
          removeIndustrialPOILoading: false,
          updateIndustrialFileLoading: false,
          deleteIndustrialFileLoading: false,
          industrialAmenitiesLoading: false,
          industrialMarketsCitiesLoading: false,
          industrialDashboardTransactionsLoading: false,
          industrialDashboardSupplyBoostLoading: false,
          industrialTransactionsLoading: false,
          industrialSupplyBoostLoading: false,
          latestLeadsLoading: false,
          visitingCompaniesLoading: false,
          warehouseUnitEditFormsDataLoading: false
        }
      }
      // PARKS
      case 'FETCH_INDUSTRIAL_PARKS_REQUEST': {
        return { ...state, parksLoading: true };
      }
      case 'FETCH_INDUSTRIAL_PARKS_SUCCESS': {
        const { items, itemsCount, itemsPerPage, total } = action.payload;
        return { ...state, parksLoading: false, parksLoaded: true, parksItems: items, parksItemsCount: itemsCount, parksItemsPerPage: itemsPerPage, parksTotal: total };
      }
      case 'FETCH_INDUSTRIAL_PARKS_FAILED': {
        return { ...state, parksLoading: false, parksLoaded: true, parksItems: [], parksItemsCount: null, parksItemsPerPage: 20, parksTotal: null };
      }
      // PARKS View Type
      case 'GET_INDUSTRIAL_PARKS_VIEW_TYPE_SUCCESS': {
        return { ...state, viewType: action.payload }
      }
      // PARKS CLUSTERS
      case 'FETCH_INDUSTRIAL_PARKS_CLUSTERS_RESET_REQUEST': {
        return {
          ...state,
          parkClustersLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_CLUSTERS_REQUEST': {
        return {
          ...state,
          parkClustersLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_CLUSTERS_SUCCESS': {
        return {
          ...state,
          parkClusters: action.payload,
          parkClustersLoaded: true,
          parkClustersLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_CLUSTERS_FAILED': {
        return {
          ...state,
          parkClusters: null,
          parkClustersLoading: false
        }
      }
      // PARKS SIMPLE LIST
      case 'FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_REQUEST': {
        return { ...state, parksSimpleListLoading: true };
      }
      case 'FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_SUCCESS': {
        return { ...state, parksSimpleListLoading: false, parksSimpleList: action.payload };
      }
      case 'FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_FAILED': {
        return { ...state, parksSimpleListLoading: false, parksSimpleList: null };
      }
      case 'FETCH_INDUSTRIAL_PARKS_OWNED_SIMPLE_LIST_SUCCESS': {
        return { ...state, parksOwnedSimpleList: action.payload ? action.payload : null, parksSimpleList: false }
      }
      // PARKS LIST RESET
      case 'FETCH_INDUSTRIAL_PARKS_RESET_REQUEST': {
        return { ...state, parksLoading: true };
      }
      case 'FETCH_INDUSTRIAL_PARKS_RESET_FAILED': {
        return { ...state, parksLoading: false };
      }
      // PARKS MINIFIED
      case 'FETCH_INDUSTRIAL_PARKS_MINIFIED_REQUEST': {
        return {
          ...state,
          parksMinifiedLoading: true,
          parksMinified: null
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_MINIFIED_SUCCESS': {
        return {
          ...state,
          parksMinifiedLoading: false,
          parksMinified: action?.payload
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_MINIFIED_FAILED': {
        return {
          ...state,
          parksMinifiedLoading: false,
          parksMinified: null
        }
      }
      case 'RESET_INDUSTRIAL_PARKS_MINIFIED_REQUEST': {
        return {
          ...state,
          parksMinifiedLoading: false,
          parksMinified: null
        }
      }
      // PARK
      case 'FETCH_INDUSTRIAL_PARK_REQUEST': {
        return {
          ...state,
          parkLoading: true,
          parkLoaded: false,
          park: null,
          parkPhotos: null,
          parkFiles: []
        };
      }
      case 'FETCH_INDUSTRIAL_PARK_SUCCESS': {
        return {
          ...state,
          parkLoading: false,
          parkLoaded: true,
          park: action.payload
        };
      }
      case 'FETCH_INDUSTRIAL_PARK_FAILED': {
        return {
          ...state,
          parkLoading: false,
          parkLoaded: true,
          park: null,
          parkPhotos: null,
          parkFiles: []
        };
      }
      // PARK RESET STATE
      case 'RESET_PARK_STATE_REQUEST': {
        return {
          ...state,
          park: null,
          parkLoading: false,
          parkLoaded: false,
          parkPhotos: null,
          parkFiles: [],
          parkPhotosLoading: false,
          parkFilesLoading: false
        }
      }
      // PARK WAREHOUSES
      case 'FETCH_INDUSTRIAL_PARK_WAREHOUSES_REQUEST': {
        return { ...state, parkWarehousesLoading: true, parkWarehousesLoaded: false };
      }
      case 'FETCH_INDUSTRIAL_PARK_WAREHOUSES_SUCCESS': {
        return { ...state, parkWarehousesLoading: false, parkWarehousesLoaded: true, parkWarehouses: action.payload };
      }
      case 'FETCH_INDUSTRIAL_PARK_WAREHOUSES_FAILED': {
        return { ...state, parkWarehousesLoading: false, parkWarehousesLoaded: true, parkWarehouses: [] };
      }
      // PARK UNITS
      case 'FETCH_INDUSTRIAL_PARK_UNITS_REQUEST': {
        return { ...state, parkUnitsLoading: true, parkUnitsLoaded: false, parkUnits: [] };
      }
      case 'FETCH_INDUSTRIAL_PARK_UNITS_SUCCESS': {
        return { ...state, parkUnitsLoading: false, parkUnitsLoaded: true, parkUnits: action.payload };
      }
      case 'FETCH_INDUSTRIAL_PARK_UNITS_FAILED': {
        return { ...state, parkUnitsLoading: false, parkUnitsLoaded: true, parkUnits: [] };
      }
      // PARK PHOTOS
      case 'FETCH_INDUSTRIAL_PARK_PHOTOS_REQUEST': {
        return { ...state, parkPhotosLoading: true, parkPhotos: null };
      }
      case 'FETCH_INDUSTRIAL_PARK_PHOTOS_SUCCESS': {
        return { ...state, parkPhotosLoading: false, parkPhotos: action.payload };
      }
      case 'FETCH_INDUSTRIAL_PARK_PHOTOS_FAILED': {
        return { ...state, parkPhotosLoading: false, parkPhotos: null };
      }
      // PARK ADD PHOTO
      case 'ADD_INDUSTRIAL_PARK_PHOTO_REQUEST': {
        return { ...state, parkPhotoUploading: true };
      }
      case 'ADD_INDUSTRIAL_PARK_PHOTO_SUCCESS': {
        return { ...state, parkPhotoUploading: false };
      }
      case 'ADD_INDUSTRIAL_PARK_PHOTO_FAILED': {
        return { ...state, parkPhotoUploading: false };
      }
      // PARK DELETE PHOTO
      case 'DELETE_INDUSTRIAL_PARK_PHOTO_SUCCESS': {
        return { ...state, parkPhotos: [...state.parkPhotos.filter(photo => photo.id !== action.payload)]}
      }
      // PARK MANAGE OWNERS
      case 'FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_REQUEST': {
        return {
          ...state,
          parkManageOwnersCurrent: null,
          parkManageOwnersPrevious: null,
          parkManageOwnersCompanies: null,
          parkManageOwnersTotalShare: null,
          parkManageOwnersLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_SUCCESS': {
        const data = action.payload ? action.payload : null;
        return {
          ...state,
          parkManageOwnersCurrent: data && data.owners && data.owners.current,
          parkManageOwnersPrevious: data && data.owners && data.owners.previous,
          parkManageOwnersCompanies: data && data.companies,
          parkManageOwnersTotalShare: data && data.totalShare,
          parkManageOwnersLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_FAILED': {
        return {
          ...state,
          parkManageOwnersCurrent: null,
          parkManageOwnersPrevious: null,
          parkManageOwnersCompanies: null,
          parkManageOwnersTotalShare: null,
          parkManageOwnersLoading: false
        }
      }
      // FETCH LATEST LEADS
      case 'FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_REQUEST': {
        return {
          ...state,
          latestLeads: null,
          latestLeadsLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_SUCCESS': {
        const { items } = action.payload;
        return {
          ...state,
          latestLeads: items,
          latestLeadsLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_FAILED': {
        return {
          ...state,
          latestLeads: null,
          latestLeadsLoading: false
        };
      }
      // FETCH VISITING COMPANIES
      case 'FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_REQUEST': {
        return {
          ...state,
          visitingCompanies: null,
          visitingCompaniesLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_SUCCESS': {
        const { items } = action.payload;
        return {
          ...state,
          visitingCompanies: items,
          visitingCompaniesLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_FAILED': {
        return {
          ...state,
          visitingCompanies: null,
          visitingCompaniesLoading: false
        };
      }
      // PARK MANAGE OWNERS - ADD
      case 'ADD_INDUSTRIAL_PARK_OWNER_REQUEST': {
        return {...state, parkManageOwnersLoading: true}
      }
      case 'ADD_INDUSTRIAL_PARK_OWNER_SUCCESS': {
        const data = action.payload;
        return {
          ...state,
          parkManageOwnersCurrent: data && data.owners && data.owners.current,
          parkManageOwnersTotalShare: data.totalShare,
          parkManageOwnersLoading: false
        }
      }
      case 'ADD_INDUSTRIAL_PARK_OWNER_FAILED': {
        return {...state, parkManageOwnersLoading: false}
      }
      // PARK MANAGE OWNERS - REMOVE
      case 'REMOVE_INDUSTRIAL_PARK_OWNER_SUCCESS': {
        const data = action.payload;
        return {
          ...state,
          parkManageOwnersCurrent: data && data.owners && data.owners.current,
          parkManageOwnersPrevious: data && data.owners && data.owners.previous,
          parkManageOwnersTotalShare: data.totalShare
        }
      }
      // PARK ADD
      case 'ADD_INDUSTRIAL_PARK_REQUEST': {
        return { ...state, addParkLoading: true };
      }
      case 'ADD_INDUSTRIAL_PARK_SUCCESS': {
        return { ...state, addParkLoading: false, parksItems: state.parksItems ? [action.payload, ...state.parksItems] : [action.payload] };
      }
      case 'ADD_INDUSTRIAL_PARK_FAILED': {
        return { ...state, addParkLoading: false };
      }
      // PARK UPDATE
      case 'UPDATE_INDUSTRIAL_PARK_REQUEST': {
        return { ...state, parkUpdateLoading: true };
      }
      case 'UPDATE_INDUSTRIAL_PARK_SUCCESS': {
        return { ...state, parkUpdateLoading: false, park: action.payload };
      }
      case 'UPDATE_INDUSTRIAL_PARK_FAILED': {
        return { ...state, parkUpdateLoading: false };
      }
      // PARK FILES
      case 'FETCH_INDUSTRIAL_PARK_PROPERTY_FILES_REQUEST': {
        return { ...state, parkFilesLoading: true };
      }
      case 'FETCH_INDUSTRIAL_PARK_PROPERTY_FILES_SUCCESS': {
        return { ...state, parkFilesLoading: false, parkFiles: action.payload };
      }
      case 'FETCH_INDUSTRIAL_PARK_PROPERTY_FILES_FAILED': {
        return { ...state, parkFilesLoading: false, parkFiles: [] };
      }
      // PARK UPLOAD FILE
      case 'UPLOAD_INDUSTRIAL_PARK_FILE_REQUEST': {
        return { ...state, uploadParkFileLoading: true };
      }
      case 'UPLOAD_INDUSTRIAL_PARK_FILE_SUCCESS': {
        return {
          ...state,
          uploadParkFileLoading: false,
          parkFiles: state.parkFiles ? [action.payload, ...state.parkFiles] : [action.payload],
          warehouseFiles: state.warehouseFiles ? [action.payload, ...state.warehouseFiles] : [action.payload]
        };
      }
      case 'UPLOAD_INDUSTRIAL_PARK_FILE_FAILED': {
        return { ...state, uploadParkFileLoading: false };
      }
      // PARK DOWNLOAD FILES
      case 'DOWNLOAD_INDUSTRIAL_PARK_FILES_REQUEST': {
        return { ...state, parkFilesDownloading: true, parkDownloadFilesData: null };
      }
      case 'DOWNLOAD_INDUSTRIAL_PARK_FILES_SUCCESS': {
        const fileUrl = URL.createObjectURL(action.payload.data);
        const link = document.createElement("a");
        link.download = action.payload.fileName;
        link.href = fileUrl;
        link.click();
        return { ...state, parkFilesDownloading: false, parkDownloadFilesData: fileUrl };
      }
      case 'DOWNLOAD_INDUSTRIAL_PARK_FILES_FAILED': {
        return { ...state, parkFilesDownloading: false, parkDownloadFilesData: null };
      }
      // WAREHOUSES
      case 'FETCH_INDUSTRIAL_WAREHOUSES_REQUEST': {
        return { ...state, warehousesLoading: true };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSES_SUCCESS': {
        const { items, itemsCount, itemsPerPage, total } = action.payload;
        return { ...state, warehousesLoading: false, warehousesLoaded: true, warehousesItems: items, warehousesItemsCount: itemsCount, warehousesItemsPerPage: itemsPerPage, warehousesTotal: total };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSES_FAILED': {
        return { ...state, warehousesLoading: false, warehousesLoaded: true, warehousesItems: [], warehousesItemsCount: null, warehousesItemsPerPage: 20, warehousesTotal: null };
      }
      // PARKS View Type
      case 'GET_INDUSTRIAL_WAREHOUSES_VIEW_TYPE_SUCCESS': {
        return { ...state, viewTypeWarehouses: action.payload }
      }
      // PARK COMPARE COMPETITION
      case 'COMPARE_SELECTED_PARKS_REQUEST': {
        return {
          ...state,
          parksComparing: !action.payload.inBackground,
          parksCompareResults: null
        }
      }
      case 'COMPARE_SELECTED_PARKS_SUCCESS': {
        return {
          ...state,
          parksComparing: false,
          parksCompareResults: action.payload
        }
      }
      case 'COMPARE_SELECTED_PARKS_FAILED': {
        return {
          ...state,
          parksComparing: false,
          parksCompareResults: null
        }
      }
      case 'COMPARE_SELECTED_PARKS_RESET': {
        return {
          ...state,
          parksComparing: false,
          parksCompareResults: null,
          parksSimpleList: null
        }
      }
      // WAREHOUSES LIST RESET
      case 'FETCH_INDUSTRIAL_WAREHOUSES_RESET_REQUEST': {
        return { ...state, warehousesLoading: true };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSES_RESET_FAILED': {
        return { ...state, warehousesLoading: false };
      }
      // WAREHOUSES CLUSTERS
      case 'FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_RESET_REQUEST': {
        return {
          ...state,
          warehousesClustersLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_REQUEST': {
        return {
          ...state,
          warehousesClustersLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_SUCCESS': {
        return {
          ...state,
          warehousesClusters: action.payload,
          warehousesClustersLoaded: true,
          warehousesClustersLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_FAILED': {
        return {
          ...state,
          warehousesClusters: null,
          warehousesClustersLoading: false
        }
      }
      // WAREHOUSE RESET STATE
      case 'RESET_WAREHOUSE_STATE_REQUEST': {
        return {
          ...state,
          warehouseLoading: false,
          warehouseLoaded: false,
          warehouse: null,
          warehousePhotos: null,
          warehouseFiles: [],
          warehousePhotosLoading: false,
          warehouseFilesLoading: false
        }
      }
      // WAREHOUSE UNITS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_REQUEST': {
        return { ...state, warehouseUnitsLoading: true, warehouseUnitsLoaded: false };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_SUCCESS': {
        return { ...state, warehouseUnitsLoading: false, warehouseUnitsLoaded: true, warehouseUnits: action.payload };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_FAILED': {
        return { ...state, warehouseUnitsLoading: false, warehouseUnitsLoaded: true, warehouseUnits: [] };
      }
      // WAREHOUSE UNITS - EDIT FORMS DATA
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_REQUEST': {
        return {
          ...state,
          warehouseUnitEditFormsData: null,
          warehouseUnitEditFormsDataLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_SUCCESS': {
        return {
          ...state,
          warehouseUnitEditFormsData: action.payload,
          warehouseUnitEditFormsDataLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_FAILED': {
        return {
          ...state,
          warehouseUnitEditFormsData: null,
          warehouseUnitEditFormsDataLoading: false
        }
      }
      // WAREHOUSE PHOTOS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_REQUEST': {
        return { ...state, warehousePhotosLoading: true, warehousePhotos: null };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_SUCCESS': {
        return { ...state, warehousePhotosLoading: false, warehousePhotos: action.payload };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_FAILED': {
        return { ...state, warehousePhotosLoading: false, warehousePhotos: null };
      }
      // WAREHOUSE ADD PHOTO
      case 'ADD_INDUSTRIAL_WAREHOUSE_PHOTO_REQUEST': {
        return { ...state, warehousePhotoUploading: true };
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_PHOTO_SUCCESS': {
        return { ...state, warehousePhotoUploading: false };
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_PHOTO_FAILED': {
        return { ...state, warehousePhotoUploading: false };
      }
      // WAREHOUSE DELETE PHOTO
      case 'DELETE_INDUSTRIAL_WAREHOUSE_PHOTO_SUCCESS': {
        return { ...state, warehousePhotos: [...state.warehousePhotos.filter(photo => photo.id !== action.payload)]}
      }
      // WAREHOUSE MANAGE OWNERS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_REQUEST': {
        return {
          ...state,
          warehouseManageOwnersCurrent: null,
          warehouseManageOwnersPrevious: null,
          warehouseManageOwnersCompanies: null,
          warehouseManageOwnersTotalShare: null,
          warehouseManageOwnersLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_SUCCESS': {
        const data = action.payload ? action.payload : null;
        return {
          ...state,
          warehouseManageOwnersCurrent: data && data.owners && data.owners.current,
          warehouseManageOwnersPrevious: data && data.owners && data.owners.previous,
          warehouseManageOwnersCompanies: data && data.companies,
          warehouseManageOwnersTotalShare: data && data.totalShare,
          warehouseManageOwnersLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_FAILED': {
        return {
          ...state,
          warehouseManageOwnersCurrent: null,
          warehouseManageOwnersPrevious: null,
          warehouseManageOwnersCompanies: null,
          warehouseManageOwnersTotalShare: null,
          warehouseManageOwnersLoading: false
        }
      }
      // WAREHOUSE MANAGE OWNERS - ADD
      case 'ADD_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST': {
        return {...state, warehouseManageOwnersLoading: true}
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_OWNER_SUCCESS': {
        const data = action.payload;
        return {
          ...state,
          warehouseManageOwnersCurrent: data && data.owners && data.owners.current,
          warehouseManageOwnersTotalShare: data.totalShare,
          warehouseManageOwnersLoading: false
        }
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_OWNER_FAILED': {
        return {...state, warehouseManageOwnersLoading: false}
      }
      // WAREHOUSE MANAGE OWNERS - REMOVE
      case 'REMOVE_INDUSTRIAL_WAREHOUSE_OWNER_SUCCESS': {
        const data = action.payload;
        return {
          ...state,
          warehouseManageOwnersCurrent: data && data.owners && data.owners.current,
          warehouseManageOwnersPrevious: data && data.owners && data.owners.previous,
          warehouseManageOwnersTotalShare: data.totalShare
        }
      }
      // WAREHOUSE AMENITIES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_REQUEST': {
        return { ...state, warehouseAmenitiesLoading: true, warehouseAmenities: null };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_SUCCESS': {
        return { ...state, warehouseAmenitiesLoading: false, warehouseAmenities: action.payload };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_FAILED': {
        return { ...state, warehouseAmenitiesLoading: false, warehouseAmenities: null };
      }
      // WAREHOUSE AMENITIES - UPDATE
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST': {
        return { ...state, warehouseAmenitiesLoading: true, warehouseAmenitiesUpdating: true };
      }
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_SUCCESS': {
        return {
          ...state,
          warehouseAmenitiesLoading: false,
          warehouseAmenitiesUpdating: false,
          warehouseAmenities: action.payload
        };
      }
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_FAILED': {
        return {
          ...state,
          warehouseAmenitiesLoading: false,
          warehouseAmenitiesUpdating: false
        };
      }
      // WAREHOUSE - ADD
      case 'ADD_INDUSTRIAL_WAREHOUSE_REQUEST': {
        return {
          ...state,
          addWarehouseLoading: true,
          addingBuilding: true,
          buildingAdded: false
        };
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_SUCCESS': {
        return {
          ...state, addWarehouseLoading: false,
          warehousesItems: state.warehousesItems ? [action.payload, ...state.warehousesItems] : [action.payload],
          addingBuilding: false,
          buildingAdded: true
        };
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_FAILED': {
        return {
          ...state,
          addWarehouseLoading: false,
          addingBuilding: false,
          buildingAdded: false
        };
      }
      // WAREHOUSE UPDATE
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_REQUEST': {
        return { ...state, warehouseUpdateLoading: true };
      }
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_SUCCESS': {
        const {data, warehouseID, request, backgroundRefresh} = action.payload;
        const isLackOfAvailableAreaChanged = _.has(request, "lackOfAvailableArea");

        return {
          ...state,
          warehouse: data,
          warehousesItems: isLackOfAvailableAreaChanged && !backgroundRefresh ? handleUpdateWarehousesListItem(warehouseID, state.warehousesItems, data?.lackOfAvailableArea) : state.warehousesItems,
          warehouseUpdateLoading: false
        };
      }
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_FAILED': {
        return { ...state, warehouseUpdateLoading: false };
      }
      // WAREHOUSE FILES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_OLD_REQUEST': {
        return { ...state, warehouseFilesLoading: true };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_OLD_SUCCESS': {
        return { ...state, warehouseFilesLoading: false, warehouseFiles: action.payload };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_OLD_FAILED': {
        return { ...state, warehouseFilesLoading: false, warehouseFiles: [] };
      }
      // WAREHOUSE UPLOAD FILE
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_REQUEST': {
        return { ...state, uploadWarehouseFileLoading: true };
      }
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_SUCCESS': {
        return {
          ...state,
          uploadWarehouseFileLoading: false,
          warehouseFiles: state.warehouseFiles ? [action.payload, ...state.warehouseFiles] : [action.payload],
          parkFiles: state.parkFiles ? [action.payload, ...state.parkFiles] : [action.payload]
        };
      }
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_FAILED': {
        return { ...state, uploadWarehouseFileLoading: false };
      }
      // WAREHOUSE UNIT - ADD UNIT
      case 'ADD_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST': {
        return {
          ...state,
          addWarehouseUnitLoading: true,
          addingUnit: true,
          unitAdded: false
        };
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS': {
        return {
          ...state,
          addWarehouseUnitLoading: false,
          addingUnit: false,
          unitAdded: true,
          warehouseUnits: state.warehouseUnits ? [action.payload, ...state.warehouseUnits] : [action.payload]
        };
      }
      case 'ADD_INDUSTRIAL_WAREHOUSE_UNIT_FAILED': {
        return {
          ...state,
          addWarehouseUnitLoading: false,
          addingUnit: false,
          unitAdded: false
        };
      }
      // WAREHOUSE UNIT - REMOVE UNIT
      case 'REMOVE_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS': {
        return { ...state, warehouseUnits: state.warehouseUnits ? [...state.warehouseUnits.filter(unit => unit.id !== action.payload)] : null };
      }
      // WAREHOUSE UNIT - UPDATE UNIT
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST': {
        return { ...state, updateWarehouseUnitLoading: true }
      }
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS': {
        return { ...state, updateWarehouseUnitLoading: false, warehouseUnits: handleUpdatedUnitData(state.warehouseUnits, action.payload) };
      }
      case 'UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_FAILED': {
        return { ...state, updateWarehouseUnitLoading: false }
      }
      // WAREHOUSE UNIT - UPLOAD PLAN
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_REQUEST': {
        return { ...state, uploadWarehouseUnitPlanLoading: true };
      }
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_SUCCESS': {
        return { ...state, uploadWarehouseUnitPlanLoading: true, warehouseUnits: handleUpdatedUnitPlan(state.warehouseUnits, action.payload) };
      }
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_FAILED': {
        return { ...state, uploadWarehouseUnitPlanLoading: true };
      }
      // WAREHOUSE UNIT - REFRESH
      case 'REFRESH_SINGLE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST': {
        return { ...state, refreshWarehouseUnitsLoading: true };
      }
      case 'REFRESH_SINGLE_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS': {
        return {
          ...state,
          refreshWarehouseUnitsLoading: false,
          warehouseUnits: handleUpdatedUnitData(state.warehouseUnits, action.payload)
        };
      }
      // WAREHOUSE OWNERS PORTFOLIO
      case 'FETCH_INDUSTRIAL_WAREHOUSE_OWNERS_PORTFOLIO_REQUEST': {
        return { ...state, warehouseOwnersPortfolioLoading: true, warehouseOwnersPortfolio: null };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_OWNERS_PORTFOLIO_SUCCESS': {
        return { ...state, warehouseOwnersPortfolioLoading: false, warehouseOwnersPortfolio: action.payload };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_OWNERS_PORTFOLIO_FAILED': {
        return { ...state, warehouseOwnersPortfolioLoading: false, warehouseOwnersPortfolio: null };
      }
      // WAREHOUSE FINANCES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FINANCES_REQUEST': {
        return { ...state, warehouseFinancesLoading: true, warehouseFinances: null };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FINANCES_SUCCESS': {
        return { ...state, warehouseFinancesLoading: false, warehouseFinances: action.payload };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FINANCES_FAILED': {
        return { ...state, warehouseFinancesLoading: false, warehouseFinances: null };
      }
      // IPI - PARK FETCH UNITS EDIT MODE
      case 'FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_REQUEST': {
        return { ...state, ipiParkUnitsEditModeLoading: true, ipiParkUnitsEditMode: null };
      }
      case 'FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_SUCCESS': {
        return {
          ...state,
          ipiParkUnitsEditModeLoading: false,
          ipiParkUnitsEditMode: action.payload
        };
      }
      case 'FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_FAILED': {
        return { ...state, ipiParkUnitsEditModeLoading: false, ipiParkUnitsEditMode: null };
      }
      // IPI - PARK FETCH UNITS EDIT MODE
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_REQUEST': {
        return { ...state, ipiWarehouseUnitsEditModeLoading: true, ipiWarehouseUnitsEditMode: null };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_SUCCESS': {
        return {
          ...state,
          ipiWarehouseUnitsEditModeLoading: false,
          ipiWarehouseUnitsEditMode: action.payload
        };
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_FAILED': {
        return { ...state, ipiWarehouseUnitsEditModeLoading: false, ipiWarehouseUnitsEditMode: null };
      }
      // IPI - UPLOAD PLAN
      case 'UPLOAD_INDUSTRIAL_PARK_IPI_PLAN_REQUEST': {
        return { ...state, ipiPlanUploading: true };
      }
      case 'UPLOAD_INDUSTRIAL_WAREHOUSE_IPI_PLAN_REQUEST': {
        return { ...state, ipiPlanUploading: true };
      }
      case 'UPLOAD_INDUSTRIAL_IPI_PLAN_SUCCESS': {
        const source = action.payload?.source;
        const plan = action.payload?.plan?.ipiPlanImage;
        const propertyID = action.payload?.sourceID;
        const isSourcePark = source === IPI_PLAN_SOURCE.PARK;
        const isSourceWarehouse = source === IPI_PLAN_SOURCE.WAREHOUSE;

        if(isSourcePark) {
          return {
            ...state,
            ipiPlanUploading: false,
            parks: state.parks ? handleUpdatedIPIPlan(state.parks, "parkIpiPlanImage", plan, propertyID) : state.parks,
            park: state.park ? handleUpdatedIPIPlan(state.park, "parkIpiPlanImage", plan) : state.park
          };
        }
        else if(isSourceWarehouse) {
          return {
            ...state,
            ipiPlanUploading: false,
            warehouses: state.warehouses ? handleUpdatedIPIPlan(state.warehouses, "warehouseIpiPlanImage", plan, propertyID) : state.warehouses,
            warehouse: state.warehouse ? handleUpdatedIPIPlan(state.warehouse, "warehouseIpiPlanImage", plan) : state.warehouse
          };
        }
        return {
          ...state,
          ipiPlanUploading: false
        };
      }
      case 'UPLOAD_INDUSTRIAL_IPI_PLAN_FAILED': {
        return { ...state, ipiPlanUploading: false };
      }
      // IPI - SAVE CHANGES
      case 'UPDATE_INDUSTRIAL_UNITS_IPI_REQUEST': {
        return { ...state, ipiUnitsUpdating: true };
      }
      case 'UPDATE_INDUSTRIAL_UNITS_IPI_SUCCESS': {
        return {
          ...state,
          ipiUnitsUpdating: false
        };
      }
      case 'UPDATE_INDUSTRIAL_UNITS_IPI_FAILED': {
        return { ...state, ipiUnitsUpdating: false };
      }
      // OTHERS
      case 'FETCH_INDUSTRIAL_COLUMN_GRID_DIMENSIONS_REQUEST': {
        return { ...state, columnGridDimensionsLoading: true, columnGridDimensions: null };
      }
      case 'FETCH_INDUSTRIAL_COLUMN_GRID_DIMENSIONS_SUCCESS': {
        return { ...state, columnGridDimensionsLoading: false, columnGridDimensions: action.payload };
      }
      case 'FETCH_INDUSTRIAL_COLUMN_GRID_DIMENSIONS_FAILED': {
        return { ...state, columnGridDimensionsLoading: false, columnGridDimensions: null };
      }
      // OTHERS  ACTIONS - ADD POI
      case 'ADD_INDUSTRIAL_POI_REQUEST': {
        return { ...state, addIndustrialPOILoading: true };
      }
      case 'ADD_INDUSTRIAL_POI_SUCCESS': {
        return { ...state, addIndustrialPOILoading: false, parkPOIS: state.parkPOIS ? [action.payload, ...state.parkPOIS] : action.payload };
      }
      case 'ADD_INDUSTRIAL_POI_FAILED': {
        return { ...state, addIndustrialPOILoading: false };
      }
      // OTHERS  ACTIONS - REMOVE POI
      case 'REMOVE_INDUSTRIAL_POI_REQUEST': {
        return { ...state, removeIndustrialPOILoading: true };
      }
      case 'REMOVE_INDUSTRIAL_POI_SUCCESS': {
        return { ...state, removeIndustrialPOILoading: false, parkPOIS: state.parkPOIS ? state.parkPOIS.filter(poi => poi.industrialPoiId !== action.payload) : null };
      }
      case 'REMOVE_INDUSTRIAL_POI_FAILED': {
        return { ...state, removeIndustrialPOILoading: false };
      }
      // OTHERS  ACTIONS - UPDATE FILE
      case 'UPDATE_INDUSTRIAL_FILE_REQUEST': {
        return { ...state, updateIndustrialFileLoading: true };
      }
      case 'UPDATE_INDUSTRIAL_FILE_SUCCESS': {
        const {data, dataSource} = action?.payload;

        if(dataSource === "industrialPark") {
          return {
            ...state,
            updateIndustrialFileLoading: false,
            parkFiles: handleUpdateFiles(state.parkFiles, data)
          };
        }
        else if(dataSource === "industrialWarehouse") {
          return {
            ...state,
            updateIndustrialFileLoading: false,
            warehouseFiles: handleUpdateFiles(state.warehouseFiles, data)
          };
        }
        return {
          ...state,
          updateIndustrialFileLoading: false
        };
      }
      case 'UPDATE_INDUSTRIAL_FILE_FAILED': {
        return { ...state, updateIndustrialFileLoading: false };
      }
      // OTHERS  ACTIONS - DELETE FILE
      case 'DELETE_INDUSTRIAL_FILE_REQUEST': {
        return { ...state, deleteIndustrialFileLoading: true };
      }
      case 'DELETE_INDUSTRIAL_FILE_SUCCESS': {
        return {
          ...state,
          deleteIndustrialFileLoading: false,
          parkFiles: state.parkFiles ? state.parkFiles.filter(file => file.id !== action.payload) : null,
          warehouseFiles: state.warehouseFiles ? state.warehouseFiles.filter(file => file.id !== action.payload) : null
        };
      }
      case 'DELETE_INDUSTRIAL_FILE_FAILED': {
        return { ...state, deleteIndustrialFileLoading: false };
      }
      // INDUSTRIAL AMENITIES
      case 'FETCH_INDUSTRIAL_AMENITIES_REQUEST': {
        return { ...state, industrialAmenitiesLoading: true, industrialAmenities: null };
      }
      case 'FETCH_INDUSTRIAL_AMENITIES_SUCCESS': {
        return {
          ...state,
          industrialAmenitiesLoading: false,
          industrialAmenities: action.payload
        };
      }
      case 'FETCH_INDUSTRIAL_AMENITIES_FAILED': {
        return { ...state, industrialAmenitiesLoading: false, industrialAmenities: null };
      }
      // INDUSTRIAL MARKETS CITIES
      case 'FETCH_INDUSTRIAL_MARKETS_CITIES_REQUEST': {
        return { ...state, industrialMarketsCitiesLoading: true, industrialMarketsCities: null };
      }
      case 'FETCH_INDUSTRIAL_MARKETS_CITIES_SUCCESS': {
        return {
          ...state,
          industrialMarketsCitiesLoading: false,
          industrialMarketsCities: action.payload || []
        };
      }
      case 'FETCH_INDUSTRIAL_MARKETS_CITIES_FAILED': {
        return { ...state, industrialMarketsCitiesLoading: false, industrialMarketsCities: null };
      }
      // INDUSTRIAL DASHBOARD TRANSACTION/SUPPLY
      case 'FETCH_INDUSTRIAL_DASHBOARD_TRANSACTIONS_REQUEST': {
        return {
          ...state,
          industrialDashboardTransactions: null,
          industrialDashboardTransactionsLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_DASHBOARD_TRANSACTIONS_SUCCESS': {
        return {
          ...state,
          industrialDashboardTransactions: action?.payload,
          industrialDashboardTransactionsLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_DASHBOARD_TRANSACTIONS_FAILED': {
        return {
          ...state,
          industrialDashboardTransactions: null,
          industrialDashboardTransactionsLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_DASHBOARD_SUPPLY_BOOST_REQUEST': {
        return {
          ...state,
          industrialDashboardSupplyBoost: null,
          industrialDashboardSupplyBoostLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_DASHBOARD_SUPPLY_BOOST_SUCCESS': {
        return {
          ...state,
          industrialDashboardSupplyBoost: action?.payload,
          industrialDashboardSupplyBoostLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_DASHBOARD_SUPPLY_BOOST_FAILED': {
        return {
          ...state,
          industrialDashboardSupplyBoost: null,
          industrialDashboardSupplyBoostLoading: false
        }
      }
      // INDUSTRIAL TRANSACTIONS/SUPPLY
      case 'FETCH_INDUSTRIAL_TRANSACTIONS_REQUEST': {
        return {
          ...state,
          industrialTransactionsLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_TRANSACTIONS_SUCCESS': {
        return {
          ...state,
          industrialTransactions: action?.payload,
          industrialTransactionsLoading: false,
        }
      }
      case 'FETCH_INDUSTRIAL_TRANSACTIONS_FAILED': {
        return {
          ...state,
          industrialTransactions: null,
          industrialTransactionsLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_SUPPLY_BOOST_REQUEST': {
        return {
          ...state,
          industrialSupplyBoostLoading: true
        }
      }
      case 'FETCH_INDUSTRIAL_SUPPLY_BOOST_SUCCESS': {
        return {
          ...state,
          industrialSupplyBoost: action?.payload,
          industrialSupplyBoostLoading: false
        }
      }
      case 'FETCH_INDUSTRIAL_SUPPLY_BOOST_FAILED': {
        return {
          ...state,
          industrialSupplyBoost: null,
          industrialSupplyBoostLoading: false
        }
      }

      default:
        return state;
    }
  }
);

// PARKS
function* fetchIndustrialParks(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at_by_user'); //updated_at_by_user
  try {
    const parks = yield axios.get(`/industrial-parks/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_SUCCESS", payload: parks.data || [{ error: parks.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_FAILED"});
  }
}
function* fetchIndustrialPark(action) {
  const {parkID} = action.payload;
  try {
    const park = yield axios.get(`/industrial-parks/${parkID}/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_SUCCESS", payload: park.data || [{ error: park.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_FAILED"});
  }
}
function* fetchIndustrialParkWarehouses(action) {
  const {parkID} = action.payload;
  try {
    const parkWarehouses = yield axios.get(`/industrial-parks/${parkID}/buildings/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_WAREHOUSES_SUCCESS", payload: parkWarehouses.data || [{ error: parkWarehouses.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_WAREHOUSES_FAILED"});
  }
}
function* fetchIndustrialParkUnits(action) {
  const {parkID} = action.payload;
  try {
    const parkUnits = yield axios.get(`/industrial-parks/${parkID}/units/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_UNITS_SUCCESS", payload: parkUnits.data || [{ error: parkUnits.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_UNITS_FAILED"});
  }
}
function* fetchIndustrialParkPhotos(action) {
  const {parkID} = action.payload;
  try {
    const parkPhotos = yield axios.get(`/industrial-parks/${parkID}/get_photos/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_PHOTOS_SUCCESS", payload: parkPhotos.data || [{ error: parkPhotos.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_PHOTOS_FAILED"});
  }
}
function* fetchIndustrialParkManageOwners(action) {
  const {parkID} = action.payload;
  try {
    const parkManageOwners = yield axios.get(`/industrial-parks/${parkID}/staff/owners/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_SUCCESS", payload: parkManageOwners.data || [{ error: parkManageOwners.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_FAILED"});
  }
}
function* fetchIndustrialParksSimpleList(action) {
  const {params, owned} = action.payload;
  const filters = setFilters(params, 'updated_at', true);
  try {
    const parksSimpleList = yield axios.get(`/industrial-parks/simple_list/${filters}`);

    if(owned) {
      yield put({ type: "FETCH_INDUSTRIAL_PARKS_OWNED_SIMPLE_LIST_SUCCESS", payload: parksSimpleList.data || [{ error: parksSimpleList.statusText }] });
    }
    else {
      yield put({ type: "FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_SUCCESS", payload: parksSimpleList.data || [{ error: parksSimpleList.statusText }] });
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_FAILED"});
  }
}
function* fetchIndustrialParksResetList(action) {
  const {initOrderBy, isEditable} = action.payload;
  const filtersReset = resetFilters(initOrderBy, isEditable);
  try {
    const parks = yield axios.get(`/industrial-parks/${filtersReset}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_SUCCESS", payload: parks.data || [{ error: parks.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialParkFiles(action) {
  try {
    const {parkID} = action.payload;
    const parkFiles = yield axios.get(`/industrial-parks/${parkID}/files/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_PROPERTY_FILES_SUCCESS", payload: parkFiles.data || [{ error: parkFiles.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* getIndustrialParksViewType() {
  const viewType = yield localStorage.getItem('industrialParksViewType');
  yield put({ type: "GET_INDUSTRIAL_PARKS_VIEW_TYPE_SUCCESS", payload: viewType || [{ error: viewType.statusText }] });
}
function* fetchIndustrialParksClusters(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'map');
  try {
    const clustersData = yield axios.get(`/industrial-parks/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_CLUSTERS_FAILED" });
  }
}
function* fetchIndustrialParksClustersReset() {
  const filters = resetFilters('map');
  try {
    const clustersData = yield axios.get(`/industrial-parks/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_CLUSTERS_FAILED" });
  }
}
function* fetchIndustrialParksLatestLeads(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at'); //number_of_units_available
  try {
    const latestLeads = yield axios.get(`/industrial-parks/latest-leads/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_SUCCESS", payload: latestLeads.data || [{ error: latestLeads.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_FAILED"});
  }
}
function* fetchIndustrialParksVisitingCompanies(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at'); //number_of_units_available
  try {
    const visitingCompanies = yield axios.get(`/industrial-parks/latest-companies/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_SUCCESS", payload: visitingCompanies.data || [{ error: visitingCompanies.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_FAILED"});
  }
}
function* fetchIndustrialParksMinified(action) {
  const params = action.payload.params;
  const filters = params ? setFilters(params) : ''; //number_of_units_available
  try {
    const parks = yield axios.get(`/industrial-parks/minified/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARKS_MINIFIED_SUCCESS", payload: parks.data || [{ error: parks.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
// PARKS ACTIONS
function* updateIndustrialPark(action) {
  try {
    const {parkID, data, backgroundRefresh} = action.payload;
    const updatePark = yield axios.patch(`/industrial-parks/${parkID}/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_PARK_SUCCESS",
      payload: updatePark.data,
      meta: actionNotification('Park has been updated.', 'success')
    });
    if(backgroundRefresh) {
      // Refresh Basic Edit Form Data
      yield put({
        type: "FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_REQUEST",
        payload: {parkID},
      });
      // Refresh Basic Data per Section
      if(backgroundRefresh === "basic") {
        // Refresh Basic Data
        yield put({
          type: 'FETCH_INDUSTRIAL_PARK_BASIC_REQUEST',
          payload: {
            parkID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "location") {
        // Refresh Basic Data
        yield put({
          type: 'FETCH_INDUSTRIAL_PARK_BASIC_REQUEST',
          payload: {
            parkID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "leaseTerms") {
        // Refresh Lease Terms Data
        yield put({
          type: 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_REQUEST',
          payload: {
            parkID,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_PARK_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addParkPhoto(action) {
  try {
    const {parkID, file} = action.payload;
    const { type, name, data } = file;
    const headers = {
      'Content-Type': type,
      'Content-Disposition': `attachment; filename=${name}`,
    };
    // upload photos
    const addPhoto = yield axios.post(`/industrial-parks/${parkID}/upload_photo/`, data, {headers});
    yield put({
      type: "ADD_INDUSTRIAL_PARK_PHOTO_SUCCESS",
      payload: addPhoto.data,
      meta: actionNotification('Images has been added.', 'success')
    });
    // refresh park props to display uploaded photos
    const updatePark = yield axios.patch(`/industrial-parks/${parkID}/`, {});
    yield put({
      type: "UPDATE_INDUSTRIAL_PARK_SUCCESS",
      payload: updatePark.data
    });
    // refresh photos props to display uploaded photos
    const photos = yield axios.get(`/industrial-parks/${parkID}/get_photos/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_PHOTOS_SUCCESS", payload: photos.data || [{ error: photos.statusText }] });
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_PARK_PHOTO_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteParkPhoto(action) {
  try {
    const photoId = action.payload;
    yield axios.delete(`/office-photos/${photoId}/`);
    yield put({
      type: "DELETE_INDUSTRIAL_PARK_PHOTO_SUCCESS",
      payload: photoId,
      meta: actionNotification('Image has been removed.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "DELETE_INDUSTRIAL_PARK_PHOTO_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addIndustrialPark(action) {
  try {
    const {data} = action.payload;
    const addPark = yield axios.post(`/industrial-parks/`, data);
    yield put({
      type: "ADD_INDUSTRIAL_PARK_SUCCESS",
      payload: addPark.data,
      meta: actionNotification('Park has been added.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "ADD_INDUSTRIAL_PARK_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addIndustrialParkOwner(action) {
  try {
    const {parkID, data} = action.payload;
    const addOwners = yield axios.post(`/industrial-parks/${parkID}/staff/owners/`, data);
    yield put({
      type: "ADD_INDUSTRIAL_PARK_OWNER_SUCCESS",
      payload: addOwners.data,
      meta: actionNotification('Owner for selected park has been added.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_PARK_OWNER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* removeIndustrialParkOwner(action) {
  try {
    const {parkID, data} = action.payload;
    const removeOwners = yield axios.post(`/industrial-parks/${parkID}/staff/delete-owners/`, data);
    yield put({
      type: "REMOVE_INDUSTRIAL_PARK_OWNER_SUCCESS",
      payload: removeOwners.data,
      meta: actionNotification('Owners for selected building has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REMOVE_INDUSTRIAL_PARK_OWNER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* uploadIndustrialParkFile(action) {
  try {
    const {parkID, file, fileDetails} = action.payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileDetails.fileType);
    formData.append('fileCategory', fileDetails.fileCategory);
    formData.append('name', fileDetails.name);
    formData.append('nameEn', fileDetails.nameEn);
    formData.append('isGalleryIncluded', fileDetails.isGalleryIncluded);

    const uploadFile = yield axios.post(`/industrial-parks/${parkID}/upload_file/`, formData);
    yield put({
      type: "UPLOAD_INDUSTRIAL_PARK_FILE_SUCCESS",
      payload: uploadFile.data,
      meta: actionNotification('File has been added.', 'success')
    });
    // Update Files List
    yield put({
      type: "FETCH_INDUSTRIAL_PARK_FILES_REQUEST",
      payload: {
        parkID
      }
    });
  }
  catch(err) {
    yield put({
      type: "UPLOAD_INDUSTRIAL_PARK_FILE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* downloadIndustrialParkFiles(action) {
  try {
    const {parkID, data, fileName} = action.payload;

    const downloadFiles = yield axios.post(`/industrial-parks/${parkID}/files_download/`, data, {responseType: "blob"});
    yield put({
      type: "DOWNLOAD_INDUSTRIAL_PARK_FILES_SUCCESS",
      payload: {data: downloadFiles.data, fileName},
      meta: actionNotification('File has been downloaded.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "DOWNLOAD_INDUSTRIAL_PARK_FILES_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteIndustrialPark(action) {
  try {
    const {parkID} = action.payload;

    yield axios.delete(`/industrial-parks/${parkID}/`);
    yield put({
      type: "DELETE_INDUSTRIAL_PARK_SUCCESS",
      payload: parkID,
      meta: actionNotification('Park has been deleted.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "DELETE_INDUSTRIAL_PARK_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* compareSelectedParks(action) {
  try {
    const {data} = action.payload;
    const compare = yield axios.post(`/industrial-parks/compare-parks/`, data);
    yield put({
      type: "COMPARE_SELECTED_PARKS_SUCCESS",
      payload: compare.data
    });
  }
  catch(err) {
    yield put({
      type: "COMPARE_SELECTED_PARKS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* compareSelectedParksReset() {
  try {
    yield put({
      type: "COMPARE_SELECTED_PARKS_RESET"
    });
  }
  catch(err) {
    console.log(err);
  }
}
// WAREHOUSES
function* fetchIndustrialWarehouses(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at_by_user'); //number_of_units_available
  try {
    const warehouses = yield axios.get(`/industrial-buildings/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_SUCCESS", payload: warehouses.data || [{ error: warehouses.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_FAILED"});
  }
}
function* fetchIndustrialWarehouseUnits(action) {
  const {warehouseID} = action.payload;
  try {
    const warehouseUnits = yield axios.get(`/industrial-buildings/${warehouseID}/units/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_SUCCESS", payload: warehouseUnits.data || [{ error: warehouseUnits.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_FAILED"});
  }
}
function* fetchIndustrialWarehousePhotos(action) {
  const {warehouseID} = action.payload;
  try {
    const warehousePhotos = yield axios.get(`/industrial-buildings/${warehouseID}/get_photos/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_SUCCESS", payload: warehousePhotos.data || [{ error: warehousePhotos.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_FAILED"});
  }
}
function* fetchIndustrialWarehouseManageOwners(action) {
  const {warehouseID} = action.payload;
  try {
    const warehouseManageOwners = yield axios.get(`/industrial-buildings/${warehouseID}/staff/owners/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_SUCCESS", payload: warehouseManageOwners.data || [{ error: warehouseManageOwners.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_FAILED"});
  }
}
function* fetchIndustrialWarehouseAmenities(action) {
  const {warehouseID} = action.payload;
  try {
    const warehouseAmenities = yield axios.get(`/industrial-buildings/${warehouseID}/amenities/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_SUCCESS", payload: warehouseAmenities.data || [{ error: warehouseAmenities.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_FAILED"});
  }
}
function* fetchIndustrialWarehousesResetList(action) {
  const {initOrderBy, isEditable} = action.payload;
  const filtersReset = resetFilters(initOrderBy, isEditable);
  try {
    const warehouses = yield axios.get(`/industrial-buildings/${filtersReset}`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_SUCCESS", payload: warehouses.data || [{ error: warehouses.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialWarehouseFiles(action) {
  try {
    const {warehouseID} = action.payload;
    const warehouseFiles = yield axios.get(`/industrial-buildings/${warehouseID}/files/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_FILES_OLD_SUCCESS", payload: warehouseFiles.data || [{ error: warehouseFiles.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialWarehousePortfolio(action) {
  try {
    const {ownerID} = action.payload;
    const portfolio = yield axios.get(`/owners/${ownerID}/get-warehouse-portfolio/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_OWNERS_PORTFOLIO_SUCCESS", payload: portfolio.data || [{ error: portfolio.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialWarehouseFinances(action) {
  try {
    const {warehouseID} = action.payload;
    const finances = yield axios.get(`/industrial-buildings/${warehouseID}/get-finances/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_FINANCES_SUCCESS", payload: finances.data || [{ error: finances.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* getIndustrialWarehousesViewType() {
  const viewTypeWarehouses = yield localStorage.getItem('industrialWarehousesViewType');
  yield put({ type: "GET_INDUSTRIAL_WAREHOUSES_VIEW_TYPE_SUCCESS", payload: viewTypeWarehouses || [{ error: viewTypeWarehouses.statusText }] });
}
function* fetchIndustrialWarehousesClusters(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'map');
  try {
    const clustersData = yield axios.get(`/industrial-buildings/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_FAILED" });
  }
}
function* fetchIndustrialWarehousesClustersReset() {
  const filters = resetFilters('map');
  try {
    const clustersData = yield axios.get(`/industrial-buildings/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_FAILED" });
  }
}
// FETCH WAREHOUSE UNITS BASIC DATA
function* fetchIndustrialWarehouseUnitEditFormsData(action) {
  const {unitID} = action.payload;
  try {
    const unitsData = yield axios.get(`/industrial-units/${unitID}/basic/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_FAILED" });
    console.log(err);
  }
}
// WAREHOUSES ACTIONS
function* updateIndustrialWarehouse(action) {
  try {
    const {warehouseID, data, backgroundRefresh} = action.payload;
    const updateWarehouse = yield axios.patch(`/industrial-buildings/${warehouseID}/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_SUCCESS",
      payload: {
        data: updateWarehouse.data,
        warehouseID,
        request: data,
        backgroundRefresh
      },
      meta: actionNotification('Building has been updated.', 'success')
    });
    if(backgroundRefresh) {
      // Refresh Basic Edit Form Data
      yield put({
        type: "FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_REQUEST",
        payload: {warehouseID},
      });
      // Refresh Basic Data per Section
      if(backgroundRefresh === "basic") {
        // Refresh Basic Data
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_BASIC_REQUEST',
          payload: {
            warehouseID,
            backgroundLoading: true
          },
        });
        // Refresh Metrics Data
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_METRICS_REQUEST',
          payload: {
            warehouseID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "location") {
        // Refresh Basic Data
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_BASIC_REQUEST',
          payload: {
            warehouseID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "leaseTerms") {
        // Refresh Lease Terms Data
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_REQUEST',
          payload: {
            warehouseID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "technicalDetails") {
        // Refresh Lease Terms Data
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_REQUEST',
          payload: {
            warehouseID,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addWarehousePhoto(action) {
  try {
    const {warehouseID, file} = action.payload;
    const { type, name, data } = file;
    const headers = {
      'Content-Type': type,
      'Content-Disposition': `attachment; filename=${name}`,
    };
    // upload photos
    const addPhoto = yield axios.post(`/industrial-buildings/${warehouseID}/upload_photo/`, data, {headers});
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_PHOTO_SUCCESS",
      payload: addPhoto.data,
      meta: actionNotification('Images has been added.', 'success')
    });
    // refresh park props to display uploaded photos
    const updateWarehouse = yield axios.patch(`/industrial-buildings/${warehouseID}/`, {});
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_SUCCESS",
      payload: updateWarehouse.data
    });
    // refresh photos props to display uploaded photos
    const photos = yield axios.get(`/industrial-buildings/${warehouseID}/get_photos/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_SUCCESS", payload: photos.data || [{ error: photos.statusText }] });
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_PHOTO_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteWarehousePhoto(action) {
  try {
    const photoId = action.payload;
    yield axios.delete(`/office-photos/${photoId}/`);
    yield put({
      type: "DELETE_INDUSTRIAL_WAREHOUSE_PHOTO_SUCCESS",
      payload: photoId,
      meta: actionNotification('Image has been removed.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "DELETE_INDUSTRIAL_WAREHOUSE_PHOTO_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addIndustrialWarehouse(action) {
  try {
    const {data, ordering} = action.payload;
    const addWarehouse = yield axios.post(`/industrial-buildings/`, data);
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_SUCCESS",
      payload: addWarehouse.data,
      meta: actionNotification('Warehouse has been added.', 'success')
    });
    // Update Units List on Building Page
    if(ordering !== undefined) {
      yield put({
        type: 'FETCH_INDUSTRIAL_PARK_BUILDINGS_REQUEST',
        payload: {
          parkID: data?.park,
          ordering
        },
      });
      // Update latest changes - property page
      if(data?.park) {
        yield put({
          type: 'FETCH_INDUSTRIAL_PARK_CHANGES_REQUEST',
          payload: {
            parkID: data?.park
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateIndustrialWarehouseAmenities(action) {
  try {
    const {warehouseID, data, backgroundRefresh} = action.payload;
    const updateAmenities = yield axios.post(`/industrial-buildings/${warehouseID}/amenities/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_SUCCESS",
      payload: updateAmenities.data,
      meta: actionNotification('Amenities for selected building has been updated.', 'success')
    });
    if(backgroundRefresh) {
      // Refresh Basic Edit Form Data
      yield put({
        type: 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_REQUEST',
        payload: {
          warehouseID
        }
      });
      // Refresh Basic Data per Section
      if(backgroundRefresh === "amenities") {
        // Refresh Amenities Data
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST',
          payload: {
            warehouseID,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addIndustrialWarehouseOwner(action) {
  try {
    const {warehouseID, data} = action.payload;
    const addOwners = yield axios.post(`/industrial-buildings/${warehouseID}/staff/owners/`, data);
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_OWNER_SUCCESS",
      payload: addOwners.data,
      meta: actionNotification('Owners for selected building has been added.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_OWNER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* removeIndustrialWarehouseOwner(action) {
  try {
    const {warehouseID, data} = action.payload;
    const addOwners = yield axios.post(`/industrial-buildings/${warehouseID}/staff/delete-owners/`, data);
    yield put({
      type: "REMOVE_INDUSTRIAL_WAREHOUSE_OWNER_SUCCESS",
      payload: addOwners.data,
      meta: actionNotification('Owners for selected building has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REMOVE_INDUSTRIAL_WAREHOUSE_OWNER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* uploadIndustrialWarehouseFile(action) {
  try {
    const {warehouseID, file, fileDetails} = action.payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileDetails.fileType);
    formData.append('fileCategory', fileDetails.fileCategory);
    formData.append('name', fileDetails.name);
    formData.append('nameEn', fileDetails.nameEn);
    formData.append('isGalleryIncluded', fileDetails.isGalleryIncluded);

    const uploadFile = yield axios.post(`/industrial-buildings/${warehouseID}/upload_file/`, formData);
    yield put({
      type: "UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_SUCCESS",
      payload: uploadFile.data,
      meta: actionNotification('File has been added.', 'success')
    });
    // Update Files List
    yield put({
      type: "FETCH_INDUSTRIAL_WAREHOUSE_FILES_REQUEST",
      payload: {
        warehouseID
      }
    });
  }
  catch(err) {
    yield put({
      type: "UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
// WAREHOUSE UNITS ACTIONS
function* addIndustrialWarehouseUnit(action) {
  try {
    const {data, ordering} = action.payload;
    const addWarehouseUnit = yield axios.post(`/industrial-units/`, data);
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS",
      payload: addWarehouseUnit.data,
      meta: actionNotification('Unit has been updated.', 'success')
    });
    // Update Units List on Building Page
    if(ordering !== undefined) {
      yield put({
        type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
        payload: {
          warehouseID: data?.warehouse,
          ordering
        },
      });
      // Update latest changes - property page
      if(data?.warehouse) {
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_REQUEST',
          payload: {
            warehouseID: data?.warehouse
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_WAREHOUSE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* removeIndustrialWarehouseUnit(action) {
  try {
    const {unitID} = action.payload;
    yield axios.delete(`/industrial-units/${unitID}/`);
    yield put({
      type: "REMOVE_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS",
      payload: unitID,
      meta: actionNotification('Unit has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REMOVE_INDUSTRIAL_WAREHOUSE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateIndustrialWarehouseUnit(action) {
  try {
    const {unitID, data, backgroundRefreshObject} = action.payload;
    const updateWarehouseUnit = yield axios.patch(`/industrial-units/${unitID}/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS",
      payload: updateWarehouseUnit.data,
      meta: actionNotification('Unit has been updated.', 'success')
    });
    if(backgroundRefreshObject) {
      // Refresh Basic Edit Form Data
      yield put({
        type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_REQUEST',
        payload: {unitID},
      });
      // Refresh Basic Data per Section
      if(backgroundRefreshObject?.target === "basic" || backgroundRefreshObject?.target === "flexibleUnits") {
        // industrialWarehouse.duck.js
        yield put({
          type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
          payload: {
            warehouseID: backgroundRefreshObject?.warehouseID,
            ordering: backgroundRefreshObject?.ordering,
            backgroundLoading: true
          },
        });
        // industrialPark.duck.js
        yield put({
          type: 'FETCH_INDUSTRIAL_PARK_UNITS_BASIC_REQUEST',
          payload: {
            parkID: backgroundRefreshObject?.parkID,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.nonFieldErrors) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* uploadIndustrialWarehouseUnitSiteplan(action) {
  try {
    const {unitID, data, backgroundRefreshObject} = action.payload;
    const formData = new FormData();
    formData.append('planImage', data);
    const uploadPlan = yield axios.post(`/industrial-units/${unitID}/plan_image/`, formData);
    yield put({
      type: "UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_SUCCESS",
      payload: uploadPlan.data,
      meta: actionNotification('Unit plan has been updated.', 'success')
    });
    if(backgroundRefreshObject) {
      // Refresh Basic Edit Form Data
      yield put({
        type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_REQUEST',
        payload: {unitID},
      });
    }
  }
  catch(err) {
    yield put({
      type: "UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* refreshAllIndustrialWarehouseUnits(action) {
  try {
    const {warehouseID, backgroundRefreshObject} = action.payload;
    const refreshAll = yield axios.post(`/industrial-buildings/${warehouseID}/refresh_all/`);
    yield put({
      type: "REFRESH_ALL_INDUSTRIAL_WAREHOUSE_UNITS_SUCCESS",
      payload: refreshAll.data,
      meta: actionNotification('All units has been refreshed.', 'success')
    });
    if(backgroundRefreshObject) {
      yield put({
        type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
        payload: {
          warehouseID,
          ordering: backgroundRefreshObject?.ordering,
          backgroundLoading: true
        },
      });
    }
  }
  catch(err) {
    yield put({
      type: "REFRESH_ALL_INDUSTRIAL_WAREHOUSE_UNITS_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* refreshSelectedIndustrialWarehouseUnits(action) {
  try {
    const {warehouseID, data, backgroundRefreshObject} = action.payload;
    const refreshSelected = yield axios.post(`/industrial-buildings/${warehouseID}/refresh/`, data);
    yield put({
      type: "REFRESH_SELECTED_INDUSTRIAL_WAREHOUSE_UNITS_SUCCESS",
      payload: refreshSelected.data,
      meta: actionNotification('Selected units has been refreshed.', 'success')
    });
    if(backgroundRefreshObject) {
      yield put({
        type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
        payload: {
          warehouseID,
          ordering: backgroundRefreshObject?.ordering,
          backgroundLoading: true
        },
      });
    }
  }
  catch(err) {
    yield put({
      type: "REFRESH_SELECTED_INDUSTRIAL_WAREHOUSE_UNITS_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* refreshSingleIndustrialWarehouseUnit(action) {
  try {
    const {unitID} = action.payload;
    const refreshUnit = yield axios.patch(`/industrial-units/${unitID}/`, {});
    yield put({
      type: "REFRESH_SINGLE_INDUSTRIAL_WAREHOUSE_UNIT_SUCCESS",
      payload: refreshUnit.data,
      meta: actionNotification('Unit has been refreshed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REFRESH_SINGLE_INDUSTRIAL_WAREHOUSE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
// IPI
function* fetchIndustrialParkUnitsIPIEditMode(action) {
  const {parkID} = action.payload;
  try {
    const parkUnitsIpi = yield axios.get(`/industrial-parks/${parkID}/ipi/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_SUCCESS", payload: parkUnitsIpi.data || [{ error: parkUnitsIpi.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_FAILED"});
  }
}
function* fetchIndustrialWarehouseUnitsIPIEditMode(action) {
  const {warehouseID} = action.payload;
  try {
    const parkUnitsIpi = yield axios.get(`/industrial-buildings/${warehouseID}/ipi/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_SUCCESS", payload: parkUnitsIpi.data || [{ error: parkUnitsIpi.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_FAILED"});
  }
}
function* updateIndustrialUnitsIPI(action) {
  try {
    const {data} = action.payload;
    const updateUnitsIpi = yield axios.post(`/industrial-units/ipi-batch/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_UNITS_IPI_SUCCESS",
      payload: updateUnitsIpi.data,
      meta: actionNotification('Interactive plan has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_UNITS_IPI_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.nonFieldErrors) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* uploadIndustrialParkIPIPlan(action) {
  try {
    const {parkID, file, source = null, sourceID = null} = action.payload;
    const formData = new FormData();
    formData.append('ipiPlanImage', file);

    const uploadFile = yield axios.post(`/industrial-parks/${parkID}/ipi-plan/`, formData);
    yield put({
      type: "UPLOAD_INDUSTRIAL_IPI_PLAN_SUCCESS",
      payload: {
        plan: uploadFile.data,
        source,
        sourceID
      },
      meta: actionNotification('IPI park plan has been uploaded.', 'success')
    });
    window.location.reload();
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "UPLOAD_INDUSTRIAL_IPI_PLAN_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* uploadIndustrialWarehouseIPIPlan(action) {
  try {
    const {warehouseID, file, source = null, sourceID = null} = action.payload;
    const formData = new FormData();
    formData.append('ipiPlanImage', file);

    const uploadFile = yield axios.post(`/industrial-buildings/${warehouseID}/ipi-plan/`, formData);
    yield put({
      type: "UPLOAD_INDUSTRIAL_IPI_PLAN_SUCCESS",
      payload: {
        plan: uploadFile.data,
        source,
        sourceID
      },
      meta: actionNotification('IPI warehouse plan has been uploaded.', 'success')
    });
    window.location.reload();
  }
  catch(err) {
    yield put({
      type: "UPLOAD_INDUSTRIAL_IPI_PLAN_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
// OTHERS
function* fetchIndustrialColumnGridDimensions() {
  try {
    const dimensions = yield axios.get(`/dimensions/`);
    yield put({ type: "FETCH_INDUSTRIAL_COLUMN_GRID_DIMENSIONS_SUCCESS", payload: dimensions.data || [{ error: dimensions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialAmenities() {
  try {
    const dimensions = yield axios.get(`/industrial-buildings/simple-amenities-list/`);
    yield put({ type: "FETCH_INDUSTRIAL_AMENITIES_SUCCESS", payload: dimensions.data || [{ error: dimensions.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_INDUSTRIAL_AMENITIES_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchIndustrialMarketsCities(action) {
  try {
    const {markets} = action.payload;
    const marketsCities = yield axios.post(`/industrial-parks/address_city_list/`, markets);
    yield put({ type: "FETCH_INDUSTRIAL_MARKETS_CITIES_SUCCESS", payload: marketsCities.data });
  }
  catch(err) {
    console.log(err);
  }
}
// FETCH INDUSTRIAL TRANSACTIONS/SUPPLY
function* fetchIndustrialDashboardTransactions() {
  try {
    const transactions = yield axios.get(`/industrial-parks/transactions-dashboard/`);
    yield put({ type: "FETCH_INDUSTRIAL_DASHBOARD_TRANSACTIONS_SUCCESS", payload: transactions.data || [{ error: transactions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialDashboardSupplyBoost() {
  try {
    const supplyBoost = yield axios.get(`/industrial-parks/supply-dashboard/`);
    yield put({ type: "FETCH_INDUSTRIAL_DASHBOARD_SUPPLY_BOOST_SUCCESS", payload: supplyBoost.data || [{ error: supplyBoost.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialTransactions(action) {
  const params = action.payload.params;
  const filters = setFilters(params); //number_of_units_available
  try {
    const transactions = yield axios.get(`/industrial-parks/transactions-list/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_TRANSACTIONS_SUCCESS", payload: transactions.data || [{ error: transactions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchIndustrialSupplyBoost(action) {
  const params = action.payload.params;
  const filters = setFilters(params); //number_of_units_available
  try {
    const supplyBoost = yield axios.get(`/industrial-parks/supply-list/${filters}`);
    yield put({ type: "FETCH_INDUSTRIAL_SUPPLY_BOOST_SUCCESS", payload: supplyBoost.data || [{ error: supplyBoost.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
// OTHERS ACTIONS
function* addIndustrialPOI(action) {
  try {
    const {data} = action.payload;
    const addPOI = yield axios.post(`/industrial-pois/`, data);
    yield put({
      type: "ADD_INDUSTRIAL_POI_SUCCESS",
      payload: addPOI.data,
      meta: actionNotification('POI has been added.', 'success')
    });
    // REFRESH POIS LIST AND MAP
    const parkID = data && data.parkId;
    const parkPois = yield axios.get(`/industrial-parks/${parkID}/pois/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_POIS_SUCCESS", payload: parkPois.data || [{ error: parkPois.statusText }] });
  }
  catch(err) {
    yield put({
      type: "ADD_INDUSTRIAL_POI_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* removeIndustrialPOI(action) {
  try {
    const {poiID} = action.payload;
    yield axios.delete(`/industrial-pois/${poiID}/`);
    yield put({
      type: "REMOVE_INDUSTRIAL_POI_SUCCESS",
      payload: poiID,
      meta: actionNotification('POI has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REMOVE_INDUSTRIAL_POI_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateIndustrialFile(action) {
  try {
    const {fileID, data, dataSource} = action.payload;
    const updateFile = yield axios.patch(`/industrial-files/${fileID}/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_FILE_SUCCESS",
      payload: {
        data: updateFile?.data,
        dataSource
      },
      meta: actionNotification('File has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_FILE_REQUEST",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteIndustrialFile(action) {
  try {
    const {fileID, parkID, warehouseID} = action.payload;
    yield axios.delete(`/industrial-files/${fileID}/`);
    yield put({
      type: "DELETE_INDUSTRIAL_FILE_SUCCESS",
      payload: fileID,
      meta: actionNotification('File has been deleted.', 'success')
    });
    // Update Files List
    if(parkID) {
      yield put({
        type: "FETCH_INDUSTRIAL_PARK_FILES_REQUEST",
        payload: {
          parkID
        }
      });
    }
    else if(warehouseID) {
      // Update Files List
      yield put({
        type: "FETCH_INDUSTRIAL_WAREHOUSE_FILES_REQUEST",
        payload: {
          warehouseID
        }
      });
    }
  }
  catch(err) {
    yield put({
      type: "DELETE_INDUSTRIAL_FILE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  // PARKS
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_REQUEST', fetchIndustrialParks);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_RESET_REQUEST', fetchIndustrialParksResetList);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_REQUEST', fetchIndustrialPark);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_WAREHOUSES_REQUEST', fetchIndustrialParkWarehouses);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_UNITS_REQUEST', fetchIndustrialParkUnits);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_PHOTOS_REQUEST', fetchIndustrialParkPhotos);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_MANAGE_OWNERS_REQUEST', fetchIndustrialParkManageOwners);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_SIMPLE_LIST_REQUEST', fetchIndustrialParksSimpleList);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_PROPERTY_FILES_REQUEST', fetchIndustrialParkFiles);
  yield takeLatest('GET_INDUSTRIAL_PARKS_VIEW_TYPE_REQUEST', getIndustrialParksViewType);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_CLUSTERS_REQUEST', fetchIndustrialParksClusters);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_CLUSTERS_RESET_REQUEST', fetchIndustrialParksClustersReset);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_LATEST_LEADS_REQUEST', fetchIndustrialParksLatestLeads);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_VISITING_COMPANIES_REQUEST', fetchIndustrialParksVisitingCompanies);
  yield takeLatest('FETCH_INDUSTRIAL_PARKS_MINIFIED_REQUEST', fetchIndustrialParksMinified);
  // PARKS ACTIONS
  yield takeLatest('UPDATE_INDUSTRIAL_PARK_REQUEST', updateIndustrialPark);
  yield takeLatest('ADD_INDUSTRIAL_PARK_PHOTO_REQUEST', addParkPhoto);
  yield takeLatest('DELETE_INDUSTRIAL_PARK_PHOTO_REQUEST', deleteParkPhoto);
  yield takeLatest('ADD_INDUSTRIAL_PARK_REQUEST', addIndustrialPark);
  yield takeLatest('ADD_INDUSTRIAL_PARK_OWNER_REQUEST', addIndustrialParkOwner);
  yield takeLatest('REMOVE_INDUSTRIAL_PARK_OWNER_REQUEST', removeIndustrialParkOwner);
  yield takeLatest('UPLOAD_INDUSTRIAL_PARK_FILE_REQUEST', uploadIndustrialParkFile);
  yield takeLatest('DOWNLOAD_INDUSTRIAL_PARK_FILES_REQUEST', downloadIndustrialParkFiles);
  yield takeLatest('DELETE_INDUSTRIAL_PARK_REQUEST', deleteIndustrialPark);
  yield takeLatest('COMPARE_SELECTED_PARKS_REQUEST', compareSelectedParks);
  yield takeLatest('COMPARE_SELECTED_PARKS_RESET_REQUEST', compareSelectedParksReset);
  // WAREHOUSES
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSES_REQUEST', fetchIndustrialWarehouses);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSES_RESET_REQUEST', fetchIndustrialWarehousesResetList);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_REQUEST', fetchIndustrialWarehouseUnits);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_PHOTOS_REQUEST', fetchIndustrialWarehousePhotos);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_MANAGE_OWNERS_REQUEST', fetchIndustrialWarehouseManageOwners);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_OLD_REQUEST', fetchIndustrialWarehouseAmenities);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_FILES_OLD_REQUEST', fetchIndustrialWarehouseFiles);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_OWNERS_PORTFOLIO_REQUEST', fetchIndustrialWarehousePortfolio);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_FINANCES_REQUEST', fetchIndustrialWarehouseFinances);
  yield takeLatest('GET_INDUSTRIAL_WAREHOUSES_VIEW_TYPE_REQUEST', getIndustrialWarehousesViewType);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_REQUEST', fetchIndustrialWarehousesClusters);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSES_CLUSTERS_RESET_REQUEST', fetchIndustrialWarehousesClustersReset);
  // FETCH BASIC DATA
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_UNIT_EDIT_FORMS_DATA_REQUEST', fetchIndustrialWarehouseUnitEditFormsData);
  // WAREHOUSES ACTIONS
  yield takeLatest('UPDATE_INDUSTRIAL_WAREHOUSE_REQUEST', updateIndustrialWarehouse);
  yield takeLatest('ADD_INDUSTRIAL_WAREHOUSE_PHOTO_REQUEST', addWarehousePhoto);
  yield takeLatest('DELETE_INDUSTRIAL_WAREHOUSE_PHOTO_REQUEST', deleteWarehousePhoto);
  yield takeLatest('ADD_INDUSTRIAL_WAREHOUSE_REQUEST', addIndustrialWarehouse);
  yield takeLatest('UPDATE_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST', updateIndustrialWarehouseAmenities);
  yield takeLatest('ADD_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST', addIndustrialWarehouseOwner);
  yield takeLatest('REMOVE_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST', removeIndustrialWarehouseOwner);
  yield takeLatest('UPLOAD_INDUSTRIAL_WAREHOUSE_FILE_REQUEST', uploadIndustrialWarehouseFile);
  // WAREHOUSE UNITS ACTIONS
  yield takeLatest('ADD_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST', addIndustrialWarehouseUnit);
  yield takeLatest('REMOVE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST', removeIndustrialWarehouseUnit);
  yield takeLatest('UPDATE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST', updateIndustrialWarehouseUnit);
  yield takeLatest('UPLOAD_INDUSTRIAL_WAREHOUSE_UNIT_PLAN_REQUEST', uploadIndustrialWarehouseUnitSiteplan);
  yield takeLatest('REFRESH_ALL_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST', refreshAllIndustrialWarehouseUnits);
  yield takeLatest('REFRESH_SELECTED_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST', refreshSelectedIndustrialWarehouseUnits);
  yield takeLatest('REFRESH_SINGLE_INDUSTRIAL_WAREHOUSE_UNIT_REQUEST', refreshSingleIndustrialWarehouseUnit);
  // IPI
  yield takeLatest('FETCH_INDUSTRIAL_PARK_UNITS_IPI_EDIT_MODE_REQUEST', fetchIndustrialParkUnitsIPIEditMode);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_UNITS_OLD_IPI_EDIT_MODE_REQUEST', fetchIndustrialWarehouseUnitsIPIEditMode);
  yield takeLatest('UPDATE_INDUSTRIAL_UNITS_IPI_REQUEST', updateIndustrialUnitsIPI);
  yield takeLatest('UPLOAD_INDUSTRIAL_PARK_IPI_PLAN_REQUEST', uploadIndustrialParkIPIPlan);
  yield takeLatest('UPLOAD_INDUSTRIAL_WAREHOUSE_IPI_PLAN_REQUEST', uploadIndustrialWarehouseIPIPlan);
  // OTHERS
  yield takeLatest('FETCH_INDUSTRIAL_COLUMN_GRID_DIMENSIONS_REQUEST', fetchIndustrialColumnGridDimensions);
  yield takeLatest('FETCH_INDUSTRIAL_AMENITIES_REQUEST', fetchIndustrialAmenities);
  yield takeLatest('FETCH_INDUSTRIAL_MARKETS_CITIES_REQUEST', fetchIndustrialMarketsCities);
  // INDUSTRIAL TRANSACTIONS/SUPPLY
  yield takeLatest('FETCH_INDUSTRIAL_DASHBOARD_TRANSACTIONS_REQUEST', fetchIndustrialDashboardTransactions);
  yield takeLatest('FETCH_INDUSTRIAL_DASHBOARD_SUPPLY_BOOST_REQUEST', fetchIndustrialDashboardSupplyBoost);
  yield takeLatest('FETCH_INDUSTRIAL_TRANSACTIONS_REQUEST', fetchIndustrialTransactions);
  yield takeLatest('FETCH_INDUSTRIAL_SUPPLY_BOOST_REQUEST', fetchIndustrialSupplyBoost);
  // OTHERS ACTIONS
  yield takeLatest('ADD_INDUSTRIAL_POI_REQUEST', addIndustrialPOI);
  yield takeLatest('REMOVE_INDUSTRIAL_POI_REQUEST', removeIndustrialPOI);
  yield takeLatest('UPDATE_INDUSTRIAL_FILE_REQUEST', updateIndustrialFile);
  yield takeLatest('DELETE_INDUSTRIAL_FILE_REQUEST', deleteIndustrialFile);
}