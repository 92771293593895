import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import styles from "./index.module.scss";

export default class TooltipCustom extends Component {
  render() {
    const {children, title, placement, disabled, childStyle} = this.props;
    return (
      <OverlayTrigger
        placement={placement || "top"}
        overlay={disabled ? <span></span> : <Tooltip id={`id-${Math.random(100)}`}>{title}</Tooltip>}
      >
        <span className={styles.labelWrapper} style={childStyle}>{children}</span>
      </OverlayTrigger>
    );
  }
}