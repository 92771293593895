import React from "react";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import ModalCustom from "../../../../partials/components/ModalCustom";
import connect from "react-redux/es/connect/connect";
import {updateLeadStatus} from "../../../../crud/leadsManagement.crud";

const STATUSES = [
  {
    id: 0,
    name: <FormattedMessage id="LM.PHRASE.NEW"/>
  },
  {
    id: 1,
    name: <FormattedMessage id="LM.PHRASE.AWAITING_INTERVIEW"/>
  },
  {
    id: 2,
    name: <FormattedMessage id="LM.PHRASE.AWAITING_BUILDINGS"/>
  },
  {
    id: 3,
    name: <FormattedMessage id="LM.PHRASE.READY_TO_SEND"/>
  },
  {
    id: 4,
    name: <FormattedMessage id="LM.PHRASE.SENT"/>
  },
  {
    id: 5,
    name: <FormattedMessage id="LM.PHRASE.DUPLICATE"/>
  },
  {
    id: 6,
    name: <FormattedMessage id="LM.PHRASE.INCORRECT_TENANT"/>
  }
];
class LeadsManagementStatus extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      status: props?.status,
      selected: props?.status
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };
  handleLabelStatus = () => {
    const {status} = this.state;
    return status === 0 ? "danger"
      : status === 1 ? "warning"
        : status === 2 ? "warning"
          : status === 3 ? "neutral"
            : status === 4 ? "success"
              : "default";
  };
  handleRenderLabel = () => {
    const activeIndex = STATUSES.findIndex(
      (item) => item.id === this.state.status
    );
    return STATUSES[activeIndex].name;
  };
  handleSave = () => {
    const {leadID, updateLeadStatus} = this.props;
    const {selected} = this.state;

    if(leadID && (selected || selected === 0) && updateLeadStatus) {
      this.setState({
        status: selected
      }, () => updateLeadStatus(leadID, {status: selected}));
    }
  }

  render() {
    const {updating} = this.props;
    const {open, selected} = this.state;

    return (
      <ModalCustom
        btn={
          <ModalTrigger
            color={this.handleLabelStatus()}
            label={this.handleRenderLabel()}
            icon="info"
            directIcon
          />
        }
        title={<FormattedMessage id="LM.PHRASE.LEAD_STATUS"/>}
        maxWidth="sm"
        handleSave={this.handleSave}
        btnConfirmDisabled={updating}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
      >
        <FormControl>
          <InputLabel htmlFor="new_user_market">
            <FormattedMessage id="LM.PHRASE.SELECT_LEAD_STATUS"/>
          </InputLabel>
          <Select
            open={open}
            onClose={this.handleToggleSelect}
            onOpen={this.handleToggleSelect}
            value={selected}
            onChange={this.handleChange}
            inputProps={{
              name: "status",
              id: "lm_status",
            }}
          >
            {STATUSES?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({leadsManagement, user}) => ({
  updating: leadsManagement?.updating,
  currentApplicationView: user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  updateLeadStatus: (leadID, data) => updateLeadStatus(leadID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementStatus);