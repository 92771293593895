// OFFERS
export const fetchOffersAll = () => ({
  type: "FETCH_OFFERS_ALL_REQUEST",
});
export const fetchShortlists = (params) => ({
  type: "FETCH_SHORTLISTS_REQUEST",
  payload: {
    params,
  },
});
export const fetchShortlistsLandlord = (params) => ({
  type: "FETCH_SHORTLISTS_LANDLORD_REQUEST",
  payload: {
    params,
  },
});
export const fetchShortlistsLandlordBuildings = () => ({
  type: "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_REQUEST"
});
export const fetchMoreShortlistsLandlord = (params) => ({
  type: "FETCH_MORE_SHORTLISTS_LANDLORD_REQUEST",
  payload: {
    params,
  },
});
export const fetchShortlistPdf = (shortlistID) => ({
  type: "FETCH_SHORTLIST_PDF_REQUEST",
  payload: shortlistID,
});
export const fetchShortlistUnits = (shortlistID) => ({
  type: "FETCH_SHORTLIST_UNITS_REQUEST",
  payload: shortlistID,
});
export const fetchShortlistUnitsLandlord = (params) => ({
  type: "FETCH_SHORTLISTS_LANDLORD_UNITS_REQUEST",
  payload: { params },
});
export const fetchShortlistLandlordReset = () => ({
  type: "FETCH_SHORTLISTS_LANDLORD_RESET_REQUEST",
});
export const fetchShortlistUnitsReset = () => ({
  type: "FETCH_SHORTLIST_UNITS_RESET_REQUEST",
});
export const fetchShortlistUnitsLandlordReset = () => ({
  type: "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_REQUEST",
});
export const fetchOfferBuildings = (offerID) => ({
  type: "FETCH_OFFER_BUILDINGS_REQUEST",
  payload: offerID,
});
export const fetchOfferBuildingsIndustrial = (offerID) => ({
  type: "FETCH_OFFER_BUILDINGS_INDUSTRIAL_REQUEST",
  payload: offerID,
});
// OFFERS ONLINE
export const fetchOfferOnline = (offerID, email, market) => ({
  type: "FETCH_OFFER_ONLINE_REQUEST",
  payload: {
    offerID,
    email,
    market,
  },
});
export const fetchOfferOnlineOffice = (offerID, officeID, email) => ({
  type: "FETCH_OFFER_ONLINE_OFFICE_REQUEST",
  payload: {
    offerID,
    officeID,
    email,
  },
});
export const fetchOfferOnlineOfficeContact = (offerID, officeID, email) => ({
  type: "FETCH_OFFER_ONLINE_OFFICE_CONTACT_REQUEST",
  payload: {
    offerID,
    officeID,
    email,
  },
});
export const fetchOfferOnlineBranding = (offerID) => ({
  type: "FETCH_OFFER_ONLINE_BRANDING_REQUEST",
  payload: {
    offerID,
  },
});
export const fetchOfferOnlineListPhotos = (offerID, email) => ({
  type: "FETCH_OFFER_ONLINE_LIST_PHOTOS_REQUEST",
  payload: {
    offerID,
    email,
  },
});
// OFFERS ONLINE ANALYTICS
export const fetchOffersAnalytics = () => ({
  type: "FETCH_OFFERS_ONLINE_ANALYTICS_REQUEST",
});
export const fetchOffersAnalyticsCharts = (year) => ({
  type: "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_REQUEST",
  payload: {
    year,
  },
});
export const fetchOffersOfficeAnalytics = (offerID) => ({
  type: "FETCH_OFFERS_ONLINE_OFFICE_ANALYTICS_REQUEST",
  payload: {
    offerID,
  },
});
// OFFERS ACTIONS
export const addShortlist = (data) => ({
  type: "ADD_SHORTLIST_REQUEST",
  payload: { data },
});
export const addUnitToExistingShortlist = (id, units) => ({
  type: "ADD_UNIT_TO_EXISTING_SHORTLIST_REQUEST",
  payload: {
    id,
    units,
  },
});
export const addUnitToNewShortlist = (name, units, forTesting) => ({
  type: "ADD_UNIT_TO_NEW_SHORTLIST_REQUEST",
  payload: {
    name,
    units,
    forTesting
  },
});
export const deleteShortlist = (shortlistId) => ({
  type: "DELETE_SHORTLIST_REQUEST",
  payload: shortlistId,
});
export const deleteMultipleShortlist = (shortLists) => ({
  type: "DELETE_MULTIPLE_SHORTLIST_REQUEST",
  payload: { shortLists },
});
export const updateOfferClients = (offerID, data, notificationDisabled) => ({
  type: "UPDATE_OFFER_CLIENTS_REQUEST",
  payload: {
    offerID,
    data,
    notificationDisabled,
  },
});
export const updateOffer = (offerID, data) => ({
  type: "UPDATE_OFFER_REQUEST",
  payload: {
    offerID,
    data,
  },
});
export const shareOfferOnline = (data, offerID) => ({
  type: "SHARE_OFFER_ONLINE_REQUEST",
  payload: {
    data,
    offerID,
  },
});
// OFFERS ONLINE ACTIONS
export const verifyOfferOnlineAccess = (offerID, email) => ({
  type: "VERIFY_OFFER_ONLINE_ACCESS_REQUEST",
  payload: {
    offerID,
    email,
  },
});
export const requestOfferOnlineAccess = (offerID, email) => ({
  type: "REQUEST_OFFER_ONLINE_ACCESS_REQUEST",
  payload: {
    offerID,
    email,
  },
});
// OFFERS KUDOS MAIL SEND
export const sendKudosEmailConfirmation = (data) => ({
  type: "SEND_KUDOS_EMAIL_REQUEST",
  payload: {
    data,
  },
});
export const createKudosEmailSignInLog = (data) => ({
  type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_REQUEST",
  payload: {
    data,
  },
});
export const createRealTimeEmailSignInLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_REQUEST",
  payload: {
    data,
  },
});
// OFFER WEEKLY SUMMARY - BUTTON REDIRECT
export const createRealTimeEmailWeeklySummaryLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_REQUEST",
  payload: {
    data,
  },
});
// OFFER FIRST OPEN - BUTTON REDIRECT
export const createRealTimeEmailFirstOpenLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_REQUEST",
  payload: {
    data,
  },
});
// OFFER FIRST OPEN - BUTTON REDIRECT
export const createRealTimeEmailOfferOpenLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_REQUEST",
  payload: {
    data,
  },
});
// OFFERS BATCH - POST
export const postOffersBatch = (data) => ({
  type: "OFFERS_BATCH_POST_REQUEST",
  payload: {
    data,
  },
});
// OFFERS INDUSTRIAL BATCH - POST
export const postOffersIndustrialBatch = (data) => ({
  type: "OFFERS_INDUSTRIAL_BATCH_POST_REQUEST",
  payload: {
    data,
  },
});

// RESET OFFICE OFFERS LOADING STATES
export const resetOfficeOffersLoadingStates = () => ({
  type: "RESET_OFFICE_OFFERS_LOADING_STATES_REQUEST"
});
