import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import styles from "./index.module.scss";
import clsx from "clsx";
import {injectIntl} from "react-intl";
import NoData from "app/partials/components/NoData/NoData";
import ButtonSimple from "../ButtonSimple";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f9f9f9",
    color: "#A2A5B8",
  },
  body: {
    fontSize: "1.6rem",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

class TableCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitActive: props?.initialLimit || false
    }
  }
  // Handlers
  handleGetTHeadLabel = (objectLabel) => {
    const { intl } = this.props;
    if (objectLabel) {
      const labelValue = objectLabel?.["label"]?.["props"]?.["id"];
      if(labelValue) {
        return intl.formatMessage({ id: labelValue });
      }
      return "";
    }
    return false;
  };
  handleLimitToggle = () => {
    this.setState({
      limitActive: !this.state.limitActive
    });
  }

  render() {
    const {
      tHead,
      tBody,
      stickyHeader,
      maxHeight,
      thStyle,
      tdStyle,
      theadStyleOrigin,
      scrollContainerID,
      tHeadBgColor,
      tHeadBgFontColor,
      responsive,
      blurrIndexes,
      blurrValues,
      propertyCellIndex,
      column_1_Wrap = "nowrap",
      column_2_Wrap = "nowrap",
      column_3_Wrap = "nowrap",
      column_4_Wrap = "nowrap",
      column_5_Wrap = "nowrap",
      column_6_Wrap = "nowrap",
      column_7_Wrap = "nowrap",
      column_8_Wrap = "nowrap",
      column_9_Wrap = "nowrap",
      column_10_Wrap = "nowrap",
      propertyPrimaryRowIndex,
      propertyPrimaryRowStyle,
      propertyPrimaryCellStyle,
      initialLimit,
      limitBtnLabel,
      tHeadCellStyle,
      paywallActive,
      paywallBanner
    } = this.props;
    const {limitActive} = this.state;

    return (
      <Paper className={styles.root} elevation={0}>
        {tHead && tBody && tBody.length ? (
          <TableContainer
            id={scrollContainerID}
            style={maxHeight && { maxHeight: maxHeight }}
          >
            <Table
              className={clsx(
                styles.table,
                responsive ? styles.responsive : undefined
              )}
              stickyHeader={stickyHeader}
              style={{
                "--tHeadBgColor": tHeadBgColor,
                "--tHeadBgFontColor": tHeadBgFontColor,
                "--column_1_Wrap": column_1_Wrap,
                "--column_2_Wrap": column_2_Wrap,
                "--column_3_Wrap": column_3_Wrap,
                "--column_4_Wrap": column_4_Wrap,
                "--column_5_Wrap": column_5_Wrap,
                "--column_6_Wrap": column_6_Wrap,
                "--column_7_Wrap": column_7_Wrap,
                "--column_8_Wrap": column_8_Wrap,
                "--column_9_Wrap": column_9_Wrap,
                "--column_10_Wrap": column_10_Wrap,
              }}
            >
              {tHead && (
                <TableHead>
                  <TableRow>
                    {tHead.map((th, index) =>
                      theadStyleOrigin ? (
                        <TableCell
                          key={index}
                          className={clsx(
                            theadStyleOrigin ? styles.theadBgColor : undefined
                          )}
                          style={tHeadCellStyle}
                        >
                          <Typography
                            align={th.number ? "right" : "left"}
                            style={thStyle}
                          >
                            {th?.label}
                          </Typography>
                        </TableCell>
                      ) : (
                        <StyledTableCell
                          key={index}
                          style={{
                            ...tHeadBgColor && tHeadBgFontColor ? {
                              backgroundColor: tHeadBgColor,
                              color: tHeadBgFontColor,
                            } : {},
                            ...tHeadCellStyle ? tHeadCellStyle : {}
                        }}
                        >
                          <Typography
                            align={th.number ? "right" : "left"}
                            style={thStyle}
                          >
                            {th?.label}
                          </Typography>
                        </StyledTableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {tBody.map((row, index) => {
                    const blurredStyles = {color: "transparent", textShadow: "0 0 8px #000", pointerEvents: "none"};
                    const isPropertyOwnedRow = propertyPrimaryRowIndex === index;

                    return(
                      <React.Fragment key={index}>
                        {theadStyleOrigin ? (
                          <TableRow
                            style={{
                              ...isPropertyOwnedRow ? propertyPrimaryRowStyle : undefined,
                              ...limitActive && index > initialLimit ? {height: 0, overflow: "hidden", display: "table-column"} : {}
                            }}
                          >
                            {row.data.map((td, index) => {
                              const isBlurred = row?.isHidden && blurrIndexes?.includes(index);
                              const blurredIndex = isBlurred ? blurrIndexes.indexOf(index) : null;
                              const blurredValue = blurredIndex >= 0 ? blurrValues?.[blurredIndex] : "Lorem ipsum";

                              return (
                                <StyledTableCell
                                  key={index}
                                  align={
                                    tHead && tHead[index].number ? "right" : "left"
                                  }
                                  style={{
                                    ...isBlurred ? blurredStyles : tdStyle,
                                    ...isPropertyOwnedRow ? propertyPrimaryCellStyle : {}
                                  }}
                                >
                                  {isBlurred ? blurredValue : td}
                                </StyledTableCell>
                              );
                            })}
                          </TableRow>
                        ) : (
                          <StyledTableRow
                            key={index}
                            style={{
                              ...isPropertyOwnedRow ? propertyPrimaryRowStyle : {},
                              ...limitActive && index > initialLimit ? {height: 0, overflow: "hidden", display: "table-column"} : {}
                            }}
                          >
                            {row.data.map((td, index) => {
                              const isBlurred = row?.isHidden && blurrIndexes?.includes(index);
                              const blurredIndex = isBlurred ? blurrIndexes.indexOf(index) : null;
                              const blurredValue = blurredIndex >= 0 ? blurrValues?.[blurredIndex] : "Lorem ipsum";

                              return(
                                <StyledTableCell
                                  key={index}
                                  align={
                                    tHead && tHead[index].number ? "right" : "left"
                                  }
                                  style={{
                                    ...isBlurred ? blurredStyles : tdStyle,
                                    ...isPropertyOwnedRow ? propertyPrimaryCellStyle : {}
                                  }}
                                  className={propertyCellIndex === index ? styles.propertyCellWrapper : undefined}
                                  data-label={
                                    responsive && this.handleGetTHeadLabel(tHead[index])
                                  }
                                >
                                  {isBlurred ? blurredValue : td}
                                </StyledTableCell>
                              );
                            })}
                          </StyledTableRow>
                        )}
                        {paywallActive && paywallBanner && (index === 2 || (index === 8 && !limitActive)) &&
                        <TableRow>
                          <TableCell colSpan={100}>{paywallBanner}</TableCell>
                        </TableRow>
                        }
                      </React.Fragment>
                    );
                  }
                )}
              </TableBody>
            </Table>
            {initialLimit && tBody?.length > initialLimit && limitActive &&
            <div className={styles.tableFooter}>
              <ButtonSimple
                label={limitBtnLabel}
                slim
                onClick={() => this.handleLimitToggle()}
              />
            </div>
            }
          </TableContainer>
        ) : (
          <NoData />
        )}
      </Paper>
    );
  }
}

export default injectIntl(TableCustom);
