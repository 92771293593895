import React from "react";
import {createTheme} from "@material-ui/core";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Roboto"].join(","),
      h1: {
        fontSize: "3.6rem",
        marginBottom: "1rem"
      },
      h2: {
        fontSize: "2.9rem"
      },
      h3: {
        fontSize: "2.4rem",
        fontWeight: 700,
        color: "#2d2d2d"
      },
      h4: {
        fontSize: "1.6rem",
        color: "#2d2d2d",
        lineHeight: "normal"
      },
      h5: {
        fontSize: "1.2rem",
        fontWeight: 500,
        marginBottom: 4.5
      },
      body1: {
        fontSize: "1.4rem",
        fontWeight: 400
      },
      body2: {
        fontSize: "1.2rem",
        fontWeight: 400,
        lineHeight: "1.2rem"
      },
      subtitle1: {
        fontSize: "1.3rem"
      }
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#FF4626",
        contrastText: "#ffffff"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3a3a3a",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#ffffff"
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#FF4626"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      },
    },
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: "rgba(255,255,255,0.9)",
          backdropFilter: "blur(5px)"
        }
      },
      MuiSelect: {
        selectMenu: {
          minWidth: '20rem',
          "&:focus": {
            background: "transparent"
          }
        }
      },
      MuiMenuItem: {
        root: {
          paddingTop: 0,
          paddingBottom: 0
        }
      },
      MuiTableCell: {
        root: {
          fontSize: "1.6rem",
          paddingTop: "1.2rem",
          paddingBottom: "1.2rem",
          whiteSpace: 'nowrap',
          borderColor: "#E6E6E6"
        },
        head: {
          color: '#2d2d2d',
          fontWeight: "400"
        },
        stickyHeader: {
          backgroundColor: "#f9f9f9"
        }
      },
      MuiTableSortLabel: {
        icon: {
          opacity: 0.5,
          marginRight: 0
        },
        root: {
          active: {
            color: "A2A5B8"
          }
        }
      },
      MuiTypography: {
        h1: {
          fontSize: '3.6rem'
        },
        h2: {
          fontSize: '2.25rem'
        },
        h3: {
          fontSize: '2.4rem',
          fontWeight: 700,
          color: '#2d2d2d',
        },
        h4: {
          fontSize: '1.6rem'
        },
        h5: {
          fontSize: "1.3rem"
        },
        body1: {
          fontSize: '1.4rem',
          fontWeight: 400,
          overflowWrap: 'break-word'
        },
        body2: {
          fontSize: '1.2rem',
          fontWeight: 400
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: "#ffffff"
        },
        elevation24: {
          boxShadow: "unset"
        }
      },
      MuiDialog: {
        root: {
          zIndex: 1301
        }
      },
      MuiDialogActions: {
        root: {
          padding: "8px 24px"
        }
      },
      MuiSnackbar: {
        anchorOriginTopRight: {
          top: "8.5rem !important"
        }
      },
      MuiFormHelperText: {
        root: {
          fontSize: '1rem'
        }
      },
      MuiFormLabel: {
        root: {
          fontSize: "1.3rem"
        }
      },
      MuiButton: {
        root: {
          fontSize: "1.2rem",
          lineHeight: "1.6rem",
          textTransform: "none",
          padding: "1.1rem 1.2rem 1.2rem",
          borderRadius: "3px"
        }
      },
      MuiIcon: {
        root: {
          fontSize: "1.8rem"
        }
      },
      MuiSlider: {
        markLabel: {
          fontSize: 10
        },
        mark: {
          width: 6,
          height: 6,
          borderRadius: "50%",
          top: "50%",
          transform: "translateY(-50%)"
        },
        markActive: {
          backgroundColor: "#ff4626"
        }
      },
      MuiInputLabel: {
        formControl: {
          transform: "translate(0, 2.4rem) scale(1)"
        },
        marginDense: {
          transform: "translate(0, 2.1rem) scale(1)"
        },
        outlined: {
          transform: "translate(28px, 11px) scale(1)"
        }
      },
      MuiOutlinedInput: {
        adornedEnd: {
          marginTop: "0 !important",
          padding: "0 !important"
        },
        inputAdornedEnd: {
          padding: "0 20px 0 27px !important"
        }
      },
      MuiInputBase: {
        root: {
          fontSize: "1.3rem"
        },
        adornedEnd: {
          paddingRight: "3.6rem !important",
          marginTop: "1.6rem !important"
        }
      },
      MuiAutocomplete: {
        groupLabel: {
          fontSize: "1rem",
          padding: "1.4rem 1.6rem 0rem",
          top: "0",
          lineHeight: "1.6rem",
          color: "#ff4626",
          textTransform: "uppercase",
          transform: "translateY(-8px)"
        },
        endAdornment: {
          right: "4px !important",
          top: "calc(50% - 10px)"
        }
      },
      MuiInput: {
        formControl: {
          marginTop: "1.6rem !important"
        }
      },
      MuiTab: {
        root: {
          padding: "0.6rem 1.2rem",
          maxWidth: "26.4rem",
          monHeight: "4.8rem"
        }
      },
      MuiGridListTile: {
        imgFullHeight: {
          width: "100%",
          height: "auto"
        }
      },
      MuiSwitch: {
        root: {
          width: "3rem",
          height: "1.8rem",
          padding: "0",
          borderRadius: "1.4rem",

          "&$checked": {
            "transform": "unset",
            "left": "auto",
            "right": "3px"
          }
        },
        thumb: {
          width: "1.2rem",
          height: "1.2rem",
          backgroundColor: "#ffffff"
        },
        track: {
          opacity: "1",
          backgroundColor: "#ededed"
        },
        switchBase: {
          color: "#ff4626",
          padding: "0",
          top: "2.5px",
          left: "3px",
          transition: "all 0.3s ease-in-out",

          "&$checked": {
            "transform": "translateX(10px)",
          }
        }
      }
    }
  }
);
const themeDark = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Roboto"].join(","),
      h1: {
        fontSize: "2.2rem",
        marginBottom: "1rem"
      },
      h3: {
        fontSize: "2.4rem",
        fontWeight: 700
      },
      h4: {
        fontSize: "1.6rem",
        color: "#ffffff",
        lineHeight: "normal",
        marginBottom: "0.65rem"
      },
      h5: {
        fontSize: "1.3rem",
        fontWeight: 500,
        marginBottom: "0.45rem"
      },
      p: {
        fontSize: "1.3rem",
        fontWeight: 500
      },
      body1: {
        fontSize: "1.4rem"
      },
      body2: {
        fontSize: "1.2rem"
      },
      subtitle1: {
        fontSize: "1.3rem"
      }
    },

    palette: {
      type: 'dark',
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#FF4626",
        contrastText: "#ffffff"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3a3a3a",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#ffffff"
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#FF4626"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#2D2D2D'
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "rgba(0,0,0,0.5)"
        }
      },
      MuiSelect: {
        selectMenu: {
          minWidth: '20rem'
        }
      },
      MuiAutocomplete: {
        input: {
          width: '100% !important',
          maxWidth: '90%'
        },
        inputRoot: {
          paddingRight: '0 !important'
        }
      },
      MuiSnackbar: {
        anchorOriginTopRight: {
          top: "8.8rem !important"
        }
      },
      MuiDialog: {
        root: {
          zIndex: 1301
        }
      },
      MuiDialogContent: {
        root: {
          minHeight: "10rem"
        }
      },
      MuiDialogActions: {
        root: {
          padding: "8px 24px"
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          '&$expanded': {
            backgroundColor: "#3a3a3a",
            color: "#FF4626"
          }
        }
      },
      MuiExpansionPanelDetails: {
        root: {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      MuiInputBase: {
        root: {
          fontSize: "1.3rem"
        }
      },
      MuiFormLabel: {
        root: {
          fontSize: "1.3rem"
        }
      },
      MuiButton: {
        root: {
          fontSize: "1.2rem",
          lineHeight: "1.6rem",
          textTransform: "none",
          padding: "1.1rem 1.2rem 1.2rem",
          borderRadius: "3px"
        }
      },
      MuiTableCell: {
        root: {
          fontSize: "1.6rem"
        }
      },
      MuiIcon: {
        root: {
          fontSize: "1.8rem"
        }
      },
      MuiGridListTile: {
        imgFullHeight: {
          width: "100%",
          height: "auto"
        }
      },
      MuiSwitch: {
        root: {
          width: "3rem",
          height: "1.8rem",
          padding: "0",
          borderRadius: "1.4rem",

          "&$checked": {
            "transform": "unset",
            "left": "auto",
            "right": "3px"
          }
        },
        thumb: {
          width: "1.2rem",
          height: "1.2rem",
          backgroundColor: "#ff4626"
        },
        track: {
          opacity: "1",
          backgroundColor: "#3a3a3a"
        },
        switchBase: {
          color: "#ff4626",
          padding: "0",
          top: "2.5px",
          left: "3px",
          transition: "all 0.3s ease-in-out",

          "&$checked": {
            "transform": "translateX(10px)",
          }
        }
      }
    }
  }
);

export default function ThemeProvider(props) {
  const { children, dark } = props;

  return <MuiThemeProvider theme={dark ? themeDark : theme}>{children}</MuiThemeProvider>;
}
